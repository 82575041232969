/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableSortLabel,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import { clearPostCache } from "../../../services/cacheService";
import { deletePost } from "../../../services/postsService";
import { UserLogged } from "../../../types/Auth";
import { NestedPost } from "../../../types/Post/NestedPost";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";
import AlertDialog from "../../AlertDialog";

interface RowProps {
  post: NestedPost;
  handleClickEdit: (imageId: number) => void;
  handleClickDelete: (imageId: number) => void;
  handleClearCache: (postSlug: string) => void;
}

function Row({
  post,
  handleClickEdit,
  handleClickDelete,
  handleClearCache,
}: RowProps) {
  return (
    <TableRow>
      <TableCell />
      <TableCell component="th" scope="row">
        {post.title}
      </TableCell>
      <TableCell align="right">{post.slug}</TableCell>
      <TableCell align="right">
        {`${post.author.first_name} ${post.author.last_name}`}
      </TableCell>
      <TableCell align="right">{`${post.published ? "Sim" : "Não"}`}</TableCell>
      <TableCell align="right">
        {post.publishedAt !== null
          ? formatFromIsoToDateTime(post.publishedAt as string)
          : "-"}
      </TableCell>
      <TableCell align="right">
        {formatFromIsoToDateTime(post.createdAt as string)}
      </TableCell>
      <TableCell align="right">
        {formatFromIsoToDateTime(post.updatedAt as string)}
      </TableCell>
      <TableCell align="right">
        <IconButton
          title="Editar post"
          onClick={() => handleClickEdit(post.id)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          title="Deletar post"
          onClick={() => handleClickDelete(post.id)}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          title="Limpar cache"
          onClick={() => handleClearCache(post.slug)}
        >
          <CleaningServicesIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

type sortKey = "createdAt" | "updatedAt" | "publishedAt" | "title";

export default function PostsTable() {
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get("q") ?? "";
  const published = searchParams.get("published") ?? "true";
  const sortBy = searchParams.get("sort") ?? "createdAt";
  const sortOrder = searchParams.get("order") ?? "desc";
  const page = searchParams.get("page")
    ? Number(searchParams.get("page")) - 1
    : 0;

  function filter(params: Record<string, string | string[]>) {
    const currentFilters = Object.fromEntries(searchParams);

    const resFilters = { ...currentFilters, ...params };

    if (currentFilters.published !== resFilters.published) {
      Object.defineProperty(resFilters, "page", { value: 1 });
    }

    setSearchParams(resFilters);
  }

  const PER_PAGE = 10;

  const { user } = React.useContext(AuthContext);

  const { data, mutate, isLoading } = useFetchWithToken<{
    count: number;
    posts: NestedPost[];
  }>(
    `/post/accessibleByUser?published=${published}&qtdPosts=${PER_PAGE}&skip=${
      PER_PAGE * page
    }&sort=${sortBy}:${sortOrder}&title=${q}`,
    (user as UserLogged).access_token
  );

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    filter({ page: (newPage + 1).toString() });
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [postId, setPostId] = React.useState<number | null>(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  function handleClickEdit(postId: number) {
    navigate(`${postId}/edit`);
  }

  function handleClickDelete(postId: number) {
    setDialogOpen(true);
    setPostId(postId);
  }

  async function handleClearCache(postSlug: string) {
    try {
      await clearPostCache(postSlug);
      enqueueSnackbar("Cache limpo", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Erro ao limpar o cache", { variant: "error" });
    }
  }

  function handleDisagree() {
    setDialogOpen(false);
  }

  async function handleAgree(postId: number | null) {
    try {
      if (postId !== null) {
        await deletePost(postId);
        enqueueSnackbar("Deletado com sucesso", { variant: "success" });
        mutate();
        setDialogOpen(false);
      } else {
        enqueueSnackbar("Id da postagem não fornecido", { variant: "error" });
      }
    } catch (err: any) {
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  const handleSort = (newSortBy: sortKey) => {
    if (newSortBy !== sortBy) {
      filter({ sort: newSortBy, order: "desc" });
    } else {
      filter({
        sort: sortBy,
        order: sortOrder === "desc" ? "asc" : "desc",
      });
    }
  };

  return (
    <>
      <AlertDialog
        title="Excluir postagem"
        content="Tem certeza que deseja excluir a postagem? A ação não pode ser desfeita."
        buttonAgree="Sim"
        buttonDisagree="Não"
        handleDisagree={() => handleDisagree()}
        handleAgree={() => handleAgree(postId)}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <FormControl fullWidth size="small">
          <InputLabel>Status</InputLabel>
          <Select
            value={published === "true" ? 1 : 0}
            label="Status"
            onChange={(e) =>
              filter({
                published: e.target.value === 1 ? "true" : "false",
              })
            }
          >
            <MenuItem value={1}>Publicado</MenuItem>
            <MenuItem value={0}>Pendente</MenuItem>
          </Select>
        </FormControl>
        <TextField
          type="text"
          placeholder="Buscar pelo título..."
          size="small"
          fullWidth
          defaultValue={q}
          onChange={(e) =>
            filter({
              q: e.target.value.toLowerCase().trim(),
            })
          }
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320 }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "title"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => handleSort("title")}
                  >
                    Título
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">Slug</TableCell>
                <TableCell align="right">Autor</TableCell>
                <TableCell align="right">Publicado</TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortBy === "publishedAt"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => handleSort("publishedAt")}
                  >
                    Publicado em
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortBy === "createdAt"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => handleSort("createdAt")}
                  >
                    Criado em
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortBy === "updatedAt"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => handleSort("updatedAt")}
                  >
                    Atualizado em
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => navigate("/posts/novo")}
                  >
                    Novo
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && data ? (
                data.posts.map((post) => (
                  <Row
                    key={post.id}
                    post={post}
                    handleClickEdit={(imageId) => handleClickEdit(imageId)}
                    handleClickDelete={(imageId) => handleClickDelete(imageId)}
                    handleClearCache={(postSlug) => handleClearCache(postSlug)}
                  />
                ))
              ) : (
                <>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell />
                      <TableCell component="th" scope="row">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton>
                          <CleaningServicesIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={data.count}
            rowsPerPage={10}
            page={page}
            onPageChange={(event, page) => handleChangePage(event, page)}
          />
        )}
      </Stack>
    </>
  );
}
