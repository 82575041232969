export function cleanImageName(imageName: string): {
  cleanedName: string;
  typeOfImage: string;
} {
  const cleanedName = imageName.replace(
    /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
    ""
  );

  let typeOfImage = "";

  const isMatch = imageName.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i);

  if (isMatch !== null) {
    typeOfImage = isMatch[0] as string;
  }

  return { cleanedName, typeOfImage };
}
