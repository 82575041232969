/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import instance from "../../../../services/api";
import { Category } from "../../../../types/Category";
import { Subcategory } from "../../../../types/Subcategory";

interface RowProps {
  category: Category;
  fetch: () => void;
  setEditCategoryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCategoryToEdit: React.Dispatch<React.SetStateAction<Category | null>>;
  setEditSubcategoryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSubcategoryToEdit: React.Dispatch<
    React.SetStateAction<Subcategory | null>
  >;
}

export function Row({
  category,
  fetch,
  setEditCategoryModalOpen,
  setCategoryToEdit,
  setEditSubcategoryModalOpen,
  setSubcategoryToEdit,
}: RowProps) {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  function handleClickEditCategory() {
    setEditCategoryModalOpen((state) => !state);
    setCategoryToEdit(category);
  }

  function handleClickEditSubcategory(subcategory: Subcategory) {
    setEditSubcategoryModalOpen((state) => !state);
    setSubcategoryToEdit(subcategory);
  }

  async function deleteCategory(categoryId: number) {
    try {
      await instance.delete(`category/${categoryId}`);

      fetch();
      enqueueSnackbar("Deletado com sucesso", { variant: "success" });
    } catch (err: any) {
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  async function deleteSubcategory(subcategoryId: number) {
    try {
      await instance.delete(`subcategory/${subcategoryId}`);

      fetch();
      enqueueSnackbar("Deletado com sucesso", { variant: "success" });
    } catch (err: any) {
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <IconButton
            disabled={!(category.subcategories.length > 0)}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {category.name}
        </TableCell>
        <TableCell>{category.slug}</TableCell>
        <TableCell>
          {category.image ? (
            <LazyLoadImage
              src={category.image.url}
              alt={category.image.alt}
              width="60px"
              height="60px"
              effect="blur"
            />
          ) : (
            "Nenhuma"
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="edit category"
            title="Editar categoria"
            onClick={() => handleClickEditCategory()}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete-category"
            title="Deletar categoria"
            onClick={() => deleteCategory(category.id)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Subcategorias
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Slug</TableCell>
                    <TableCell>Imagem</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category.subcategories.map((subcategory) => (
                    <TableRow key={subcategory.id}>
                      <TableCell component="th" scope="row">
                        {subcategory.name}
                      </TableCell>
                      <TableCell>{subcategory.slug}</TableCell>
                      <TableCell>
                        {subcategory.image?.name ? (
                          <LazyLoadImage
                            src={subcategory.image.url}
                            alt={subcategory.image.alt}
                            width="60px"
                            height="60px"
                          />
                        ) : (
                          "Nenhuma"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="edit-subcategory"
                          title="Editar subcategoria"
                          onClick={() =>
                            handleClickEditSubcategory(subcategory)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete-subcategory"
                          title="Deletar subcategoria"
                          onClick={() => deleteSubcategory(subcategory.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
