/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Skeleton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import { useState, useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import { deleteImage } from "../../../services/galleryService";
import theme from "../../../styles/theme";
import { Image } from "../../../types/Image";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";
import AlertDialog from "../../AlertDialog";

interface RowProps {
  image: Image;
  handleClickEdit: (imageId: number) => void;
  handleClickDelete: (imageId: number) => void;
}

function Row({ image, handleClickEdit, handleClickDelete }: RowProps) {
  return (
    <TableRow>
      <TableCell />
      <TableCell>
        <LazyLoadImage
          width="60px"
          height="60px"
          src={image.url}
          alt={image.alt}
          effect="blur"
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {image.name}
      </TableCell>
      <TableCell align="right">{image.mimitype}</TableCell>
      <TableCell align="right">
        {formatFromIsoToDateTime(image.createdAt as string)}
      </TableCell>
      <TableCell align="right">
        {formatFromIsoToDateTime(image.updatedAt as string)}
      </TableCell>
      <TableCell align="right">
        <IconButton aria-label="edit" onClick={() => handleClickEdit(image.id)}>
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => handleClickDelete(image.id)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const options = [
  { label: "Todos", value: "" },
  { label: "Receita", value: "post" },
  { label: "Blog", value: "blog_post" },
  { label: "Categoria", value: "category" },
  { label: "Subcategoria", value: "subcategory" },
  { label: "Popup", value: "popup" },
];

function GalleryTable() {
  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const resource = searchParams.get("resource") || "";
  const q = searchParams.get("q") ?? "";
  const page = searchParams.get("page")
    ? Number(searchParams.get("page")) - 1
    : 0;

  function filter(params: Record<string, string | string[]>) {
    const currentFilters = Object.fromEntries(searchParams);

    const resFilters = { ...currentFilters, ...params };

    setSearchParams(resFilters);
  }

  const PER_PAGE = 10;

  const { user } = useContext(AuthContext);

  const { data, isLoading, mutate } = useFetchWithToken<{
    count: number;
    images: Image[];
  }>(
    `/image?resource=${resource?.toUpperCase()}&query=${q}&take=${PER_PAGE}&skip=${
      PER_PAGE * page
    }`,
    user?.access_token as string
  );

  function handleChangePage(
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) {
    filter({ page: (newPage + 1).toString() });
  }

  const [dialogOpen, setDialogOpen] = useState(false);

  const [imageId, setImageId] = useState<number | null>(null);

  const navigate = useNavigate();

  function handleClickEdit(imageId: number) {
    navigate(`/galeria/${imageId}`);
  }

  function handleClickDelete(imageId: number) {
    setDialogOpen(true);
    setImageId(imageId);
  }

  function handleDisagree() {
    setDialogOpen(false);
  }

  async function handleAgree(imageId: number | null) {
    try {
      if (imageId !== null) {
        await deleteImage(imageId);
        enqueueSnackbar("Deletado com sucesso", { variant: "success" });
        mutate();
        setDialogOpen(false);
      } else {
        enqueueSnackbar("Id de imagem não fornecido", { variant: "error" });
      }
    } catch (err: any) {
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  return (
    <>
      <AlertDialog
        title="Excluir várias imagens"
        content="Tem certeza que deseja excluir as imagens? A ação não pode ser desfeita."
        buttonAgree="Sim"
        buttonDisagree="Não"
        handleDisagree={() => handleDisagree()}
        handleAgree={() => handleAgree(imageId)}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Stack
          direction={smBreakpoint ? "column" : "row"}
          justifyContent="space-between"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Autocomplete
            size="small"
            options={options}
            sx={{
              width: 250,
              [theme.breakpoints.down("sm")]: { width: "100%" },
            }}
            value={options.find((option) => option.value === resource)}
            getOptionLabel={(value) => value.label}
            disableClearable
            onChange={(_, { value }) => filter({ resource: value })}
            renderInput={(params) => <TextField {...params} label="Recurso" />}
          />
          <TextField
            size="small"
            placeholder="pesquisar pelo nome..."
            sx={{
              width: 250,
              [theme.breakpoints.down("sm")]: { width: "100%" },
            }}
            onChange={(e) => filter({ q: e.target.value })}
          />
        </Stack>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320 }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Imagem</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell align="right">Tipo</TableCell>
                <TableCell align="right">Criada em</TableCell>
                <TableCell align="right">Última modificação</TableCell>
                <TableCell align="right">
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => navigate("novo")}
                  >
                    Novo
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && data ? (
                data.images.map((image) => (
                  <Row
                    key={image.id}
                    image={image}
                    handleClickEdit={(imageId) => handleClickEdit(imageId)}
                    handleClickDelete={(imageId) => handleClickDelete(imageId)}
                  />
                ))
              ) : (
                <>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell />
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={data.count}
            rowsPerPage={10}
            page={page}
            onPageChange={(event, page) => handleChangePage(event, page)}
          />
        )}
      </Stack>
    </>
  );
}

export default GalleryTable;
