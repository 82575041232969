import Box from "@mui/material/Box";
import React from "react";

import { useComments } from ".";
import CommentsTable from "../../components/Tables/CommentsTable";
import { Comment } from "../../types/Comment";

function ApprovedComments() {
  const { comments, fetch } = useComments();

  const [viewComments, setViewComments] = React.useState<Comment[]>([]);

  React.useEffect(() => {
    if (comments.length > 0) {
      setViewComments(comments.filter((comment) => comment.approved === true));
    }
  }, [comments]);

  return (
    <Box width="100%">
      <CommentsTable comments={viewComments} fetch={fetch} />
    </Box>
  );
}

export default ApprovedComments;
