import styled from "@emotion/styled";
import ArticleIcon from "@mui/icons-material/Article";
import CollectionsIcon from "@mui/icons-material/Collections";
import CommentIcon from "@mui/icons-material/Comment";
import CreateIcon from "@mui/icons-material/Create";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupIcon from "@mui/icons-material/Group";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { Link } from "react-router-dom";

import AuthContext from "../../contexts/auth";
import { permittedRoutesByRoles } from "../../routes/AuthorizedRoute";

interface LinkStyledProps {
  disabled?: boolean;
}

const LinkStyled = styled(Link)<LinkStyledProps>`
  text-decoration: none;
  color: black;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

function ListItems() {
  const { user } = React.useContext(AuthContext);

  const role = user ? user.user.role : undefined;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSelectedItem = (item: string): boolean => {
    if (open && anchorEl !== null) {
      if ((anchorEl.getAttribute("id") as string).includes(item)) {
        return true;
      }
    }
    return false;
  };

  return (
    <List>
      <LinkStyled
        to="/"
        hidden={role && !permittedRoutesByRoles.posts.includes(role)}
      >
        <ListItem button key="Dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </LinkStyled>
      <LinkStyled
        to="/posts"
        hidden={role && !permittedRoutesByRoles.posts.includes(role)}
      >
        <ListItem button key="Posts">
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText primary="Posts" />
        </ListItem>
      </LinkStyled>
      <LinkStyled
        to="/categorias"
        hidden={role && !permittedRoutesByRoles.categories.includes(role)}
      >
        <ListItem button key="Categories">
          <ListItemIcon>
            <FormatListBulletedIcon />
          </ListItemIcon>
          <ListItemText primary="Categorias" />
        </ListItem>
      </LinkStyled>
      <LinkStyled
        to="/galeria"
        hidden={role && !permittedRoutesByRoles.gallery.includes(role)}
      >
        <ListItem button key="Gallery">
          <ListItemIcon>
            <CollectionsIcon />
          </ListItemIcon>
          <ListItemText primary="Galeria" />
        </ListItem>
      </LinkStyled>

      <Box
        sx={{
          display: `${
            role && !permittedRoutesByRoles.posts.includes(role)
              ? "none"
              : "block"
          }`,
        }}
      >
        <ListItem id="Comments" button key="Comments" onClick={handleClick}>
          <ListItemIcon>
            <CommentIcon />
          </ListItemIcon>
          <ListItemText primary="Comentários" />
        </ListItem>
        <Menu
          open={open && openSelectedItem("Comments")}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <LinkStyled to="/comentarios">
            <MenuItem onClick={handleClose}>Aprovados</MenuItem>
          </LinkStyled>
          <LinkStyled to="/comentarios/pendings">
            <MenuItem onClick={handleClose}>Pendentes</MenuItem>
          </LinkStyled>
        </Menu>
      </Box>
      <LinkStyled
        to="/usuarios"
        hidden={role && !permittedRoutesByRoles.users.includes(role)}
      >
        <ListItem button key="Users">
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItem>
      </LinkStyled>

      <LinkStyled
        to="/pages"
        hidden={role && !permittedRoutesByRoles.categories.includes(role)}
      >
        <ListItem button key="Pages">
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary="Páginas" />
        </ListItem>
      </LinkStyled>
      <Box
        sx={{
          display: `${
            role && !permittedRoutesByRoles.blog.includes(role)
              ? "none"
              : "block"
          }`,
        }}
      >
        <ListItem
          id="Blog"
          key="Blog"
          button
          sx={{ color: "black" }}
          onClick={handleClick}
        >
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary="Blog" />
        </ListItem>
        <Menu
          open={open && openSelectedItem("Blog")}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <LinkStyled to="/blog/posts">
            <MenuItem onClick={handleClose}>Posts</MenuItem>
          </LinkStyled>
          <LinkStyled to="/blog/categorias">
            <MenuItem onClick={handleClose}>Categorias</MenuItem>
          </LinkStyled>
        </Menu>
      </Box>
      <LinkStyled
        to="/videos"
        hidden={role && !permittedRoutesByRoles.videos.includes(role)}
      >
        <ListItem button key="Videos">
          <ListItemIcon>
            <PlayArrowOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Vídeos" />
        </ListItem>
      </LinkStyled>
    </List>
  );
}

export default ListItems;
