import { Box, Button, Grid, Modal, Stack, Typography } from "@mui/material";
import React from "react";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import { User } from "../../../types/User";
import AttachUserTable from "../../Tables/AttachUserTable";
import NewUserModal from "../NewUserModal";

interface AttachUserModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUserId: number | null;
  setSelectedUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
  "@media(max-width: 600px)": {
    width: "90%",
  },
};

function AttachUserModal({
  open,
  setOpen,
  selectedUserId,
  setSelectedUserId,
}: AttachUserModalProps) {
  const [newUserModalOpen, setNewUserModalOpen] = React.useState(false);

  const { user } = React.useContext(AuthContext);

  const { mutate } = useFetchWithToken<User[]>(
    "/users",
    user?.access_token as string
  );

  return (
    <>
      {newUserModalOpen && (
        <NewUserModal
          open={newUserModalOpen}
          setOpen={setNewUserModalOpen}
          fetch={() => mutate()}
        />
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Anexar usuário</Typography>
                <Button
                  variant="outlined"
                  onClick={() => setNewUserModalOpen(true)}
                >
                  Novo usuário
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              <AttachUserTable
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
              />
            </Grid>
            <Grid item alignSelf="end">
              <Button
                variant="contained"
                disabled={!selectedUserId}
                onClick={() => setOpen(false)}
              >
                Anexar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default AttachUserModal;
