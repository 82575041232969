/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendImage } from "../services/galleryService";

export async function handleEditorImageUpload(
  userId: string,
  blobInfo: any
): Promise<string> {
  const file = blobInfo.blob();

  try {
    const image = await sendImage(file as File, userId, "BLOG_POST");

    return Promise.resolve(image.url);
  } catch (err) {
    return Promise.reject(new Error("Ocorreu um erro!"));
  }
}
