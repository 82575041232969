import Box from "@mui/material/Box";

import BlogCategoriesTable from "../components/Tables/BlogCategoriesTable";

function BlogCategories() {
  return (
    <Box width="100%" height="800px">
      <BlogCategoriesTable />
    </Box>
  );
}

export default BlogCategories;
