import Box from "@mui/material/Box";

import NewBlogPostForm from "../../components/Forms/Blog/NewBlogPostForm";

function NewBlogPost() {
  return (
    <Box width="100%">
      <NewBlogPostForm />
    </Box>
  );
}

export default NewBlogPost;
