import { Comment, UpdateComment, NewComment, NewReply } from "../types/Comment";
import instance from "./api";

export async function createComment(newComment: NewComment): Promise<Comment> {
  const { data } = await instance.post("comment", newComment);

  return data;
}

export async function createCommentWithoutEmail(
  newComment: NewComment
): Promise<Comment> {
  const { data } = await instance.post(
    "comment/createWithoutEmail",
    newComment
  );

  return data;
}

export async function createReply(newComment: NewReply): Promise<Comment> {
  const { data } = await instance.post("comment/reply", newComment);

  return data;
}

export async function deleteComment(commentId: number) {
  const { data } = await instance.delete(`comment/${commentId}`);

  return data;
}

export async function deleteManyComments(commentsIds: number[]) {
  const { data } = await instance.delete("comment", {
    data: { commentsIds },
  });

  return data;
}

export async function updateComment(
  commentId: number,
  updateComment: Partial<UpdateComment>
): Promise<Comment> {
  const { data } = await instance.patch(`comment/${commentId}`, updateComment);

  return data;
}
