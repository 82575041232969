/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import { NewCommentUser } from "../../../../types/User";

interface NewCommentUserFormProps {
  onSubmit: SubmitHandler<NewCommentUser>;
}

function NewCommentUserForm({ onSubmit }: NewCommentUserFormProps) {
  const schema = yup.object().shape({
    full_name: yup.string().required("O Nome não pode ser vazio"),
    email: yup.string().optional().email("Endereço de e-mail não é válido"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewCommentUser>({
    defaultValues: {
      full_name: "",
      email: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Controller
          name="full_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nome completo"
              error={Boolean(errors?.full_name)}
              helperText={errors?.full_name?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="E-mail (Opcional)"
              error={Boolean(errors?.email)}
              helperText={errors?.email?.message}
            />
          )}
        />
        <Button variant="contained" type="submit">
          Criar usuário
        </Button>
      </Stack>
    </form>
  );
}

export default NewCommentUserForm;
