import instance from "./api";

export async function clearPathCache(path: string) {
  const { data } = await instance.patch(`/cache/revalidatePath`, null, {
    params: { path },
  });
  return data;
}

export async function clearPostCache(postSlug: string) {
  const { data } = await instance.patch(`/cache/revalidatePost/${postSlug}`);
  return data;
}
