import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

import NewUserForm from "../../components/Forms/Users/NewUser";

function NewUser() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <Grid
        item
        sx={{ minWidth: "320px" }}
        width={matches ? "100%" : undefined}
        justifyContent="center"
        alignItems="center"
      >
        <NewUserForm />
      </Grid>
    </Grid>
  );
}

export default NewUser;
