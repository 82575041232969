import { EditVideo, NewVideo } from "../types/Video";
import instance from "./api";

export async function addVideo(newVideo: NewVideo) {
  const { data } = await instance.post("/video", newVideo);
  return data;
}

export async function updateVideo(videoId: number, video: EditVideo) {
  const { data } = await instance.patch(`/video/${videoId}`, video);

  return data;
}

export async function deleteVideo(id: number) {
  const { data } = await instance.delete(`/video/${id}`);
  return data;
}
