/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React from "react";

import AttachPostsTable from "../../Tables/AttachPostsTable";

interface AttachPostsModalProps {
  handleAttach: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPosts: number[];
  setSelectedPosts: React.Dispatch<React.SetStateAction<number[]>>;
  singleSelection?: boolean;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
  "@media(max-width: 425px)": {
    width: "90%",
  },
};

function AttachPostsModal({
  open,
  setOpen,
  selectedPosts,
  setSelectedPosts,
  handleAttach,
  singleSelection,
}: AttachPostsModalProps) {
  const handleClose = () => {
    setOpen(false);
    setSelectedPosts([]);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Grid container direction="column">
          <Grid item height="5%">
            <Typography variant="h6">Anexar postagem</Typography>
          </Grid>
          <Grid item height="90%">
            <AttachPostsTable
              selectedPosts={selectedPosts}
              setSelectedPosts={setSelectedPosts}
              singleSelection={singleSelection}
            />
          </Grid>
          <Grid item height="5%" alignSelf="end">
            <Button
              variant="contained"
              onClick={() => handleAttach()}
              disabled={selectedPosts.length === 0}
            >
              Anexar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default AttachPostsModal;
