/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Autocomplete, Box, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";

import FilesDropzone from "../../components/Inputs/FilesDropzone";
import AuthContext from "../../contexts/auth";
import { Resource, sendImage } from "../../services/galleryService";
import { UserLogged } from "../../types/Auth";
import { Image } from "../../types/Image";

interface ThumbsProps {
  images: Image[];
}

function Thumbs({ images }: ThumbsProps) {
  return (
    <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
      {images.length > 0 ? (
        <Paper
          elevation={8}
          sx={{ width: "100%", padding: "5px", backgroundColor: "#81c784" }}
        >
          <Stack spacing={2}>
            <Typography
              variant="h6"
              align="center"
              borderRadius="4px"
              sx={{ background: "white", width: "100%" }}
            >
              Envio bem-sucedido
            </Typography>
            {images.map((image) => (
              <Stack
                key={image.id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                  <img
                    src={image.url}
                    alt=""
                    style={{ width: "80px", height: "80px" }}
                  />
                  <Typography>{image.name}</Typography>
                </Stack>

                <a
                  href={`/galeria/${image.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Editar
                </a>
              </Stack>
            ))}
          </Stack>
        </Paper>
      ) : null}
    </Stack>
  );
}

export type Options =
  | "Receita"
  | "Blog"
  | "Categoria"
  | "Subcategoria"
  | "Popup";

const mappedResource: Record<Options, Resource> = {
  Receita: "POST",
  Blog: "BLOG_POST",
  Categoria: "CATEGORY",
  Subcategoria: "SUBCATEGORY",
  Popup: "POPUP",
};

function NewImage() {
  const [files, setFiles] = useState<File[]>([]);
  const [images, setImages] = useState<Image[]>([]);
  const [resource, setResource] = useState<Resource | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(AuthContext);

  const {
    user: { id: userLoggedId },
  } = user as UserLogged;

  useEffect(() => {
    async function exec() {
      try {
        if (files.length > 0 && resource) {
          const file = files[0];

          const newImage = await sendImage(
            file,
            userLoggedId.toString(),
            resource
          );

          setImages((prev) => [...prev, newImage]);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const message = err?.response?.data.message;
          enqueueSnackbar(message, { variant: "error" });
        }
      }
    }

    exec();
  }, [files]);

  function handleAutocompleteChange(value: string | null) {
    setResource(mappedResource[value as Options] || null);
  }

  function handleDropzoneClick() {
    if (resource === null) {
      enqueueSnackbar("Selecione um recurso para enviar a imagem", {
        variant: "error",
      });
    }
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        width: "100%",
      }}
    >
      <Autocomplete
        fullWidth
        options={
          ["Receita", "Blog", "Categoria", "Subcategoria", "Popup"] as Options[]
        }
        onChange={(_, value) => handleAutocompleteChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Imagem destinada ao recurso de"
            size="medium"
          />
        )}
      />
      <Box sx={{ width: "100%" }} onClick={() => handleDropzoneClick()}>
        <FilesDropzone setFiles={setFiles} disabled={!resource} />
      </Box>
      <Thumbs images={images} />
    </Stack>
  );
}

export default NewImage;
