/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import {
  Controller,
  FieldError,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import * as yup from "yup";

import { createPopup } from "../../../../services/popupService";
import { Image } from "../../../../types/Image";
import { NewPopup, PopupPages, PopupTarget } from "../../../../types/Popup";
import MediaModal from "../../../Modals/MediaModal";

interface NewPopupFormProps {
  onFinished: () => void;
  selectedPages: PopupPages[];
}

function NewPopupForm({ onFinished, selectedPages }: NewPopupFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    href: yup.string().required("O campo Link não pode ser vazio!"),
    rel: yup.string().optional(),
    target: yup.string().required().default<PopupTarget>("_self"),
    visible: yup.boolean().required().default(false),
    pages: yup.array().of(yup.string()).min(1, "Páginas não podem ser vazias!"),
    imageId: yup.number().nullable().required("A Imagem não pode ser vazia!"),
  });

  const defaultValues: NewPopup = {
    href: "",
    target: "_self",
    rel: "",
    visible: false,
    pages: [],
    imageId: null,
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<NewPopup>({ resolver: yupResolver(schema), defaultValues });

  const onSubmit: SubmitHandler<NewPopup> = async (data) => {
    try {
      await createPopup(data);
      enqueueSnackbar("Popup criado com sucesso!", {
        variant: "success",
      });
      onFinished();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const [openMediaModal, setOpenMediaModal] = React.useState(false);

  const [image, setImage] = React.useState<Image | null>(null);

  React.useEffect(() => {
    setValue("imageId", image?.id || null);
  }, [image]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {openMediaModal && (
        <MediaModal
          open={openMediaModal}
          setOpen={setOpenMediaModal}
          setSelectedImage={setImage}
          resource="POPUP"
        />
      )}
      <Stack spacing={2}>
        <Controller
          name="href"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Link"
              size="small"
              error={Boolean(errors?.href)}
              helperText={errors?.href?.message}
            />
          )}
        />
        <FormControl>
          <InputLabel id="select-link-target">Abrir</InputLabel>
          <Controller
            name="target"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Abrir"
                labelId="select-link-target"
                size="small"
              >
                <MenuItem value="_self">Mesma página</MenuItem>
                <MenuItem value="_blank">Nova página</MenuItem>
              </Select>
            )}
          />
          <FormHelperText error={Boolean(errors?.target)}>
            {errors?.target?.message}
          </FormHelperText>
        </FormControl>
        <Controller
          name="rel"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="rel"
              size="small"
              error={Boolean(errors?.rel)}
              helperText={errors?.rel?.message}
            />
          )}
        />
        <Controller
          name="pages"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              options={["Home", "Recipe", "Blog", "BlogPost"] as PopupPages[]}
              getOptionDisabled={(option) =>
                selectedPages.includes(option as PopupPages)
              }
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              value={value}
              onChange={(_, value) => {
                onChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Páginas"
                  size="small"
                  error={Boolean(errors?.pages)}
                  helperText={(errors?.pages as unknown as FieldError)?.message}
                />
              )}
            />
          )}
        />
        <FormControlLabel
          label="Visível"
          labelPlacement="start"
          control={
            <Controller
              name="visible"
              control={control}
              render={({ field }) => <Switch {...field} />}
            />
          }
        />
        <Stack>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              height: "120px",
              backgroundColor: "#f0f0f0",
              color: "black",
              ":hover": {
                backgroundColor: "#D3D3D3",
              },
            }}
            onClick={() => setOpenMediaModal(true)}
          >
            {image ? (
              <img
                src={image.url}
                alt={image.alt || ""}
                style={{ width: "180px", height: "100%" }}
              />
            ) : (
              <span>Definir imagem</span>
            )}
          </Button>
          <FormHelperText
            error={Boolean(errors?.imageId)}
            sx={{ textAlign: "center" }}
          >
            {errors?.imageId?.message}
          </FormHelperText>
        </Stack>
        <Button variant="contained" type="submit">
          Criar
        </Button>
      </Stack>
    </form>
  );
}

export default NewPopupForm;
