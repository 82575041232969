import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

import Router from "./routes";
import theme from "./styles/theme";

export function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          maxSnack={3}
          autoHideDuration={2000}
        >
          <Router />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}
