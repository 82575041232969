import { Section, EditSection } from "../types/Section";
import instance from "./api";

export async function updateSection(
  id: number,
  section: Partial<EditSection>
): Promise<Array<Section>> {
  const { data } = await instance.patch(`section/${id}`, section);

  return data;
}
