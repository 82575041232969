import { EditBlogPost, NewBlogPost } from "../types/BlogPost";
import instance from "./api";

export async function createBlogPost(data: NewBlogPost) {
  const { data: result } = await instance.post("/blog-post", data);

  return result;
}

export async function updateBlogPost(blogPostId: number, data: EditBlogPost) {
  const { data: result } = await instance.patch(
    `/blog-post/${blogPostId}`,
    data
  );

  return result;
}

export async function deleteBlogPost(blogPostId: number) {
  const { data } = await instance.delete(`/blog-post/${blogPostId}`);

  return data;
}
