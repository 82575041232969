import red from "@mui/material/colors/red";
import yellow from "@mui/material/colors/yellow";
import { createTheme } from "@mui/material/styles";
import { ptBR } from "@mui/x-date-pickers/locales";

const theme = createTheme(
  {
    palette: {
      primary: red,
      secondary: yellow,
    },
  },
  ptBR
);

export default theme;
