import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
  useTheme,
  useMediaQuery,
  TextField,
  TableSortLabel,
  TablePagination,
  IconButton,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { orderBy } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useUsers } from "../../../pages/Users";
import { deleteManyUsers, deleteUser } from "../../../services/userService";
import { User } from "../../../types/User";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";
import AlertDialog from "../../AlertDialog";

interface RowProps {
  user: User;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setTitleDialog: React.Dispatch<React.SetStateAction<string>>;
  setContentDialog: React.Dispatch<React.SetStateAction<string>>;
  setUserId: React.Dispatch<React.SetStateAction<number>>;
  selectedUsers: Array<number>;
  setSelectedUsers: React.Dispatch<React.SetStateAction<number[]>>;
}

function Row({
  user,
  setOpenDialog,
  setTitleDialog,
  setContentDialog,
  setUserId,
  selectedUsers,
  setSelectedUsers,
}: RowProps) {
  const navigate = useNavigate();

  function handleClickDelete(userId: number) {
    setTitleDialog("Excluir usuário");
    setContentDialog(
      "Tem certeza que deseja excluir o usuário? A ação não pode ser desfeita."
    );
    setOpenDialog(true);
    setUserId(userId);
  }

  function handleToggleUsers(userId: number) {
    const currentIndex = selectedUsers.indexOf(userId);
    const newChecked = [...selectedUsers];

    if (currentIndex === -1) {
      newChecked.push(userId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedUsers(newChecked);
  }

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedUsers.indexOf(user.id) !== -1}
          onChange={() => handleToggleUsers(user.id)}
        />
      </TableCell>
      <TableCell />
      <TableCell>
        <Avatar
          sx={{ width: "60px", height: "60px" }}
          variant="rounded"
          src="https://cms-receitadevovo-gallery.s3.us-east-2.amazonaws.com/avatars/default-avatar.webp"
        />
      </TableCell>
      <TableCell>{user.full_name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.role?.name}</TableCell>
      <TableCell>{formatFromIsoToDateTime(user.createdAt as string)}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="edit"
          onClick={() => navigate(`/usuarios/${user.id}`)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => handleClickDelete(user.id)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

type filterBy = "nome" | "e-mail";

function UsersTable() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const { users, fetch } = useUsers();

  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [filter, setFilter] = React.useState("");
  const [filterBy, setFilterBy] = React.useState<filterBy>("nome");

  const [selectedUsers, setSelectedUsers] = React.useState<number[]>([]);
  const [selectionOption, setSelectionOption] = React.useState("");
  const [checkerSelectAll, setCheckerSelectAll] = React.useState(false);

  const [sortedData, setSortedData] = React.useState<User[]>([]);
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sortBy, setSortBy] = React.useState("createdAt");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [titleDialog, setTitleDialog] = React.useState("");
  const [contentDialog, setContentDialog] = React.useState("");
  const [typeOfDeletion, setTypeOfDeletion] = React.useState<
    "singleDeletion" | "manyDeletions"
  >("singleDeletion");

  const [userId, setUserId] = React.useState(0);

  function handleDisagree() {
    setOpenDialog(false);
  }

  async function handleAgree(
    typeOfDeletion: "singleDeletion" | "manyDeletions",
    userId?: number
  ) {
    try {
      switch (typeOfDeletion) {
        case "singleDeletion":
          await deleteUser(userId as number);
          fetch();
          enqueueSnackbar("Deletado com sucesso", { variant: "success" });
          setOpenDialog(false);
          break;
        case "manyDeletions":
          await deleteManyUsers(selectedUsers);
          fetch();
          enqueueSnackbar("Usuários deletadas com sucesso", {
            variant: "success",
          });
          setSelectedUsers([]);
          setSelectionOption("");
          setTypeOfDeletion("singleDeletion");
          setCheckerSelectAll(false);
          setOpenDialog(false);
          break;
        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    if (users) {
      const sorted = orderBy(users, [sortBy], [sortOrder]);
      setSortedData(sorted);
    }
  }, [users, sortOrder, sortBy]);

  function handleSort(newSortBy: string) {
    if (newSortBy !== sortBy) {
      setSortBy(newSortBy);
    } else {
      setSortOrder((state) => (state === "desc" ? "asc" : "desc"));
    }
  }

  async function handleClickApplyButton() {
    try {
      switch (selectionOption) {
        case "delete":
          setTypeOfDeletion("manyDeletions");
          setTitleDialog("Excluir vários usuários");
          setContentDialog(
            "Tem certeza que deseja excluir os usuários? A ação não pode ser desfeita."
          );
          setOpenDialog(true);
          break;
        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2} width="100%">
      <AlertDialog
        title={titleDialog}
        content={contentDialog}
        buttonAgree="Sim"
        buttonDisagree="Não"
        handleDisagree={() => handleDisagree()}
        handleAgree={() => handleAgree(typeOfDeletion, userId)}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <Stack
        width="100%"
        direction={matches ? "column" : "row"}
        justifyContent="space-between"
        component={Paper}
        elevation={8}
        padding="10px"
      >
        <Stack direction="row" spacing={2} maxWidth="400px">
          <FormControl size="small" sx={{ width: "250px" }}>
            <InputLabel id="select-option">Ação em massa</InputLabel>
            <Select
              label="Ação em massa"
              disabled={!(selectedUsers.length > 1)}
              value={selectionOption}
              onChange={(e) => setSelectionOption(e.target.value)}
            >
              <MenuItem value="">Nenhuma</MenuItem>
              <MenuItem value="delete">Excluir</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            disabled={!(selectedUsers.length > 1)}
            onClick={() => {
              handleClickApplyButton();
            }}
          >
            Aplicar
          </Button>
        </Stack>

        <Stack direction="row" spacing={2} maxWidth="400px">
          <TextField
            type="text"
            placeholder={`buscar pelo ${filterBy}...`}
            size="small"
            sx={{
              alignSelf: "flex-end",
              maxWidth: "250px",
              [theme.breakpoints.down("sm")]: {
                alignSelf: "start",
                marginTop: "10px",
              },
            }}
            onChange={(e) => setFilter(e.target.value.toLowerCase())}
          />

          <FormControl size="small" sx={{ alignSelf: "flex-end" }}>
            <Select
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value as filterBy)}
            >
              <MenuItem value="nome">Nome</MenuItem>
              <MenuItem value="e-mail">E-mail</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 320 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  disabled={!(sortedData.length > 0)}
                  checked={checkerSelectAll}
                  onChange={(e) => {
                    setCheckerSelectAll((state) => !state);
                    if (e.target.checked) {
                      setSelectedUsers(
                        sortedData.slice(0, rowsPerPage).map((el) => el.id)
                      );
                    } else {
                      setSelectedUsers([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell />
              <TableCell>Avatar</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "full_name"}
                  direction={sortOrder === "desc" ? sortOrder : "asc"}
                  onClick={() => handleSort("full_name")}
                >
                  Nome
                </TableSortLabel>
              </TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "roleId"}
                  direction={sortOrder === "desc" ? sortOrder : "asc"}
                  onClick={() => handleSort("roleId")}
                >
                  Cargo
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "createdAt"}
                  direction={sortOrder === "desc" ? sortOrder : "asc"}
                  onClick={() => handleSort("createdAt")}
                >
                  Data de cadastro
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  onClick={() => navigate("/usuarios/novo")}
                >
                  novo
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length > 0 &&
              sortedData
                .filter((user) => {
                  return filterBy === "nome"
                    ? user.full_name?.toLowerCase().includes(filter)
                    : user.email.includes(filter);
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <Row
                    key={user.id}
                    user={user}
                    setOpenDialog={setOpenDialog}
                    setTitleDialog={setTitleDialog}
                    setContentDialog={setContentDialog}
                    setUserId={setUserId}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
}

export default UsersTable;
