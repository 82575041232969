import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React from "react";

import { Resource } from "../../../services/galleryService";
import { Image } from "../../../types/Image";
import MediaModalTabs from "./Tabs";

interface MediaModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedImage: React.Dispatch<React.SetStateAction<Image | null>>;
  resource: Resource;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
};

function MediaModal({
  open,
  setOpen,
  setSelectedImage,
  resource,
}: MediaModalProps) {
  const handleClose = () => setOpen(false);

  const [currentImage, setCurrentImage] = React.useState<Image | null>(null);

  function handleSetImage() {
    setSelectedImage(currentImage);
    setOpen((state) => !state);
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          height="100%"
        >
          <Grid item sx={{ width: "100%" }} height="5%">
            <Typography variant="h6" sx={{ marginLeft: 2 }}>
              Media
            </Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }} height="90%">
            <MediaModalTabs
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
              resource={resource}
            />
          </Grid>
          <Grid item alignSelf="flex-end" height="5%">
            <Button variant="contained" onClick={() => handleSetImage()}>
              Anexar imagem
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default MediaModal;
