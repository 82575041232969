/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useUsers } from "../../../../pages/Users";
import { createUser } from "../../../../services/userService";
import { NewUser } from "../../../../types/User";
import { formatFromDateToIso } from "../../../../utils/formatDate";

function NewUserForm() {
  const { enqueueSnackbar } = useSnackbar();

  const { fetch } = useUsers();

  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Endereço de e-mail não é válido")
      .required("O campo e-mail não pode ser vazio"),
    first_name: yup
      .string()
      .required("O campo primeiro nome não pode ser vazio"),
    last_name: yup.string().required("O campo último nome não pode ser vazio"),
    gender: yup.string().required("O campo sexo não pode ser vazio"),
    password: yup
      .string()
      .required("O campo senha não pode ser vazio")
      .min(8, "A senha deve ter no mínino 8 caracteres"),
    birthDate: yup
      .string()
      .required("O campo data de nascimento não pode ser vazio"),
    roleId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required("O Cargo precisa ser definido"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewUser>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      gender: "",
      birthDate: "",
      roleId: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<NewUser> = async (data) => {
    try {
      data.birthDate = formatFromDateToIso(data.birthDate as string);

      if (data.first_name && data.last_name) {
        data.full_name = `${data.first_name} ${data.last_name}`;
      }

      await createUser(data);
      fetch();

      enqueueSnackbar("Usuário criado com sucesso!", {
        variant: "success",
      });

      navigate("/usuarios");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} padding="5px" spacing={2} elevation={2}>
        <Typography variant="h5">Adicionar novo usuário</Typography>

        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Primeiro nome"
              error={Boolean(errors?.first_name)}
              helperText={errors?.first_name?.message}
            />
          )}
        />

        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Segundo nome"
              error={Boolean(errors?.last_name)}
              helperText={errors?.last_name?.message}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="E-mail"
              error={Boolean(errors?.email)}
              helperText={errors?.email?.message}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="senha"
              type="password"
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
            />
          )}
        />

        <FormControl size="small">
          <InputLabel id="select-user-gender">Sexo</InputLabel>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId="select-user-gender"
                label="Sexo"
                error={Boolean(errors?.gender)}
              >
                <MenuItem value="m">Masculino</MenuItem>
                <MenuItem value="f">Feminino</MenuItem>
              </Select>
            )}
          />
          <FormHelperText error={Boolean(errors?.gender)}>
            {errors?.gender?.message}
          </FormHelperText>
        </FormControl>

        <Controller
          name="birthDate"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Data de nascimento"
              InputLabelProps={{ shrink: true }}
              type="date"
              error={Boolean(errors?.birthDate)}
              helperText={errors?.birthDate?.message}
            />
          )}
        />

        <FormControl size="small">
          <InputLabel id="select-user-role">Cargo</InputLabel>
          <Controller
            name="roleId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId="select-user-role"
                label="Cargo"
                error={Boolean(errors?.roleId)}
              >
                <MenuItem value="1">Admin</MenuItem>
                <MenuItem value="2">Editor</MenuItem>
                <MenuItem value="3">Contribuidor</MenuItem>
              </Select>
            )}
          />
          <FormHelperText error={Boolean(errors?.roleId)}>
            {errors?.roleId?.message}
          </FormHelperText>
        </FormControl>

        <Button variant="contained" type="submit">
          Criar usuário
        </Button>
      </Stack>
    </form>
  );
}

export default NewUserForm;
