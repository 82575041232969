/* eslint-disable react/require-default-props */
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { useFetch } from "../../../hooks/useFetch";
import { Category } from "../../../types/Category";
import { Subcategory } from "../../../types/Subcategory";

type SubcategoriesListProps = {
  subcategoriesChecked: number[];
  setSubcategoriesChecked: React.Dispatch<React.SetStateAction<number[]>>;
  error?: boolean;
  errorMessage?: string;
};

function renderRow({ index, style, data }: ListChildComponentProps) {
  const { subcategories, subcategoriesChecked, setSubcategoriesChecked } = data;
  const item = subcategories[index];

  function handleToggleSubCategories(value: number) {
    const currentIndex = subcategoriesChecked.indexOf(value);
    const newChecked = [...subcategoriesChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSubcategoriesChecked(newChecked);
  }

  return (
    <ListItem
      key={item.id}
      style={style}
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={() => handleToggleSubCategories(item.id)}
          checked={subcategoriesChecked.indexOf(item.id) !== -1}
        />
      }
      disablePadding
    >
      <ListItemButton onClick={() => handleToggleSubCategories(item.id)}>
        <ListItemText
          primary={`${item.name}`}
          secondary={`${item.category.name}`}
          sx={{ pl: 4 }}
        />
      </ListItemButton>
    </ListItem>
  );
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

function SubcategoriesList({
  subcategoriesChecked,
  setSubcategoriesChecked,
  error,
  errorMessage,
}: SubcategoriesListProps) {
  const [subcategories, setSubcategories] = React.useState<Subcategory[]>([]);

  const { data: subcategoryData, isLoading } =
    useFetch<Subcategory[]>("/subcategory/cms");

  const { data: categoryData } =
    useFetch<{ count: number; categories: Category[] }>("/category/cms");

  const [filter, setFilter] = React.useState("");

  React.useEffect(() => {
    if (subcategoryData && filter.includes("Selecionados")) {
      setSubcategories(
        subcategoryData.filter((sub) =>
          subcategoriesChecked.some((x) => x === sub.id)
        )
      );
    } else if (subcategoryData) {
      setSubcategories(
        subcategoryData.filter((sub) => sub.category.name.includes(filter))
      );
    }
  }, [subcategoryData, filter, subcategoriesChecked]);

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as string);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "300px",
      }}
    >
      <Typography variant="h6" m={1}>
        Subcategoria
      </Typography>
      <FormControl fullWidth size="small" sx={{ marginBottom: 2 }}>
        <InputLabel id="select-category-filter">Por categoria</InputLabel>
        <Select
          value={filter}
          label="Por categoria"
          labelId="select-category-filter"
          onChange={handleChange}
          MenuProps={MenuProps}
        >
          <MenuItem value="" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
            <em>Mostrar todas</em>
          </MenuItem>
          <MenuItem
            value="Selecionados"
            style={{ color: "rgba(0, 0, 0, 0.6)" }}
          >
            <em>Selecionadas</em>
          </MenuItem>
          {categoryData &&
            categoryData.categories.map((category) => (
              <MenuItem key={category.id} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText error={error}>{errorMessage}</FormHelperText>
      </FormControl>
      {!isLoading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemSize={56}
                itemCount={subcategories.length}
                itemData={{
                  subcategories,
                  subcategoriesChecked,
                  setSubcategoriesChecked,
                }}
                overscanCount={5}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      )}
    </Stack>
  );
}

export default SubcategoriesList;
