/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Editor as EditorType } from "tinymce";
import * as yup from "yup";

import AuthContext from "../../../contexts/auth";
import { createBlogPost } from "../../../services/blogPostsService";
import { NewBlogPost } from "../../../types/BlogPost";
import { Image } from "../../../types/Image";
import clearEditorText from "../../../utils/clearEditorText";
import { handleEditorImageUpload } from "../../../utils/handleEditorImageUpload";
import Editor from "../../Editor";
import BlogCategoriesList from "../../Lists/BlogCategoriesList";
import EditorEmbedModal from "../../Modals/EditorEmbedModal";
import MediaModal from "../../Modals/MediaModal";

const editorInit = {
  menubar: false,
  plugins: ["lists", "link", "image", "media", "code", "wordcount"],
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
    "p { margin: 0; }" +
    "ul { margin: 0; }" +
    "ol { margin: 0; }",
};

const toolbar =
  "undo redo blocks bold italic bullist numlist image link clearText embedYoutube code";

const schema = yup.object().shape({
  title: yup.string().required("O campo Título não pode ser vazio"),
  content: yup
    .string()
    .notRequired()
    .when("schedule", {
      is: (value: { schedule: string } | null) => {
        if (value?.schedule !== undefined) {
          return true;
        }
        return false;
      },
      then: yup
        .string()
        .required("O Conteúdo não pode ser vazio quando agendado"),
    }),
  description: yup
    .string()
    .notRequired()
    .max(160, "Limite de 160 caracteres ultrapassado"),
  keywords: yup.string().notRequired(),
  published: yup.boolean().default(false),
  authorId: yup.number().required(),
  imageId: yup
    .number()
    .notRequired()
    .when("schedule", {
      is: (value: { schedule: string } | null) => {
        if (value?.schedule !== undefined) {
          return true;
        }
        return false;
      },
      then: yup
        .number()
        .nullable()
        .required("A Imagem não pode ser vazia quando agendado"),
    }),
  categoryId: yup
    .number()
    .notRequired()
    .when("schedule", {
      is: (value: { schedule: string } | null) => {
        if (value?.schedule !== undefined) {
          return true;
        }
        return false;
      },
      then: yup
        .number()
        .nullable()
        .required("A Categoria não pode ser vazia quando agendado"),
    }),
  schedule: yup
    .object()
    .shape({
      schedule: yup
        .string()
        .nullable()
        .test("is-future-date", "A data não pode ser no passado", (value) => {
          if (!value) {
            return true;
          }

          const selectedDate = new Date(value as string);
          const currentDate = new Date();

          return selectedDate >= currentDate;
        }),
    })
    .nullable()
    .default(null),
  blogPostMeta: yup.object().shape({
    metaTitle: yup.string().nullable().max(65, "Máximo de 65 caracteres"),
  }),
});

function NewBlogPostForm() {
  const { user } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<NewBlogPost>({
    resolver: yupResolver(schema),
    defaultValues: { authorId: user?.user.id },
  });

  const [mediaModalOpen, setMediaModalOpen] = useState(false);

  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  useEffect(() => {
    if (selectedImage) {
      setValue("imageId", selectedImage.id);
    } else {
      setValue("imageId", undefined);
    }

    if (selectedCategory) {
      setValue("categoryId", selectedCategory);
    } else {
      setValue("categoryId", undefined);
    }
  }, [selectedImage, selectedCategory]);

  async function submit(data: NewBlogPost) {
    try {
      await createBlogPost(data);

      enqueueSnackbar("Post adicionado com sucesso!", {
        variant: "success",
      });

      navigate(-1);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  const contentEditorRef = useRef<EditorType>();

  const [editorEmbedModalOpen, setEditorEmbedModalOpen] = useState(false);

  const mdBreakpoint = useMediaQuery("(max-height:900px)");
  const editorHeight = mdBreakpoint ? "550px" : "750px";

  return (
    <>
      <MediaModal
        open={mediaModalOpen}
        setOpen={setMediaModalOpen}
        setSelectedImage={setSelectedImage}
        resource="BLOG_POST"
      />
      <EditorEmbedModal
        open={editorEmbedModalOpen}
        setOpen={setEditorEmbedModalOpen}
        editorRef={contentEditorRef}
      />
      <form onSubmit={handleSubmit(submit)}>
        <Grid container columns={3} spacing={1}>
          <Grid item xs={3} md={2} height={mdBreakpoint ? "600px" : "800px"}>
            <Paper
              elevation={8}
              sx={{ height: "100%", borderRadius: "10px", padding: "5px" }}
            >
              <Typography variant="h5" sx={{ marginBottom: "2px" }}>
                Conteúdo
              </Typography>
              <FormHelperText error={!!errors?.content}>
                {errors.content?.message}
              </FormHelperText>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <Editor
                    onInit={(_, editor) => {
                      contentEditorRef.current = editor;
                    }}
                    init={{
                      ...editorInit,
                      toolbar,
                      images_upload_handler: (blobInfo) =>
                        handleEditorImageUpload(
                          String(user?.user.id),
                          blobInfo
                        ),
                      height: editorHeight,
                      setup(editor) {
                        editor.ui.registry.addButton("clearText", {
                          icon: "remove-formatting",
                          tooltip: "Limpar texto",
                          onAction() {
                            editor.setContent(
                              clearEditorText(editor.getContent())
                            );
                          },
                        });
                        editor.ui.registry.addButton("embedYoutube", {
                          icon: "embed",
                          tooltip: "Anexar vídeo do Youtube",
                          onAction() {
                            setEditorEmbedModalOpen(true);
                          },
                        });
                      },
                    }}
                    onEditorChange={(e, editor) => {
                      field.onChange(editor.getContent());
                    }}
                  />
                )}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} md={1} height={mdBreakpoint ? "600px" : "800px"}>
            <Stack
              direction="column"
              spacing={2}
              p="5px"
              height="100%"
              overflow="auto"
            >
              <Controller
                name="title"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextField
                    type="text"
                    label="Título"
                    onChange={onChange}
                    error={Boolean(errors?.title)}
                    helperText={errors?.title?.message}
                  />
                )}
              />
              <Controller
                name="keywords"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextField
                    type="text"
                    label="Palavras-chave (keywords)"
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="schedule"
                control={control}
                render={() => (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                        }}
                        ampm={false}
                        label="Agendar"
                        onAccept={(newValue: dayjs.Dayjs | null) => {
                          if (newValue) {
                            setValue(
                              "schedule.schedule",
                              newValue.toISOString()
                            );
                          } else {
                            setValue("schedule", null);
                          }
                        }}
                      />
                    </LocalizationProvider>
                    {errors.schedule && (
                      <FormHelperText error={Boolean(errors.schedule)}>
                        {
                          (errors.schedule as { schedule: FieldError })
                            ?.schedule?.message
                        }
                      </FormHelperText>
                    )}
                  </>
                )}
              />
              <Paper sx={{ width: "100%", padding: "5px" }}>
                <Typography variant="h6" color="rgba(0, 0, 0, 0.6)">
                  Imagem principal
                </Typography>
                <FormHelperText error={!!errors?.imageId}>
                  {errors.imageId?.message}
                </FormHelperText>
                {!selectedImage ? (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      height: "120px",
                      backgroundColor: "rgba(0,0,0,0.6)",
                      color: "white",
                    }}
                    onClick={() => setMediaModalOpen(true)}
                  >
                    Definir imagem
                  </Button>
                ) : (
                  <Button fullWidth onClick={() => setMediaModalOpen(true)}>
                    <img
                      src={selectedImage.url}
                      alt={selectedImage?.alt || ""}
                      style={{ width: "200px", height: "120px" }}
                    />
                  </Button>
                )}
              </Paper>
              <Stack
                component={Paper}
                variant="outlined"
                sx={{ padding: "5px" }}
                spacing={2}
              >
                <Typography variant="h6">SEO</Typography>
                <Controller
                  name="blogPostMeta.metaTitle"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Meta título"
                      type="text"
                      size="medium"
                      fullWidth
                      onChange={onChange}
                      error={Boolean(errors.blogPostMeta?.metaTitle)}
                      helperText={errors.blogPostMeta?.metaTitle?.message}
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Meta descrição"
                      type="text"
                      multiline
                      size="medium"
                      fullWidth
                      onChange={onChange}
                      error={Boolean(errors.description)}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              </Stack>
              <BlogCategoriesList
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                error={!!errors?.categoryId}
                errorMessage={errors?.categoryId?.message}
              />
              <Button type="submit" variant="contained">
                Criar postagem
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default NewBlogPostForm;
