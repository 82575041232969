/* eslint-disable react/jsx-props-no-spreading */
import HideImageIcon from "@mui/icons-material/HideImage";
import ImageIcon from "@mui/icons-material/Image";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect, useState } from "react";

import { Resource } from "../../../../services/galleryService";
import { Image } from "../../../../types/Image";
import ImageList from "../../../Lists/ImageList";
import UploadImage from "../UploadImage";

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%", height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width: "100%", height: "100%", p: 3 }}>{children}</Box>
      )}
    </Box>
  );
}

interface MediaModalTabsProps {
  currentImage: Image | null;
  setCurrentImage: React.Dispatch<React.SetStateAction<Image | null>>;
  resource: Resource;
}

export default function MediaModalTabs({
  currentImage,
  setCurrentImage,
  resource,
}: MediaModalTabsProps) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [check, setCheck] = useState(!currentImage);

  useEffect(() => {
    setCheck(!currentImage);
  }, [currentImage]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", height: "10%" }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Enviar imagem" />
          <Tab label="Biblioteca de mídia" />
        </Tabs>
      </Box>
      <Box sx={{ height: "90%" }}>
        <TabPanel value={value} index={0}>
          <UploadImage setTabValue={setValue} resource={resource} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack height="100%">
            <Checkbox
              sx={{
                maxHeight: "10%",
                alignSelf: "flex-start",
              }}
              checked={check}
              onClick={() => setCurrentImage(null)}
              icon={<ImageIcon />}
              checkedIcon={<HideImageIcon />}
              title="Sem imagem"
            />
            <ImageList
              sx={{ height: "90%" }}
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
            />
          </Stack>
        </TabPanel>
      </Box>
    </Box>
  );
}
