/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */

import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import { deleteBlogPost } from "../../../services/blogPostsService";
import { clearPathCache } from "../../../services/cacheService";
import { UserLogged } from "../../../types/Auth";
import { BlogPost } from "../../../types/BlogPost";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";
import AlertDialog from "../../AlertDialog";

interface RowProps {
  post: BlogPost;
  handleDelete: () => void;
}

function Row({ post, handleDelete }: RowProps) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  async function handleClearCache() {
    try {
      await clearPathCache(`/blog/posts/${post.slug}`);
      enqueueSnackbar("Cache limpo", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Erro ao limpar o cache", { variant: "error" });
    }
  }

  return (
    <TableRow>
      <TableCell />
      <TableCell component="th" scope="row">
        {post.title}
      </TableCell>
      <TableCell align="right">{post.slug}</TableCell>
      <TableCell align="right">{`${post.author.first_name} ${post.author.last_name}`}</TableCell>
      <TableCell align="right">{`${post.published ? "Sim" : "Não"}`}</TableCell>
      <TableCell align="right">
        {post.publishedAt
          ? formatFromIsoToDateTime(post.publishedAt as string)
          : "-"}
      </TableCell>
      <TableCell align="right">
        {formatFromIsoToDateTime(post.createdAt as string)}
      </TableCell>
      <TableCell align="right">
        {formatFromIsoToDateTime(post.updatedAt as string)}
      </TableCell>
      <TableCell align="right">
        <IconButton
          title="Editar post"
          onClick={() => navigate(`${post.id}/edit`)}
        >
          <EditIcon />
        </IconButton>
        <IconButton title="Deletar post" onClick={() => handleDelete()}>
          <DeleteIcon />
        </IconButton>
        <IconButton title="Limpar cache" onClick={() => handleClearCache()}>
          <CleaningServicesIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

type sortKey = "createdAt" | "updatedAt" | "publishedAt" | "title";

function BlogPostsTable() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get("q") ?? "";
  const published = searchParams.get("published") ?? "true";
  const sortBy = searchParams.get("sort") ?? "createdAt";
  const sortOrder = searchParams.get("order") ?? "desc";
  const page = searchParams.get("page")
    ? Number(searchParams.get("page")) - 1
    : 0;

  function filter(params: Record<string, string | string[]>) {
    const currentFilters = Object.fromEntries(searchParams);

    const resFilters = { ...currentFilters, ...params };

    if (currentFilters.published !== resFilters.published) {
      Object.defineProperty(resFilters, "page", { value: 1 });
    }

    setSearchParams(resFilters);
  }

  const PER_PAGE = 10;

  const { user } = useContext(AuthContext);

  function handleStatusFilter(e: SelectChangeEvent<number>) {
    filter({
      published: e.target.value === 1 ? "true" : "false",
    });
  }

  const { data, isLoading, mutate } = useFetchWithToken<{
    count: number;
    posts: BlogPost[];
  }>(
    `/blog-post/accessibleByUser?published=${published}&take=${PER_PAGE}&skip=${
      PER_PAGE * page
    }&title=${q}&sort=${sortBy}:${sortOrder}`,
    (user as UserLogged).access_token
  );

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    filter({ page: (newPage + 1).toString() });
  };

  const handleSort = (newSortBy: sortKey) => {
    if (newSortBy !== sortBy) {
      filter({ sort: newSortBy, order: "desc" });
    } else {
      filter({
        sort: sortBy,
        order: sortOrder === "desc" ? "asc" : "desc",
      });
    }
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [blogPostId, setBlogPostId] = useState<number | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  function handleDelete(blogPostId: number) {
    setDialogOpen(true);
    setBlogPostId(blogPostId);
  }

  async function handleAgree() {
    try {
      if (blogPostId !== null) {
        await deleteBlogPost(blogPostId);
        mutate();
        setDialogOpen(false);
      } else {
        throw new TypeError("Erro ao capturar id do blog!");
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      } else if (err instanceof TypeError) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    }
  }

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        title="Excluir Postagem"
        content="Tem certeza que deseja excluir a postagem? A ação não pode ser desfeita."
        buttonAgree="Sim"
        buttonDisagree="Não"
        handleAgree={() => handleAgree()}
        handleDisagree={() => setDialogOpen(false)}
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <FormControl fullWidth size="small">
          <InputLabel>Status</InputLabel>
          <Select
            value={published === "true" ? 1 : 0}
            label="Status"
            onChange={(e) => handleStatusFilter(e)}
          >
            <MenuItem value={1}>Publicado</MenuItem>
            <MenuItem value={0}>Pendente</MenuItem>
          </Select>
        </FormControl>
        <TextField
          type="text"
          placeholder="Buscar pelo título..."
          size="small"
          fullWidth
          defaultValue={q}
          onChange={(e) =>
            filter({
              q: e.target.value.toLowerCase().trim(),
            })
          }
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320 }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "title"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => handleSort("title")}
                  >
                    Título
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">Slug</TableCell>
                <TableCell align="right">Autor</TableCell>
                <TableCell align="right">Publicado</TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortBy === "publishedAt"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => handleSort("publishedAt")}
                  >
                    Publicado em
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortBy === "createdAt"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => handleSort("createdAt")}
                  >
                    Criado em
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortBy === "updatedAt"}
                    direction={sortOrder === "asc" ? "asc" : "desc"}
                    onClick={() => handleSort("updatedAt")}
                  >
                    Atualizado em
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => navigate("/blog/posts/novo")}
                  >
                    Novo
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && data ? (
                data.posts.map((post) => (
                  <Row
                    key={post.id}
                    post={post}
                    handleDelete={() => handleDelete(post.id)}
                  />
                ))
              ) : (
                <>
                  {Array.from({ length: 10 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell />
                      <TableCell component="th" scope="row">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={data.count}
            rowsPerPage={10}
            page={page}
            onPageChange={(event, page) => handleChangePage(event, page)}
          />
        )}
      </Stack>
    </>
  );
}

export default BlogPostsTable;
