import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Dispatch } from "react";

import theme from "../../../styles/theme";
import NewVideoForm from "../../Forms/Videos/NewVideoForm";

interface NewVideoModalProps {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  fetch: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
};

function NewVideoModal({ open, setOpen, fetch }: NewVideoModalProps) {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <NewVideoForm fetch={fetch} onFinished={() => setOpen(false)} />
      </Box>
    </Modal>
  );
}

export default NewVideoModal;
