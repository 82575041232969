/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  FormControlLabel,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { updateSection } from "../../../services/sectionsService";
import { Page } from "../../../types/Page";
import { EditSection, Section } from "../../../types/Section";
import AttachPostsModal from "../AttachPostsModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  height: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  "@media(max-width: 1536px)": {
    width: "50%",
  },
  "@media(max-width: 600px)": {
    width: "90%",
  },
};

const schema = yup.object().shape({
  name: yup.string().required("O campo Nome não pode ser vazio!"),
  visible: yup.boolean(),
});

interface SectionEditionModalProps {
  fetch: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  section: Section;
  page: Page;
}

function SectionEditionModal({
  fetch,
  open,
  setOpen,
  section,
  page,
}: SectionEditionModalProps) {
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<EditSection>({
    resolver: yupResolver(schema),
  });

  const [attachPostsModalOpen, setAttachPostsModalOpen] = React.useState(false);
  const [selectedPosts, setSelectedPosts] = React.useState<number[]>(
    section.posts.map((post) => post.id)
  );

  React.useEffect(() => {
    setValue("name", section.name);
    setSelectedPosts(section.posts.map((post) => post.id));
  }, [section]);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  function handleAttach() {
    setAttachPostsModalOpen(false);
  }

  async function onSubmit(data: EditSection) {
    try {
      await updateSection(section.id, {
        ...data,
        posts: selectedPosts.map((postId) => ({ id: postId })),
      });

      fetch();
      enqueueSnackbar("Seção atualiazda com sucesso!", {
        variant: "success",
      });
      handleClose();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack sx={style} spacing={2}>
        <AttachPostsModal
          handleAttach={() => handleAttach()}
          open={attachPostsModalOpen}
          setOpen={setAttachPostsModalOpen}
          selectedPosts={selectedPosts}
          setSelectedPosts={setSelectedPosts}
        />
        <Typography variant="h6">{`Editando Seção '${section.name}'`}</Typography>
        <TextField
          type="text"
          size="small"
          disabled
          label="Página"
          value={page.name}
        />
        <TextField
          type="text"
          label="Nome da seção"
          defaultValue={section.name}
          {...register("name")}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
        />
        <FormControlLabel
          control={
            <Switch defaultChecked={section.visible} {...register("visible")} />
          }
          label="Visível"
          labelPlacement="start"
        />

        <Badge badgeContent={selectedPosts.length} color="secondary" showZero>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setAttachPostsModalOpen(true)}
          >
            Alterar postagens
          </Button>
        </Badge>

        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Atualizar
        </Button>
      </Stack>
    </Modal>
  );
}

export default SectionEditionModal;
