/* eslint-disable react/no-array-index-key */
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useFetch } from "../../../hooks/useFetch";
import { deleteBlogCategory } from "../../../services/blogCategoriesService";
import { clearPathCache } from "../../../services/cacheService";
import { BlogCategory, EditBlogCategory } from "../../../types/BlogCategory";
import AlertDialog from "../../AlertDialog";
import NewBlogCategoryModal from "../../Modals/BlogCategoriesModals/NewBlogCategoryModal";
import UpdateBlogCategoryModal from "../../Modals/BlogCategoriesModals/UpdateBlogCategoryModal";

interface RowProps {
  category: BlogCategory;
  handleDelete: () => void;
  handleEdit: () => void;
}

function Row({ category, handleDelete, handleEdit }: RowProps) {
  const { enqueueSnackbar } = useSnackbar();

  async function handleClearCache() {
    try {
      await clearPathCache(`/blog/categorias/${category.slug}`);
      enqueueSnackbar("Cache limpo", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Erro ao limpar o cache", { variant: "error" });
    }
  }

  return (
    <TableRow>
      <TableCell />
      <TableCell component="th" scope="row">
        {category.name}
      </TableCell>
      <TableCell>{category.slug}</TableCell>
      <TableCell>{category.createdAt}</TableCell>
      <TableCell>{category.updatedAt}</TableCell>
      <TableCell align="right">
        <IconButton title="Editar categoria" onClick={() => handleEdit()}>
          <EditIcon />
        </IconButton>
        <IconButton title="Deletar categoria" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
        <IconButton title="Limpar cache" onClick={() => handleClearCache()}>
          <CleaningServicesIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function BlogCategoriesTable() {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: categories,
    isLoading,
    mutate,
  } = useFetch<BlogCategory[]>("/blog-category");

  const [newBlogCategoryModalState, setNewBlogCategoryModalState] =
    useState(false);

  const [updateBlogCategoryModalState, setUpdateBlogCategoryModalState] =
    useState(false);
  const [blogCategoryEdited, setBlogCategoryEdited] =
    useState<EditBlogCategory | null>(null);

  const [dialogState, setDialogState] = useState(false);
  const [blogCategoryId, setBlogCategoryId] = useState<number | null>(null);

  const handleDelete = (blogCategoryId: number) => {
    setBlogCategoryId(blogCategoryId);
    setDialogState(true);
  };

  const handleEdit = (editBlogCategory: EditBlogCategory) => {
    setBlogCategoryEdited(editBlogCategory);
    setUpdateBlogCategoryModalState(true);
  };

  const handleAgree = async () => {
    try {
      if (blogCategoryId !== null) {
        await deleteBlogCategory(blogCategoryId);
        mutate();
        setDialogState(false);
      } else {
        throw new TypeError("Erro ao capturar id do blog!");
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      } else if (err instanceof TypeError) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    }
  };

  return (
    <>
      <AlertDialog
        open={dialogState}
        setOpen={setDialogState}
        title="Excluir Postagem"
        content="Tem certeza que deseja excluir a categoria? A ação não pode ser desfeita."
        buttonAgree="Sim"
        buttonDisagree="Não"
        handleAgree={() => handleAgree()}
        handleDisagree={() => setDialogState(false)}
      />
      <NewBlogCategoryModal
        open={newBlogCategoryModalState}
        setOpen={setNewBlogCategoryModalState}
        refetch={mutate}
      />
      {blogCategoryEdited && (
        <UpdateBlogCategoryModal
          open={updateBlogCategoryModalState}
          setOpen={setUpdateBlogCategoryModalState}
          data={blogCategoryEdited}
          refetch={mutate}
        />
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: "320px" }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nome</TableCell>
              <TableCell>Slug</TableCell>
              <TableCell>Criada em</TableCell>
              <TableCell>Atualizada em</TableCell>
              <TableCell align="right">
                <Button
                  title="Nova categoria"
                  variant="contained"
                  onClick={() => setNewBlogCategoryModalState(true)}
                >
                  Nova
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && categories ? (
              categories?.map((category) => (
                <Row
                  key={category.id}
                  category={category}
                  handleDelete={() => handleDelete(category.id)}
                  handleEdit={() => handleEdit(category)}
                />
              ))
            ) : (
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell />
                    <TableCell component="th" scope="row">
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default BlogCategoriesTable;
