import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import AuthContext from "../contexts/auth";

function PrivateRoute() {
  const { signed } = React.useContext(AuthContext);

  return signed ? <Outlet /> : <Navigate to="/signin" />;
}

export default PrivateRoute;
