import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { SubmitHandler } from "react-hook-form";

import { createCommentWithoutEmail } from "../../../services/commentsService";
import { NewComment as NewCommentType } from "../../../types/Comment";
import NewCommentForm from "../../Forms/Comments/NewComment";

interface NewCommentModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
  "@media(max-width: 900px)": {
    width: "90%",
  },
};

function NewCommentModal({ open, setOpen, fetch }: NewCommentModal) {
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => setOpen(false);

  const onSubmitNewCommentForm: SubmitHandler<NewCommentType> = async (
    data
  ) => {
    try {
      await createCommentWithoutEmail(data);
      fetch();

      enqueueSnackbar("Comentário criado com sucesso", { variant: "success" });
      handleClose();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">Novo comentário</Typography>
          </Grid>
          <Grid item>
            <NewCommentForm onSubmit={onSubmitNewCommentForm} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default NewCommentModal;
