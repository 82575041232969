/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TablePagination,
  TableSortLabel,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import { Category } from "../../../types/Category";
import { Subcategory } from "../../../types/Subcategory";
import EditCategoryModal from "../../Modals/EditCategoryModal";
import EditSubcategoryModal from "../../Modals/EditSubcategoryModal";
import NewCategoryModal from "../../Modals/NewCategoryModal";
import NewSubcategoryModal from "../../Modals/NewSubcategoryModal";
import { Row } from "./Row";

type FilterForm = {
  type: "categoryName" | "subcategoryName" | string;
  q: string;
};

export default function CategoriesTable() {
  const [searchParams, setSearchParams] = useSearchParams();

  const PER_PAGE = 6;

  const page = searchParams.get("page")
    ? Number(searchParams.get("page")) - 1
    : 0;
  const q = searchParams.get("q") || "";
  const type = searchParams.get("type") || "categoryName";

  const { user } = React.useContext(AuthContext);

  const { data, isLoading, mutate } = useFetchWithToken<{
    count: number;
    categories: Category[];
  }>(
    `/category/cms?take=${PER_PAGE}&skip=${PER_PAGE * page}&${type}=${q}`,
    user?.access_token as string
  );

  function filter(params: Record<string, string | string[]>) {
    const currentFilters = Object.fromEntries(searchParams);

    const resFilters = { ...currentFilters, ...params };

    if (currentFilters.published !== resFilters.published) {
      Object.defineProperty(resFilters, "page", { value: 1 });
    }

    setSearchParams(resFilters);
  }

  const theme = useTheme();
  const lgBreakpoint = useMediaQuery(theme.breakpoints.down("lg"));
  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    filter({ page: (newPage + 1).toString() });
  };

  const [newCategoryModalOpen, setNewCategoryModalOpen] = React.useState(false);

  const [editCategoryModalOpen, setEditCategoryModalOpen] =
    React.useState(false);

  const [categoryToEdit, setCategoryToEdit] = React.useState<Category | null>(
    null
  );

  const [newSubcategoryModalOpen, setNewSubcategoryModalOpen] =
    React.useState(false);

  const [editSubcategoryModalOpen, setEditSubcategoryModalOpen] =
    React.useState(false);

  const [subcategoryToEdit, setSubcategoryToEdit] =
    React.useState<Subcategory | null>(null);

  const { control, setValue, reset, handleSubmit } = useForm<FilterForm>({
    defaultValues: {
      type,
      q,
    },
  });

  function handleFilterFormSubmit(data: FilterForm) {
    filter({ ...data, page: "1" });
  }

  React.useEffect(() => {
    setValue("type", type);
    setValue("q", q);
  }, [type, q]);

  return (
    <>
      {newCategoryModalOpen && (
        <NewCategoryModal
          open={newCategoryModalOpen}
          setOpen={setNewCategoryModalOpen}
          fetch={mutate}
        />
      )}
      {newSubcategoryModalOpen && (
        <NewSubcategoryModal
          open={newSubcategoryModalOpen}
          setOpen={setNewSubcategoryModalOpen}
          fetch={mutate}
        />
      )}
      {categoryToEdit && (
        <EditCategoryModal
          open={editCategoryModalOpen}
          setOpen={setEditCategoryModalOpen}
          category={categoryToEdit}
          fetch={mutate}
        />
      )}
      {subcategoryToEdit && (
        <EditSubcategoryModal
          open={editSubcategoryModalOpen}
          setOpen={setEditSubcategoryModalOpen}
          subcategory={subcategoryToEdit}
          fetch={mutate}
        />
      )}
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="column"
        width="100%"
        height="100%"
        spacing={2}
      >
        <Stack
          direction={lgBreakpoint ? "column" : "row"}
          justifyContent="space-between"
          spacing={1}
          sx={{ width: "100%" }}
        >
          <form
            style={{
              alignSelf: "flex-start",
              width: smBreakpoint ? "100%" : "auto",
            }}
            onSubmit={handleSubmit(handleFilterFormSubmit)}
          >
            <Stack
              width="100%"
              direction={smBreakpoint ? "column" : "row"}
              justifyContent="flex-start"
              spacing={2}
              component={Paper}
              elevation={8}
              padding="10px"
            >
              <FormControl>
                <InputLabel id="select-q-label">buscar por</InputLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="select-q-label"
                      id="select-q"
                      label="buscar por"
                      size="small"
                      value={value}
                      onChange={onChange}
                      sx={{
                        width: 250,
                        [theme.breakpoints.down("lg")]: {
                          width: "200px",
                        },
                        [theme.breakpoints.down("sm")]: {
                          width: "100%",
                        },
                      }}
                    >
                      <MenuItem value="categoryName">Categoria</MenuItem>
                      <MenuItem value="subcategoryName">Subcategoria</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <Controller
                name="q"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    type="text"
                    placeholder={
                      type === "categoryName"
                        ? "nome da categoria"
                        : "nome da subcategoria"
                    }
                    size="small"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            title="Limpar campo"
                            onClick={() => reset({ q: "" })}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "250px",
                      [theme.breakpoints.down("lg")]: {
                        width: "200px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                      },
                    }}
                  />
                )}
              />
              <Button type="submit" variant="contained">
                Aplicar
              </Button>
            </Stack>
          </form>

          <Stack
            width={smBreakpoint ? "100%" : "380px"}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            component={Paper}
            elevation={8}
            padding="10px"
          >
            <Button
              variant="contained"
              onClick={() => setNewCategoryModalOpen(true)}
            >
              Nova categoria
            </Button>
            <Button
              variant="contained"
              onClick={() => setNewSubcategoryModalOpen(true)}
            >
              Nova subcategoria
            </Button>
          </Stack>
        </Stack>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320 }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>
                  <TableSortLabel>Nome</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>Slug</TableSortLabel>
                </TableCell>
                <TableCell>Imagem</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && data ? (
                data.categories.map((category) => (
                  <Row
                    key={category.id}
                    category={category}
                    fetch={mutate}
                    setEditCategoryModalOpen={setEditCategoryModalOpen}
                    setCategoryToEdit={setCategoryToEdit}
                    setEditSubcategoryModalOpen={setEditSubcategoryModalOpen}
                    setSubcategoryToEdit={setSubcategoryToEdit}
                  />
                ))
              ) : (
                <>
                  {Array.from({ length: PER_PAGE }).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <Skeleton />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={data.count}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
          />
        )}
      </Stack>
    </>
  );
}
