import { EditImage, Image } from "../types/Image";
import instance from "./api";

export type Resource =
  | "POST"
  | "BLOG_POST"
  | "CATEGORY"
  | "SUBCATEGORY"
  | "POPUP";

export async function sendImage(
  file: File,
  authorId: string,
  resource: Resource
): Promise<Image> {
  const formData = new FormData();

  formData.append("file", file);

  formData.append("authorId", authorId);

  formData.append("resource", resource);

  const { data } = await instance.post("/image", formData);

  return data;
}

export async function deleteImage(imageId: number) {
  const { data } = await instance.delete(`/image/${imageId}`);

  return data;
}

export async function deleteManyImages(imageIds: number[]) {
  const { data } = await instance.delete("/image", { data: { imageIds } });

  return data;
}

export async function updateImage(
  imageId: number,
  editImageData: EditImage
): Promise<Image> {
  const { data } = await instance.patch(`/image/${imageId}`, editImageData);

  return data;
}
