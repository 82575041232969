import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useParams } from "react-router-dom";

import { useUsers } from ".";
import EditUserForm from "../../components/Forms/Users/EditUser";
import { User } from "../../types/User";

function EditUser() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { userId } = useParams();

  const { users } = useUsers();

  const [user, setUser] = React.useState<User | undefined>(undefined);

  React.useEffect(() => {
    const user = users.find((user) => user.id === Number(userId));
    setUser(user);
  }, [users]);

  return (
    <Grid container>
      <Grid
        item
        sx={{ minWidth: "320px" }}
        width={matches ? "100%" : undefined}
        justifyContent="center"
        alignItems="center"
      >
        {user && <EditUserForm user={user} />}
      </Grid>
    </Grid>
  );
}

export default EditUser;
