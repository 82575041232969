/* eslint-disable react/jsx-props-no-spreading */
import { Editor as TinymceEditor } from "@tinymce/tinymce-react";
import { IAllProps } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";

type EditorProps = IAllProps;

function Editor(props: EditorProps) {
  return (
    <TinymceEditor {...props} apiKey={process.env.REACT_APP_TINYMCE_API_KEY} />
  );
}

export default Editor;
