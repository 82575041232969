/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import AuthContext from "../../contexts/auth";
import { useFetchWithToken } from "../../hooks/useFetch";
import { updateImage } from "../../services/galleryService";
import { Image, EditImage as EditImageType } from "../../types/Image";
import { cleanImageName } from "../../utils/cleanImageName";
import { formatFromIsoToDateTime } from "../../utils/formatDate";

const schema = yup.object().shape({
  name: yup.string().required("O campo nome não pode ser vazio"),
});

function EditImage() {
  const { imageId } = useParams();

  const { user } = React.useContext(AuthContext);

  const { data: image, mutate } = useFetchWithToken<Image>(
    `/image/findById/${imageId}`,
    user?.access_token as string
  );

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditImageType>({ resolver: yupResolver(schema) });

  async function saveImage(data: EditImageType) {
    // eslint-disable-next-line no-param-reassign
    data.name += cleanImageName(image?.name as string).typeOfImage;

    try {
      await updateImage(image?.id as number, data);
      mutate();

      enqueueSnackbar("Atualizado com sucesso", { variant: "success" });
      navigate(-1);
    } catch (err: any) {
      const message = err?.response?.data.message;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      height="800px"
      spacing={1}
    >
      <Grid item xs={10}>
        <Paper
          elevation={8}
          sx={{
            height: "100%",
            padding: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {image && (
            <Stack spacing={2}>
              <Controller
                name="name"
                control={control}
                defaultValue={cleanImageName(image.name).cleanedName || ""}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="Nome da imagem"
                    size="small"
                    fullWidth
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                  />
                )}
              />

              <Typography>
                Link da imagem:{" "}
                <a href={image.url} target="_blank" rel="noreferrer">
                  {image.url}
                </a>
              </Typography>

              <Paper
                elevation={8}
                sx={{ width: "800px", height: "400px", padding: "5px" }}
              >
                <img
                  src={image.url}
                  alt={image.name}
                  style={{ width: "100%", height: "100%" }}
                />
              </Paper>

              <Controller
                name="alt"
                control={control}
                defaultValue={image.alt || ""}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="Texto alternativo"
                    size="small"
                    fullWidth
                    helperText="Este texto ajuda ferramentas de leitura de tela a descrever imagens para deficientes visuais e permite que mecanismos de pesquisa rastreiem e classifiquem o site."
                  />
                )}
              />

              <Controller
                name="caption"
                control={control}
                defaultValue={image.caption || ""}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="Legenda"
                    size="small"
                    fullWidth
                    helperText="Este texto descreve e/ou informa a fonte da imagem."
                  />
                )}
              />
            </Stack>
          )}
        </Paper>
      </Grid>
      <Grid item xs={2}>
        <Paper
          elevation={8}
          sx={{
            height: "100%",
            padding: "5px",
          }}
        >
          {image && (
            <Stack spacing={2}>
              <Typography>
                Publicado em:{" "}
                <strong>
                  {formatFromIsoToDateTime(image.createdAt as string)}
                </strong>
              </Typography>
              <Typography>
                Enviado por:{" "}
                <strong>
                  {`${image.author.first_name} ${image.author.last_name}`}
                </strong>
              </Typography>
              <Typography>
                Tipo do arquivo:{" "}
                <strong>
                  {image.mimitype.replace("image/", "").toUpperCase()}
                </strong>
              </Typography>
              <Button variant="contained" onClick={handleSubmit(saveImage)}>
                Salvar alterações
              </Button>
            </Stack>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default EditImage;
