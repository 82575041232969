import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { updateBlogCategory } from "../../../services/blogCategoriesService";
import { EditBlogCategory } from "../../../types/BlogCategory";

interface UpdateBlogCategoryModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: EditBlogCategory;
  refetch: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  height: "40%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  "@media(max-width: 425px)": {
    width: "90%",
  },
};

const schema = yup.object().shape({
  name: yup.string().required("O campo Nome não pode ser vazio"),
  slug: yup.string().notRequired(),
  description: yup.string().nullable().notRequired(),
});

function UpdateBlogCategoryModal({
  open,
  setOpen,
  data,
  refetch,
}: UpdateBlogCategoryModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<EditBlogCategory>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      name: data.name,
      slug: data.slug,
      description: data?.description || "",
    });
  }, [data]);

  const submit = async (editBlogCategory: EditBlogCategory) => {
    try {
      if (data.id !== undefined) {
        await updateBlogCategory(data.id, editBlogCategory);
        refetch();
        enqueueSnackbar("Categoria atualizada", { variant: "success" });
        handleClose();
      } else {
        throw new TypeError("Erro ao capturar id da postagem!");
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      } else if (err instanceof TypeError) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <form onSubmit={handleSubmit(submit)} style={{ height: "100%" }}>
          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{ height: "100%" }}
          >
            <Stack direction="column" gap={1}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    label="Nome"
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                  />
                )}
              />
              <Controller
                name="slug"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    label="Slug"
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors?.slug)}
                    helperText={errors?.slug?.message}
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    label="Descrição (opcional)"
                    multiline
                    value={value}
                    onChange={onChange}
                    maxRows={6}
                  />
                )}
              />
            </Stack>
            <Button
              type="submit"
              title="Criar categoria"
              fullWidth
              variant="contained"
            >
              Atualizar
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}

export default UpdateBlogCategoryModal;
