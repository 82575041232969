export default function clearEditorText(htmlString: string) {
  const removeMetadata = /data-sourcepos="[^"]*"/gi;

  const removeClass = /class\s*=\s*["'][^"']*["']/gi;

  const removeStyle = / style="[^"]*"/gi;

  const res = htmlString
    .replace(removeMetadata, "")
    .replace(removeClass, "")
    .replace(removeStyle, "");

  return res;
}
