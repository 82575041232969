/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import {
  Autocomplete,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import List from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "react-lazy-load-image-component/src/effects/blur.css";
import React, { ChangeEvent, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import theme from "../../../styles/theme";
import { Image } from "../../../types/Image";

interface ImageListProps {
  currentImage: Image | null;
  setCurrentImage: React.Dispatch<React.SetStateAction<Image | null>>;
  sx?: SxProps<Theme>;
}

type OptionType = {
  label: string;
  value: string;
};

const options: OptionType[] = [
  { label: "Todos", value: "" },
  { label: "Receita", value: "post" },
  { label: "Blog", value: "blog_post" },
  { label: "Categoria", value: "category" },
  { label: "Subcategoria", value: "subcategory" },
  { label: "Popup", value: "popup" },
];

function ImageList({ currentImage, setCurrentImage, sx }: ImageListProps) {
  const [page, setPage] = React.useState(1);

  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));
  const mdBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

  const cols = smBreakpoint ? 3 : mdBreakpoint ? 5 : 10;

  const PER_PAGE = smBreakpoint ? 18 : mdBreakpoint ? 25 : 40;

  const [term, setTerm] = useState("");
  const [resource, setResource] = useState<OptionType>(
    options.find((option) => option.label === "Todos") as OptionType
  );

  const handlePageChange = (_: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const { user } = React.useContext(AuthContext);

  const { isLoading, data } = useFetchWithToken<{
    count: number;
    images: Image[];
  }>(
    `/image?resource=${resource.value.toUpperCase()}&query=${term}&take=${PER_PAGE}&skip=${
      PER_PAGE * (page - 1)
    }`,
    user?.access_token as string
  );

  const count = (data && Math.ceil(data.count / PER_PAGE)) || 1;

  return (
    <Stack justifyContent="space-evenly" alignItems="center" sx={sx}>
      <Stack
        direction={smBreakpoint ? "column" : "row"}
        justifyContent="space-between"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Autocomplete
          size="small"
          options={options}
          sx={{
            width: 250,
            [theme.breakpoints.down("sm")]: { width: "100%" },
          }}
          value={options.find((option) => option.value === resource.value)}
          getOptionLabel={(value) => value.label}
          disableClearable
          onChange={(_, { value }) => {
            setResource(
              options.find((option) => option.value === value) as OptionType
            );
          }}
          renderInput={(params) => <TextField {...params} label="Recurso" />}
        />
        <TextField
          size="small"
          placeholder="pesquisar pelo nome..."
          sx={{
            width: 250,
            [theme.breakpoints.down("sm")]: { width: "100%" },
          }}
          onChange={(e) => setTerm(e.target.value)}
        />
      </Stack>
      <List cols={cols} rowHeight={100}>
        {!isLoading && data ? (
          data.images.map((image) => (
            <ImageListItem key={image.id}>
              <ListItemButton
                onClick={() => setCurrentImage(image)}
                selected={image.id === currentImage?.id}
                sx={{
                  padding: "3px",
                  "&.Mui-selected": { backgroundColor: "#f44336" },
                  backgroundColor: "#f0f0f0",
                }}
              >
                <LazyLoadImage
                  src={image.url}
                  alt={image.alt}
                  width="100%"
                  height={100}
                  effect="blur"
                  style={{ objectFit: "cover" }}
                />
              </ListItemButton>
            </ImageListItem>
          ))
        ) : (
          <>
            {Array.from({ length: PER_PAGE }).map((_, index) => (
              <ImageListItem key={index}>
                <Skeleton variant="rectangular" height={100} animation="wave" />
              </ImageListItem>
            ))}
          </>
        )}
      </List>
      <Pagination count={count} page={page} onChange={handlePageChange} />
    </Stack>
  );
}

export default ImageList;
