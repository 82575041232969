import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { updateVideo } from "../../../services/videoService";
import { EditVideo, Video } from "../../../types/Video";

interface EditVideoFormProps {
  data: Video;
  fetch: () => void;
  onFinished: () => void;
}

function EditVideoForm({ data, fetch, onFinished }: EditVideoFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    title: yup.string().required("Título não pode ser vazio"),
    url: yup
      .string()
      .trim()
      .matches(
        /^(https?:\/\/)?(www\.)?youtube\.com\/shorts\/.+$/,
        "Formato de URL incorreto"
      )
      .required("Url não pode ser vazia"),
    thumbnailUrl: yup.string().notRequired(),
    author: yup.string().required("Autor não pode ser vazio"),
    source: yup.string().required("Fonte não pode ser vazia"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditVideo>({
    resolver: yupResolver(schema),
  });

  async function submit(editVideo: EditVideo) {
    try {
      await updateVideo(data.id, editVideo);

      fetch();

      enqueueSnackbar("Video atualizado com sucesso!", {
        variant: "success",
      });

      onFinished();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack spacing={2}>
        <Typography variant="h6">Editando vídeo</Typography>
        <FormControl fullWidth size="small">
          <InputLabel id="select-souce-label">Fonte</InputLabel>
          <Controller
            name="source"
            control={control}
            defaultValue={data.source}
            render={({ field: { onChange, value } }) => (
              <Select
                labelId="select-souce-label"
                value={value}
                label="Fonte"
                onChange={onChange}
              >
                <MenuItem value="SHORTS">SHORTS</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <Controller
          name="title"
          control={control}
          defaultValue={data.title}
          render={({ field: { onChange, value } }) => (
            <TextField
              size="small"
              label="Título"
              onChange={onChange}
              value={value}
              error={Boolean(errors?.title)}
              helperText={errors?.title?.message}
            />
          )}
        />
        <Stack direction="row">
          <Controller
            name="url"
            control={control}
            defaultValue={data.url}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Url"
                onChange={onChange}
                value={value}
                error={Boolean(errors?.url)}
                helperText={errors?.url?.message}
              />
            )}
          />
          <Tooltip
            title={
              <>
                <Typography>Exemplo:</Typography>
                <em>
                  https://www.youtube.com/shorts/video_id
                  <br />
                </em>
                <em>
                  https://youtube.com/shorts/video_id
                  <br />
                </em>
              </>
            }
            placement="right-start"
            sx={{ alignSelf: "flex-start" }}
          >
            <IconButton>
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Controller
          name="thumbnailUrl"
          control={control}
          defaultValue={data.thumbnailUrl ?? ""}
          render={({ field: { onChange, value } }) => (
            <TextField
              size="small"
              label="Url da thumbnail (Opcional)"
              onChange={onChange}
              value={value}
              error={Boolean(errors?.thumbnailUrl)}
              helperText={errors?.thumbnailUrl?.message}
            />
          )}
        />
        <Controller
          name="author"
          control={control}
          defaultValue={data.author}
          render={({ field: { onChange, value } }) => (
            <TextField
              size="small"
              label="Autor"
              onChange={onChange}
              value={value}
              error={Boolean(errors?.author)}
              helperText={errors?.author?.message}
            />
          )}
        />
        <Button type="submit" variant="contained">
          Atualizar
        </Button>
      </Stack>
    </form>
  );
}

export default EditVideoForm;
