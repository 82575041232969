/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import Stack from "@mui/material/Stack";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface FilesDropzoneProps {
  setFiles: Dispatch<SetStateAction<File[]>>;
  multiple?: boolean;
  maxFiles?: number;
  disabled?: boolean;
}

function FilesDropzone({
  setFiles,
  multiple = false,
  maxFiles = 1,
  disabled,
}: FilesDropzoneProps) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    maxFiles,
    disabled,
  });

  return (
    <Stack
      {...getRootProps()}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        height: "200px",
        border: "5px dashed gray",
        textAlign: "center",
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <div>
          {multiple ? (
            <p>Solte os arquivos aqui...</p>
          ) : (
            <p>Solte o arquivo aqui...</p>
          )}
        </div>
      ) : (
        <div>
          {multiple ? (
            <p>
              Arraste e solte os arquivos aqui, ou clique para selecioná-los
            </p>
          ) : (
            <p>Arraste e solte o arquivo aqui, ou clique para selecioná-lo</p>
          )}
        </div>
      )}
    </Stack>
  );
}

export default FilesDropzone;
