/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from "axios";

import AuthService from "../authService";
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
} from "../tokenService";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_CMS_API_HOST}`,
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const refresh_token = getLocalRefreshToken();
          const newToken = await AuthService.refreshToken(refresh_token);
          updateLocalAccessToken(newToken);
          axios.defaults.headers.common.Authorization = `Bearer ${newToken.access_token}`;
          return instance(originalConfig);
        } catch (_err) {
          AuthService.signOut();
          window.location.href = "/signin";
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
