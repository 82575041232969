import { Backdrop, Box, CircularProgress } from "@mui/material";
import React from "react";

import PagesTable from "../components/Tables/PagesTable";
import PopupTable from "../components/Tables/PopupTable";
import { useFetch } from "../hooks/useFetch";
import { Page } from "../types/Page";
import { Popup } from "../types/Popup";

function Pages() {
  const { data: pages, mutate: fetchPages } = useFetch<Page[]>("/page");
  const { data: popups, mutate: fetchPopups } = useFetch<Popup[]>("/popup");

  return (
    <Box width="100%" height="800px">
      {popups && <PopupTable popups={popups} fetch={fetchPopups} />}
      {pages && <PagesTable pages={pages} fetch={fetchPages} />}
      <Backdrop
        sx={{ color: "red", backgroundColor: "transparent" }}
        open={!pages && !popups}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default Pages;
