import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React from "react";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import { User } from "../../../types/User";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";

interface RowProps {
  user: User;
  selectedUserId: number | null;
  setSelectedUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

function Row({ user, selectedUserId, setSelectedUserId }: RowProps) {
  function onChange(value: number) {
    if (value === selectedUserId) {
      setSelectedUserId(null);
    } else {
      setSelectedUserId(value);
    }
  }

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={user.id === selectedUserId}
          onChange={() => onChange(user.id)}
        />
      </TableCell>
      <TableCell align="left">{user.full_name}</TableCell>
      <TableCell align="right">{user.email}</TableCell>
      <TableCell align="right">
        {formatFromIsoToDateTime(user.createdAt as string)}
      </TableCell>
    </TableRow>
  );
}

interface AttachUserTableProps {
  selectedUserId: number | null;
  setSelectedUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function changePosition(arr: any[], from: number, to: number) {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
}

function AttachUserTable({
  selectedUserId,
  setSelectedUserId,
}: AttachUserTableProps) {
  const { user } = React.useContext(AuthContext);

  const { data } = useFetchWithToken<User[]>(
    "/user",
    user?.access_token as string
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [filter, setFilter] = React.useState("");

  const [users, setUsers] = React.useState<User[]>([]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      let arr = data;
      arr.forEach((el, index) => {
        if (selectedUserId === el.id) {
          arr = changePosition(arr, index, 0);
        }
      });

      setUsers(arr);
    }
  }, [data]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <TextField
        type="text"
        placeholder="buscar pelo nome..."
        size="small"
        sx={{ alignSelf: "flex-end", maxWidth: "250px" }}
        onChange={(e) => setFilter(e.target.value.toLowerCase())}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Nome</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Criado em</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users
                .filter((user) =>
                  user.full_name?.toLowerCase().includes(filter)
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <Row
                    key={user.id}
                    user={user}
                    selectedUserId={selectedUserId}
                    setSelectedUserId={setSelectedUserId}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {users && (
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Stack>
  );
}

export default AttachUserTable;
