import { Post } from "../types/Post";
import { EditPost } from "../types/Post/EditPost";
import { NewPost } from "../types/Post/NewPost";
import instance from "./api";

export async function createPost(post: NewPost) {
  const { data } = await instance.post("/post", post);

  return data;
}

export async function getPosts(): Promise<Post[]> {
  const { data } = await instance.get("/post");
  return data;
}

export async function getPostsByAuthor(authorId: number): Promise<Post[]> {
  const { data } = await instance.get(`/post/findByAuthor/${authorId}`);
  return data;
}

export async function updatePost(postId: number, post: EditPost) {
  const { data } = await instance.patch(`/post/${postId}`, post);

  return data;
}

export async function deletePost(postId: number) {
  const { data } = await instance.delete(`/post/${postId}`);

  return data;
}

export async function deleteManyPosts(postIds: number[]) {
  const { data } = await instance.delete("/post", { data: { postIds } });

  return data;
}
