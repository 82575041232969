import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { SubmitHandler } from "react-hook-form";

import { createUser } from "../../../services/userService";
import { NewCommentUser } from "../../../types/User";
import NewCommentUserForm from "../../Forms/Users/NewCommentUser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
  "@media(max-width: 600px)": {
    width: "90%",
  },
};

interface NewUserModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => void;
}

function NewUserModal({ open, setOpen, fetch }: NewUserModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<NewCommentUser> = async (data) => {
    try {
      await createUser(data);
      fetch();

      enqueueSnackbar("Usuário criado com sucesso", { variant: "success" });
      setOpen(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid container direction="column">
        <Grid item>
          <Typography>Novo usuário</Typography>
        </Grid>
        <Grid item>
          <Box sx={style}>
            <NewCommentUserForm onSubmit={onSubmit} />
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default NewUserModal;
