/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import AuthContext from "../contexts/auth";
import { UserRoles } from "../types/Roles";

interface AuthorizedRouteProps {
  routeName:
    | "dashboard"
    | "posts"
    | "categories"
    | "gallery"
    | "comments"
    | "users"
    | "pages"
    | "blog"
    | "videos";
}

export const permittedRoutesByRoles: Record<
  AuthorizedRouteProps["routeName"],
  UserRoles[]
> = {
  dashboard: ["Admin", "Editor"],
  posts: ["Admin", "Editor"],
  categories: ["Admin"],
  gallery: ["Admin"],
  comments: ["Admin"],
  users: ["Admin"],
  pages: ["Admin"],
  blog: ["Admin", "Editor"],
  videos: ["Admin"],
};

function AuthorizedRoute({ routeName }: AuthorizedRouteProps) {
  const { user } = React.useContext(AuthContext);

  return user && permittedRoutesByRoles[routeName].includes(user.user.role) ? (
    <Outlet />
  ) : (
    <Navigate to="nao-autorizado" />
  );
}

export default AuthorizedRoute;
