import { NewSubcategory, EditSubcategory } from "../types/Subcategory";
import instance from "./api";

export async function createSubcategory(newSubcategory: NewSubcategory) {
  const { data } = await instance.post("subcategory", newSubcategory);

  return data;
}

export async function updateSubcategory(
  subcategoryId: number,
  editedSubcategory: EditSubcategory
) {
  const { data } = await instance.patch(
    `subcategory/${subcategoryId}`,
    editedSubcategory
  );

  return data;
}
