import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function BlogPosts() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/blog") {
      navigate("/blog/posts");
    }
  }, [location]);

  return <Outlet />;
}

export default BlogPosts;
