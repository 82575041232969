import { UserLogin, UserLogged } from "../types/Auth";
import instance from "./api/index";

async function signIn({ email, password }: UserLogin): Promise<UserLogged> {
  const { data } = await instance.post("/login", {
    email,
    password,
  });

  const user: UserLogged = data;

  if (user.access_token) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  return user;
}

function signOut() {
  localStorage.removeItem("user");
}

async function refreshToken(refresh_token: string) {
  const { data } = await instance.post("/refresh-token", {
    refresh_token,
  });

  const user: UserLogged = data;

  return user;
}

const AuthService = {
  signIn,
  signOut,
  refreshToken,
};

export default AuthService;
