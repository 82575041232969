import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { createCategory } from "../../../services/categoriesService";
import { NewCategory } from "../../../types/Category";
import { Image } from "../../../types/Image";
import MediaModal from "../MediaModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
  "@media(max-width: 600px)": {
    width: "90%",
  },
};

const schema = yup.object().shape({
  name: yup.string().required("Nome não pode ser vazio!"),
  imageId: yup.number().required("Imagem não pode ser vazia!"),
});

interface NewCategoryModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => void;
}

function NewCategoryModal({ open, setOpen, fetch }: NewCategoryModalProps) {
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm<NewCategory>({ resolver: yupResolver(schema) });

  const { enqueueSnackbar } = useSnackbar();

  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  useEffect(() => {
    if (selectedImage) {
      setValue("imageId", selectedImage.id);
    }
  }, [selectedImage]);

  async function submit(data: NewCategory) {
    try {
      await createCategory(data);

      enqueueSnackbar("Categoria adicionada com sucesso!", {
        variant: "success",
      });

      fetch();
      reset();
      setOpen(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  return (
    <>
      <MediaModal
        open={mediaModalOpen}
        setOpen={setMediaModalOpen}
        setSelectedImage={setSelectedImage}
        resource="CATEGORY"
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <form onSubmit={handleSubmit(submit)}>
            <Stack spacing={2}>
              <Typography variant="h6">Nova categoria</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextField
                    type="text"
                    label="Nome da categoria"
                    onChange={onChange}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                  />
                )}
              />
              <Stack>
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "#f0f0f0",
                    color: "black",
                    ":hover": {
                      backgroundColor: "#D3D3D3",
                    },
                  }}
                  onClick={() => setMediaModalOpen(true)}
                >
                  {!selectedImage ? (
                    <Typography align="center" fontWeight={500}>
                      Definir imagem (categoria)
                    </Typography>
                  ) : (
                    <img
                      src={selectedImage.url}
                      alt={selectedImage.alt}
                      style={{ width: "200px", height: "100%" }}
                    />
                  )}
                </Button>
                <FormHelperText
                  error={Boolean(errors?.imageId)}
                  sx={{ textAlign: "center" }}
                >
                  {errors?.imageId?.message}
                </FormHelperText>
              </Stack>
              <Button variant="contained" type="submit">
                Criar categoria
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default NewCategoryModal;
