import Box from "@mui/material/Box";

import CategoriesTable from "../components/Tables/CategoriesTable";

function Categories() {
  return (
    <Box sx={{ width: "100%" }}>
      <CategoriesTable />
    </Box>
  );
}

export default Categories;
