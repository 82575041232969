/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import { CheckBox } from "@mui/icons-material";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  TextField,
  TablePagination,
  Skeleton,
} from "@mui/material";
import React from "react";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import { Post } from "../../../types/Post";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";

interface RowProps {
  post: Post;
  selectedPosts: Array<number>;
  setSelectedPosts: React.Dispatch<React.SetStateAction<number[]>>;
  singleSelection?: boolean;
}

function Row({
  post,
  selectedPosts,
  setSelectedPosts,
  singleSelection,
}: RowProps) {
  function handleTogglePosts(postId: number) {
    const currentIndex = selectedPosts.indexOf(postId);
    const newChecked = [...selectedPosts];

    if (currentIndex === -1) {
      if (newChecked.length > 0 && singleSelection === true) {
        newChecked.pop();
        newChecked.push(postId);
      } else {
        newChecked.push(postId);
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedPosts(newChecked);
  }

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedPosts.indexOf(post.id) !== -1}
          onChange={() => handleTogglePosts(post.id)}
        />
      </TableCell>
      <TableCell align="left">{post.title}</TableCell>
      <TableCell align="left">{post.slug}</TableCell>
      <TableCell align="right">{post.published ? "Sim" : "Não"}</TableCell>
      <TableCell align="right">
        {formatFromIsoToDateTime(post.createdAt as string)}
      </TableCell>
    </TableRow>
  );
}

interface AttachPostsTableProps {
  selectedPosts: number[];
  setSelectedPosts: React.Dispatch<React.SetStateAction<number[]>>;
  singleSelection?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function changePosition(arr: any[], from: number, to: number) {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
}

function AttachPostsTable({
  selectedPosts,
  setSelectedPosts,
  singleSelection = false,
}: AttachPostsTableProps) {
  const { user } = React.useContext(AuthContext);

  const { data, isLoading } = useFetchWithToken<{
    count: number;
    posts: Post[];
  }>("/post/accessibleByUser?published=true", user?.access_token as string);

  const [filter, setFilter] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [posts, setPosts] = React.useState<Post[]>([]);

  React.useEffect(() => {
    if (data && data.posts.length > 0) {
      let arr = data.posts;
      arr.forEach((el, index) => {
        if (selectedPosts.includes(el.id)) {
          arr = changePosition(arr, index, 0);
        }
      });

      setPosts(arr);
    }
  }, [data]);

  return (
    <Stack height="100%" spacing={2}>
      <TextField
        type="text"
        placeholder="buscar pelo título..."
        size="small"
        sx={{ alignSelf: "flex-end", maxWidth: "250px" }}
        onChange={(e) => setFilter(e.target.value.toLowerCase())}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">Título</TableCell>
              <TableCell align="left">Slug</TableCell>
              <TableCell align="right">Publicado</TableCell>
              <TableCell align="right">Postado em</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && posts ? (
              posts
                .filter((post) => post.title.toLowerCase().includes(filter))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((post) => (
                  <Row
                    key={post.id}
                    post={post}
                    selectedPosts={selectedPosts}
                    setSelectedPosts={setSelectedPosts}
                    singleSelection={singleSelection}
                  />
                ))
            ) : (
              <>
                {Array.from({ length: 8 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <CheckBox>
                        <Skeleton />
                      </CheckBox>
                    </TableCell>
                    <TableCell align="left">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="left">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {posts && (
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={posts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Stack>
  );
}

export default AttachPostsTable;
