/* eslint-disable @typescript-eslint/no-explicit-any */

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";

import { deletePopup } from "../../../services/popupService";
import { Popup } from "../../../types/Popup";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";
import EditPopupModal from "../../Modals/EditPopupModal";
import NewPopupModal from "../../Modals/NewPopupModal";

interface RowProps {
  popup: Popup;
  fetch: () => void;
  setEditPopupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditPopup: React.Dispatch<React.SetStateAction<Popup | null>>;
}

function Row({ popup, fetch, setEditPopupModalOpen, setEditPopup }: RowProps) {
  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = () => {
    setEditPopup(popup);
    setEditPopupModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deletePopup(popup.id);
      fetch();
      enqueueSnackbar("Popup deletado com sucesso!", {
        variant: "success",
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  return (
    <TableRow>
      <TableCell padding="checkbox" />
      <TableCell>{popup.href}</TableCell>
      <TableCell>{popup.visible ? "Sim" : "Não"}</TableCell>
      <TableCell>
        {popup.target === "_self" ? "Mesma página" : "Nova página"}
      </TableCell>
      <TableCell>
        {formatFromIsoToDateTime(popup.createdAt as string)}
      </TableCell>
      <TableCell>
        {formatFromIsoToDateTime(popup.updatedAt as string)}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface PopupTableProps {
  popups: Array<Popup>;
  fetch: () => void;
}

function PopupTable({ popups, fetch }: PopupTableProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [newPopupModalOpen, setNewPopupModalOpen] = React.useState(false);

  const [editPopupModalOpen, setEditPopupModalOpen] = React.useState(false);
  const [editPopup, setEditPopup] = React.useState<Popup | null>(null);

  return (
    <>
      {newPopupModalOpen && (
        <NewPopupModal
          fetch={fetch}
          open={newPopupModalOpen}
          setOpen={setNewPopupModalOpen}
          selectedPages={popups.flatMap((popup) => popup.pages)}
        />
      )}
      {editPopupModalOpen && editPopup && (
        <EditPopupModal
          fetch={fetch}
          open={editPopupModalOpen}
          setOpen={setEditPopupModalOpen}
          popup={editPopup}
          selectedPages={popups.flatMap((popup) => popup.pages)}
        />
      )}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Box component={Paper} sx={{ width: "100%" }}>
          <Typography ml={2} mr={2} mt={2} variant="h6">
            Popup
          </Typography>
          <TableContainer>
            <Table sx={{ minWidth: "320px" }}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell>Link</TableCell>
                  <TableCell>Visível</TableCell>
                  <TableCell>Abrir</TableCell>
                  <TableCell>Criado em</TableCell>
                  <TableCell>Atualizado em</TableCell>
                  <TableCell align="right">
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => setNewPopupModalOpen(true)}
                    >
                      Novo
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popups?.map((popup) => (
                  <Row
                    key={popup.id}
                    popup={popup}
                    fetch={fetch}
                    setEditPopupModalOpen={setEditPopupModalOpen}
                    setEditPopup={setEditPopup}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={popups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </>
  );
}

export default PopupTable;
