import Box from "@mui/material/Box";

import VideosTable from "../components/Tables/VideosTable";

function Videos() {
  return (
    <Box sx={{ width: "100%" }}>
      <VideosTable />
    </Box>
  );
}

export default Videos;
