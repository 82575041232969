import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { useFetch } from "../../hooks/useFetch";
import { User } from "../../types/User";

type ContextType = { users: User[]; fetch: () => Promise<void> };

function Users() {
  const { data: users, isLoading, mutate } = useFetch<User[]>("/user");

  return (
    <>
      {!isLoading && <Outlet context={{ users, fetch: () => mutate() }} />}
      <Backdrop
        sx={{ color: "red", backgroundColor: "transparent" }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Users;

export function useUsers() {
  return useOutletContext<ContextType>();
}
