/* eslint-disable @typescript-eslint/no-explicit-any */
import useSWR from "swr";

import instance from "../services/api";

export function useFetch<Data = any, Error = any>(url: string) {
  const { data, mutate, error, isLoading } = useSWR<Data, Error>(
    url,
    async (url) => {
      const response = await instance.get(url);

      return response.data;
    }
  );

  return { data, mutate, error, isLoading };
}

export function useFetchWithToken<Data = any, Error = any>(
  url: string,
  token: string
) {
  const { data, mutate, error, isLoading } = useSWR<Data, Error>(
    [url, token],
    async ([url, token]: [url: string, token: string]) => {
      const response = await instance.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    }
  );

  return { data, mutate, error, isLoading };
}
