import { NewBlogCategory, EditBlogCategory } from "../types/BlogCategory";
import instance from "./api";

export async function createBlogCategory(data: NewBlogCategory) {
  const { data: result } = await instance.post("blog-category", data);

  return result;
}

export async function updateBlogCategory(
  blogCategoryId: number,
  data: EditBlogCategory
) {
  const { data: result } = await instance.patch(
    `/blog-category/${blogCategoryId}`,
    data
  );

  return result;
}

export async function deleteBlogCategory(blogCategoryId: number) {
  const { data } = await instance.delete(`/blog-category/${blogCategoryId}`);

  return data;
}
