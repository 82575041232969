import { EditUser, NewUser, NewCommentUser } from "../types/User";
import instance from "./api";

export async function getUsers() {
  const { data } = await instance.get("/user");

  return data;
}

export async function createUser(userData: NewUser | NewCommentUser) {
  const { data } = await instance.post("/user", userData);

  return data;
}

export async function updateUser(userId: number, userData: EditUser) {
  const { data } = await instance.patch(`/user/${userId}`, userData);

  return data;
}

export async function deleteUser(userId: number) {
  const { data } = await instance.delete(`/user/${userId}`);

  return data;
}

export async function deleteManyUsers(usersIds: number[]) {
  const { data } = await instance.delete("/user", {
    data: { usersIds },
  });

  return data;
}
