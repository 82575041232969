/* eslint-disable no-script-url */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import vovo from "../assets/vovo.webp";
import AuthContext from "../contexts/auth";
import { UserLogin } from "../types/Auth";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Receita de Vovó
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const defaultValues: UserLogin = {
  email: "",
  password: "",
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Endereço de e-mail não é válido")
    .required("O campo e-mail não pode ser vazio"),
  password: yup.string().required("O campo senha não pode ser vazio"),
});

export default function SignIn() {
  const { signIn, signed } = React.useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (signed !== true) {
      navigate("/signin");
    } else {
      navigate("/");
    }
  }, [signed]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserLogin>({ defaultValues, resolver: yupResolver(schema) });

  const [showPassword, setShowPassword] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleToggleBackdrop = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const handleClickShowPassword = () => {
    setShowPassword((state) => !state);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = async (data: UserLogin) => {
    try {
      handleToggleBackdrop();
      await signIn(data);
      handleCloseBackdrop();
      navigate("/");

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      handleCloseBackdrop();
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LazyLoadImage src={vovo} width={160} height={160} effect="blur" />
        <Box component="form" sx={{ mt: 1 }}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label="Endereço de e-mail"
                autoComplete="email"
                autoFocus
                error={Boolean(errors?.email)}
                helperText={errors?.email?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label="Senha"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors?.password)}
                helperText={errors?.password?.message}
              />
            )}
          />
          <Box>
            <Button
              type="submit"
              fullWidth
              disabled={openBackdrop}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit(onSubmit)}
            >
              Entrar
            </Button>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
