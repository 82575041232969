import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "../components/_layout";
import BlogPostsTable from "../components/Tables/BlogPostsTable";
import GalleryTable from "../components/Tables/GalleryTable";
import PostsTable from "../components/Tables/PostsTable";
import UsersTable from "../components/Tables/UsersTable";
import { AuthProvider } from "../contexts/auth";
import BlogCategories from "../pages/BlogCategories";
import BlogPosts from "../pages/BlogPosts";
import EditBlogPost from "../pages/BlogPosts/Edit";
import NewBlogPost from "../pages/BlogPosts/New";
import Categories from "../pages/Categories";
import Comments from "../pages/Comments";
import ApprovedComments from "../pages/Comments/Approved";
import PendingsComments from "../pages/Comments/Pendings";
import Dashboard from "../pages/Dashboard";
import Gallery from "../pages/Gallery";
import EditImage from "../pages/Gallery/Edit";
import NewImage from "../pages/Gallery/New";
import NotFound from "../pages/NotFound";
import Pages from "../pages/Pages";
import Posts from "../pages/Posts";
import EditPosts from "../pages/Posts/Edit";
import NewPost from "../pages/Posts/New";
import SignIn from "../pages/SignIn";
import Unauthorized from "../pages/Unauthorized";
import Users from "../pages/Users";
import EditUser from "../pages/Users/Edit";
import NewUser from "../pages/Users/New";
import Videos from "../pages/Videos";
import AuthorizedRoute from "./AuthorizedRoute";
import PrivateRoute from "./PrivateRoute";

function Router() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/signin" element={<SignIn />} />

          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              <Route element={<AuthorizedRoute routeName="dashboard" />}>
                <Route path="/" element={<Dashboard />} />
              </Route>

              <Route element={<AuthorizedRoute routeName="posts" />}>
                <Route path="/posts" element={<Posts />}>
                  <Route index element={<PostsTable />} />
                  <Route path="novo" element={<NewPost />} />
                  <Route path=":postId/edit" element={<EditPosts />} />
                </Route>
              </Route>

              <Route element={<AuthorizedRoute routeName="categories" />}>
                <Route path="/categorias" element={<Categories />} />
              </Route>

              <Route element={<AuthorizedRoute routeName="gallery" />}>
                <Route path="/galeria" element={<Gallery />}>
                  <Route path="" element={<GalleryTable />} />
                  <Route path=":imageId" element={<EditImage />} />
                  <Route path="novo" element={<NewImage />} />
                </Route>
              </Route>

              <Route element={<AuthorizedRoute routeName="comments" />}>
                <Route path="/comentarios" element={<Comments />}>
                  <Route index element={<ApprovedComments />} />

                  <Route path="pendings" element={<Comments />}>
                    <Route index element={<PendingsComments />} />
                  </Route>
                </Route>
              </Route>

              <Route element={<AuthorizedRoute routeName="users" />}>
                <Route path="/usuarios" element={<Users />}>
                  <Route path="" element={<UsersTable />} />
                  <Route path=":userId" element={<EditUser />} />
                  <Route path="novo" element={<NewUser />} />
                </Route>
              </Route>

              <Route element={<AuthorizedRoute routeName="pages" />}>
                <Route path="/pages" element={<Pages />} />
              </Route>

              <Route element={<AuthorizedRoute routeName="blog" />}>
                <Route path="/blog" element={<BlogPosts />}>
                  <Route path="posts">
                    <Route index element={<BlogPostsTable />} />
                    <Route path="novo" element={<NewBlogPost />} />
                    <Route path=":postId/edit" element={<EditBlogPost />} />
                  </Route>

                  <Route path="categorias" element={<BlogCategories />} />
                </Route>
              </Route>

              <Route element={<AuthorizedRoute routeName="videos" />}>
                <Route path="videos" element={<Videos />} />
              </Route>

              <Route path="*" element={<NotFound />} />
              <Route path="nao-autorizado" element={<Unauthorized />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default Router;
