import { EditCategory, NewCategory } from "../types/Category";
import instance from "./api";

export async function getCategories() {
  const { data } = await instance.get("category/cms");
  return data;
}

export async function createCategory(newCategory: NewCategory) {
  const { data } = await instance.post("category", newCategory);
  return data;
}

export async function deleteCategory(postId: number) {
  const { data } = await instance.delete(`/category/${postId}`);

  return data;
}

export async function deleteManyCategories(categoriesIds: number[]) {
  const { data } = await instance.delete("/category", {
    data: { categoriesIds },
  });

  return data;
}

export async function updateCategory(
  categoryId: number,
  editCategory: EditCategory
) {
  const { data } = await instance.patch(
    `/category/${categoryId}`,
    editCategory
  );

  return data;
}
