/* eslint-disable @typescript-eslint/no-explicit-any */

import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Badge,
  Button,
  Paper,
  Stack,
  TableContainer,
  TablePagination,
} from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import React from "react";

import { clearPathCache } from "../../../services/cacheService";
import { Page } from "../../../types/Page";
import { Section } from "../../../types/Section";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";
import SectionEditionModal from "../../Modals/SectionEditionModal";

interface RowProps {
  page: Page;
  setAssociatedPage: React.Dispatch<React.SetStateAction<Page | null>>;
  setSectionEditionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditedSection: React.Dispatch<React.SetStateAction<Section | null>>;
}

function Row({
  page,
  setAssociatedPage,
  setSectionEditionModalOpen,
  setEditedSection,
}: RowProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);

  function handleSectionEdit(section: Section) {
    setEditedSection(section);
    setAssociatedPage(page);
    setSectionEditionModalOpen(true);
  }

  async function handleClearPathCache(path: string) {
    try {
      await clearPathCache(path);
      enqueueSnackbar("Cache limpo", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Erro ao limpar o cache", { variant: "error" });
    }
  }

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{page.name}</TableCell>
        <TableCell>{page.path}</TableCell>
        <TableCell>
          {formatFromIsoToDateTime(page.createdAt as string)}
        </TableCell>
        <TableCell>
          {formatFromIsoToDateTime(page.updatedAt as string)}
        </TableCell>
        <TableCell align="right">
          <IconButton
            title="Limpar cache"
            onClick={() => handleClearPathCache(page.path)}
          >
            <CleaningServicesIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Seções
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Posts</TableCell>
                    <TableCell align="right">Criada em</TableCell>
                    <TableCell align="right">Atualizada em</TableCell>
                    <TableCell align="right">Ordem</TableCell>
                    <TableCell align="right">Visível</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {page.sections &&
                    page.sections.map((section) => (
                      <TableRow key={section.id}>
                        <TableCell>{section.name}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            onClick={() => handleSectionEdit(section)}
                          >
                            <Badge
                              badgeContent={section.posts.length}
                              color="secondary"
                            >
                              Editar
                            </Badge>
                          </Button>
                        </TableCell>
                        <TableCell align="right">
                          {formatFromIsoToDateTime(section.createdAt as string)}
                        </TableCell>
                        <TableCell align="right">
                          {formatFromIsoToDateTime(section.updatedAt as string)}
                        </TableCell>
                        <TableCell align="right">{section.order}</TableCell>
                        <TableCell align="right">
                          {section.visible ? "Sim" : "Não"}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="Editar Seção"
                            title="Editar Seção"
                            onClick={() => handleSectionEdit(section)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface PagesTableProps {
  pages: Array<Page>;
  fetch: () => void;
}

function PagesTable({ pages, fetch }: PagesTableProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [associatedPage, setAssociatedPage] = React.useState<Page | null>(null);

  const [editedSection, setEditedSection] = React.useState<Section | null>(
    null
  );
  const [sectionEditionModal, setSectionEditionModalOpen] =
    React.useState(false);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {editedSection && associatedPage && (
        <SectionEditionModal
          fetch={fetch}
          open={sectionEditionModal}
          setOpen={setSectionEditionModalOpen}
          section={editedSection}
          page={associatedPage}
        />
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: "320px" }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>Nome</TableCell>
              <TableCell>Caminho</TableCell>
              <TableCell>Criada em</TableCell>
              <TableCell>Atualizada em</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {pages?.map((page) => (
              <Row
                key={page.id}
                page={page}
                setAssociatedPage={setAssociatedPage}
                setSectionEditionModalOpen={setSectionEditionModalOpen}
                setEditedSection={setEditedSection}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={pages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
}

export default PagesTable;
