/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldError,
} from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";

import Editor from "../../components/Editor";
import SubcategoriesList from "../../components/Lists/SubcategoriesList";
import MediaModal from "../../components/Modals/MediaModal";
import AuthContext from "../../contexts/auth";
import { useFetchWithToken } from "../../hooks/useFetch";
import { updatePost } from "../../services/postsService";
import { Image } from "../../types/Image";
import { EditPost as EditPostType } from "../../types/Post/EditPost";
import { NestedPost } from "../../types/Post/NestedPost";
import clearEditorText from "../../utils/clearEditorText";

const editorInit = {
  menubar: false,
  plugins: ["lists", "link", "media", "code", "wordcount"],
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
    "p { margin: 0; }" +
    "ul { margin: 0; }" +
    "ol { margin: 0; }",
};

const ingredientsToolbar = "undo redo bold italic bullist link clearText code";

const preparationMethodToolbar =
  "undo redo bold italic numlist link clearText code";

const additionalInformationToolbar =
  "undo redo bold italic bullist numlist link clearText code";

const introductionToolbar = "undo redo bold italic link clearText code";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("O campo Título não pode ser vazio")
    .max(65, "Máximo de 65 caracteres"),
  slug: yup.string().required("O campo Slug não pode ser vazio"),
  portions: yup
    .number()
    .nullable()
    .when("published", {
      is: (value: boolean) => value === true,
      then: yup
        .number()
        .typeError("Preencha as porções")
        .moreThan(0, "O valor deve ser maior que zero"),
      otherwise: yup.number().nullable(),
    })
    .when("schedule", {
      is: (value: unknown) => value !== null,
      then: yup
        .number()
        .typeError("Preencha as porções")
        .moreThan(0, "O valor deve ser maior que zero"),
      otherwise: yup.number().nullable(),
    }),
  preparationTime: yup
    .number()
    .nullable()
    .when("published", {
      is: (value: boolean) => value === true,
      then: yup
        .number()
        .typeError("Preencha o tempo de preparo")
        .moreThan(0, "O valor deve ser maior que zero"),
      otherwise: yup.number().nullable(),
    })
    .when("schedule", {
      is: (value: unknown) => value !== null,
      then: yup
        .number()
        .typeError("Preencha o tempo de preparo")
        .moreThan(0, "O valor deve ser maior que zero"),
      otherwise: yup.number().nullable(),
    }),
  ingredients: yup
    .string()
    .notRequired()
    .nullable()
    .when("published", {
      is: (value: boolean) => value === true,
      then: yup
        .string()
        .transform((string) => string.replace(/\n/g, ""))
        .matches(/^(?!<ul><li>&nbsp;<\/li><\/ul>$|^$).*$/gim, {
          message: "Ingredientes não pode ser vazio",
        }),
      otherwise: yup.string().notRequired().nullable(),
    })
    .when("schedule", {
      is: (value: unknown) => value !== null,
      then: yup
        .string()
        .transform((string) => string.replace(/\n/g, ""))
        .matches(/^(?!<ul><li>&nbsp;<\/li><\/ul>$|^$).*$/gim, {
          message: "Ingredientes não pode ser vazio",
        }),
      otherwise: yup.string().notRequired().nullable(),
    }),
  preparationMethod: yup
    .string()
    .notRequired()
    .nullable()
    .when("published", {
      is: (value: boolean) => value === true,
      then: yup
        .string()
        .transform((string) => string.replace(/\n/g, ""))
        .matches(/^(?!<ol><li>&nbsp;<\/li><\/ol>$|^$).*$/gim, {
          message: "Modo de preparo não pode ser vazio",
        }),
      otherwise: yup.string().notRequired().nullable(),
    })
    .when("schedule", {
      is: (value: unknown) => value !== null,
      then: yup
        .string()
        .transform((string) => string.replace(/\n/g, ""))
        .matches(/^(?!<ol><li>&nbsp;<\/li><\/ol>$|^$).*$/gim, {
          message: "Modo de preparo não pode ser vazio",
        }),
      otherwise: yup.string().notRequired().nullable(),
    }),
  additionalInformation: yup.string().nullable(),
  published: yup.boolean(),
  imageId: yup.number().nullable(),
  subcategories: yup
    .array()
    .of(yup.object().shape({ id: yup.number() }))
    .test(
      "has-at-least-one-object",
      "Deve conter pelo menos um objeto",
      (value, ctx) => {
        const {
          published,
          schedule,
        }: { published: boolean; schedule: unknown } = ctx.parent;

        if (published === true || schedule !== null) {
          if (value && value.length > 0) {
            return true;
          }

          return ctx.createError({
            path: ctx.path,
            message: "Deve conter pelo ao menos uma subcategoria",
          });
        }
        return true;
      }
    ),
  postMeta: yup.object().shape({
    metaTitle: yup.string().nullable().max(65, "Máximo de 65 caracteres"),
    metaDescription: yup
      .string()
      .nullable()
      .max(160, "Máximo de 160 caracteres"),
    videoUrl: yup.lazy((value: string) => {
      if (value === "") {
        return yup.string().nullable();
      }
      return yup
        .string()
        .nullable()
        .matches(
          /(www\.)youtube.com\/embed\/([\w\\-]+)/,
          "Formato de Url incorreto!"
        );
    }),
    metaIngredients: yup.string().nullable(),
    metaPreparationMethod: yup.string().nullable(),
    introductionTitle: yup.string().nullable(),
    introduction: yup.string().nullable(),
  }),
  schedule: yup
    .object()
    .shape({
      args: yup.object().shape({
        postId: yup.number().required(),
      }),
      schedule: yup
        .string()
        .required()
        .test("is-future-date", "A data não pode ser no passado", (value) => {
          if (!value) {
            return false;
          }

          const selectedDate = new Date(value);
          const currentDate = new Date();

          return selectedDate >= currentDate;
        }),
    })
    .nullable()
    .default(null),
});

function EditPost() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { postId } = useParams();

  const { user } = useContext(AuthContext);

  const { data, isLoading, mutate } = useFetchWithToken<NestedPost>(
    `/post/findById/${postId}`,
    user?.access_token as string
  );

  const [subcategoriesChecked, setSubcategoriesChecked] = useState<number[]>(
    []
  );

  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const [mediaModalOpen, setMediaModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<EditPostType>({
    defaultValues: useMemo(() => ({ authorId: user?.user.id }), [user]),
    resolver: yupResolver(schema),
  });

  const handleOnSubmit: SubmitHandler<EditPostType> = async (data) => {
    try {
      await updatePost(Number(postId), data);
      mutate();

      enqueueSnackbar("Atualizado com sucesso", { variant: "success" });
      navigate(-1);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const [isPublished, setIsPublished] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      if (data.image) {
        setValue("imageId", data.image.id);
        setSelectedImage(data.image);
      }

      if (data.schedule) {
        setValue("schedule", {
          task: "POST_RECIPE",
          args: {
            postId: Number(postId),
          },
          schedule: data.schedule.schedule,
        });
      }

      if (data.subcategories) {
        setSubcategoriesChecked(
          data.subcategories.map((subcategory) => subcategory.id)
        );
        setValue(
          "subcategories",
          data.subcategories.map((subcategory) => ({ id: subcategory.id }))
        );
      }

      setIsPublished(data.published);
    }
  }, [data]);

  useEffect(() => {
    if (isPublished === true) {
      setValue("schedule", null);
    }
  }, [isPublished]);

  useEffect(() => {
    setValue("imageId", selectedImage?.id || null);

    setValue(
      "subcategories",
      subcategoriesChecked.map((id) => ({ id }))
    );
  }, [selectedImage, subcategoriesChecked]);

  return (
    <>
      <MediaModal
        open={mediaModalOpen}
        setOpen={setMediaModalOpen}
        setSelectedImage={setSelectedImage}
        resource="POST"
      />
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="800px"
      >
        {!isLoading && data && (
          <>
            <Grid item xs={12} sm={12} md={4} height="100%">
              <Paper elevation={8} sx={{ height: "100%", padding: "5px" }}>
                <Typography
                  variant="h4"
                  sx={{ height: "5%", marginBottom: "2px" }}
                >
                  Ingredientes
                </Typography>
                <FormHelperText error={Boolean(errors?.ingredients)}>
                  {errors.ingredients?.message}
                </FormHelperText>
                <Controller
                  name="ingredients"
                  control={control}
                  defaultValue={data?.ingredients || "<ul><li></li></ul>"}
                  render={({ field: { value, onChange } }) => (
                    <Editor
                      init={{
                        ...editorInit,
                        toolbar: ingredientsToolbar,
                        height: "95%",
                        setup(editor) {
                          editor.ui.registry.addButton("clearText", {
                            icon: "remove-formatting",
                            tooltip: "Limpar texto",
                            onAction() {
                              editor.setContent(
                                clearEditorText(editor.getContent())
                              );
                            },
                          });
                        },
                      }}
                      value={value as string}
                      onEditorChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} height="100%">
              <Paper elevation={8} sx={{ height: "100%", padding: "5px" }}>
                <Typography
                  variant="h4"
                  sx={{ height: "5%", marginBottom: "2px" }}
                >
                  Modo de preparo
                </Typography>
                <FormHelperText error={Boolean(errors?.preparationMethod)}>
                  {errors.preparationMethod?.message}
                </FormHelperText>
                <Controller
                  name="preparationMethod"
                  control={control}
                  defaultValue={data?.preparationMethod || "<ol><li></li></ol>"}
                  render={({ field: { value, onChange } }) => (
                    <Editor
                      init={{
                        ...editorInit,
                        toolbar: preparationMethodToolbar,
                        height: "95%",
                        setup(editor) {
                          editor.ui.registry.addButton("clearText", {
                            icon: "remove-formatting",
                            tooltip: "Limpar texto",
                            onAction() {
                              editor.setContent(
                                clearEditorText(editor.getContent())
                              );
                            },
                          });
                        },
                      }}
                      value={value as string}
                      onEditorChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} height="100%">
              <Stack
                component={Paper}
                elevation={8}
                padding="5px"
                spacing={2}
                height="100%"
                overflow="auto"
              >
                <Controller
                  name="title"
                  control={control}
                  defaultValue={data.title}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="Título"
                      type="text"
                      size="medium"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors?.title)}
                      helperText={errors?.title?.message}
                    />
                  )}
                />

                <Controller
                  name="slug"
                  control={control}
                  defaultValue={data.slug}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="Slug"
                      type="text"
                      size="medium"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors?.slug)}
                      helperText={errors?.slug?.message}
                    />
                  )}
                />

                <Controller
                  name="portions"
                  control={control}
                  defaultValue={data?.portions || 0}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="Porções"
                      type="number"
                      size="medium"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors?.portions)}
                      helperText={errors?.portions?.message}
                    />
                  )}
                />

                <Controller
                  name="preparationTime"
                  control={control}
                  defaultValue={data?.preparationTime || 0}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="Tempo de preparo (min)"
                      type="number"
                      size="medium"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors?.preparationTime)}
                      helperText={errors?.preparationTime?.message}
                    />
                  )}
                />

                <Stack direction="column" justifyContent="space-between">
                  <Controller
                    name="published"
                    control={control}
                    defaultValue={data.published}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        sx={{ alignSelf: "end" }}
                        label="Postado"
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(event) => {
                              onChange(event.target.checked);
                              setIsPublished(event.target.checked);
                            }}
                          />
                        }
                      />
                    )}
                  />

                  <Controller
                    name="schedule"
                    control={control}
                    defaultValue={
                      data.schedule
                        ? {
                            task: "POST_RECIPE",
                            args: {
                              postId: data.schedule?.id,
                            },
                            schedule: data.schedule?.schedule,
                          }
                        : null
                    }
                    render={({ field: { value } }) => (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            slotProps={{
                              actionBar: {
                                actions: ["clear", "accept"],
                              },
                            }}
                            ampm={false}
                            disabled={isPublished}
                            label="Agendar"
                            value={value ? dayjs(value?.schedule) : null}
                            onAccept={(newValue) => {
                              setValue(
                                "schedule",
                                newValue !== null
                                  ? {
                                      task: "POST_RECIPE",
                                      args: {
                                        postId: Number(postId),
                                      },
                                      schedule: dayjs(
                                        newValue
                                      ).toISOString() as string,
                                    }
                                  : null
                              );
                            }}
                          />
                        </LocalizationProvider>
                        {errors.schedule && (
                          <FormHelperText error={Boolean(errors.schedule)}>
                            {
                              (errors.schedule as { schedule: FieldError })
                                ?.schedule?.message
                            }
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Stack>

                <Stack
                  component={Paper}
                  alignItems="center"
                  spacing={1}
                  variant="outlined"
                  sx={{ width: "100%", padding: "5px" }}
                >
                  <Typography variant="h6" alignSelf="flex-start">
                    Imagem em destaque
                  </Typography>
                  {!selectedImage ? (
                    <Button
                      variant="contained"
                      component="span"
                      sx={{
                        width: "100%",
                        height: "100px",
                        backgroundColor: "#f0f0f0",
                        color: "black",
                        ":hover": {
                          backgroundColor: "#D3D3D3",
                        },
                      }}
                      onClick={() => setMediaModalOpen(true)}
                    >
                      Definir imagem destacada
                    </Button>
                  ) : (
                    <Button onClick={() => setMediaModalOpen(true)}>
                      <img
                        src={selectedImage.url}
                        alt=""
                        style={{ width: "200px", height: "150px" }}
                      />
                    </Button>
                  )}
                </Stack>

                <Paper variant="outlined" sx={{ padding: "5px" }}>
                  <SubcategoriesList
                    subcategoriesChecked={subcategoriesChecked}
                    setSubcategoriesChecked={setSubcategoriesChecked}
                    error={Boolean(
                      errors.subcategories as unknown as FieldError
                    )}
                    errorMessage={
                      (errors.subcategories as unknown as FieldError)?.message
                    }
                  />
                </Paper>

                <Stack
                  component={Paper}
                  variant="outlined"
                  spacing={1}
                  sx={{ padding: "5px" }}
                >
                  <Typography variant="h6">Introdução</Typography>
                  <Controller
                    name="postMeta.introductionTitle"
                    control={control}
                    defaultValue={data.postMeta.introductionTitle || ""}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Título (Opcional)"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors?.postMeta?.introductionTitle)}
                        helperText={
                          errors?.postMeta?.introductionTitle?.message
                        }
                      />
                    )}
                  />
                  <Controller
                    name="postMeta.introduction"
                    control={control}
                    defaultValue={data.postMeta?.introduction || ""}
                    render={({ field: { value, onChange } }) => (
                      <Editor
                        init={{
                          ...editorInit,
                          toolbar: introductionToolbar,
                          height: "200px",
                          setup(editor) {
                            editor.ui.registry.addButton("clearText", {
                              icon: "remove-formatting",
                              tooltip: "Limpar texto",
                              onAction() {
                                editor.setContent(
                                  clearEditorText(editor.getContent())
                                );
                              },
                            });
                          },
                        }}
                        value={value as string}
                        onEditorChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                  <FormHelperText
                    error={Boolean(errors.postMeta?.introduction)}
                  >
                    {errors.postMeta?.introduction?.message}
                  </FormHelperText>
                </Stack>

                <Paper variant="outlined" sx={{ padding: "5px" }}>
                  <Typography variant="h6">Informações adicionais</Typography>
                  <Controller
                    name="additionalInformation"
                    control={control}
                    defaultValue={data?.additionalInformation || ""}
                    render={({ field: { value, onChange } }) => (
                      <Editor
                        init={{
                          ...editorInit,
                          toolbar: additionalInformationToolbar,
                          height: "200px",
                          setup(editor) {
                            editor.ui.registry.addButton("clearText", {
                              icon: "remove-formatting",
                              tooltip: "Limpar texto",
                              onAction() {
                                editor.setContent(
                                  clearEditorText(editor.getContent())
                                );
                              },
                            });
                          },
                        }}
                        value={value as string}
                        onEditorChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                </Paper>

                <Stack
                  component={Paper}
                  variant="outlined"
                  sx={{ padding: "5px" }}
                  spacing={2}
                >
                  <Typography variant="h6">SEO</Typography>
                  <Controller
                    name="postMeta.metaTitle"
                    control={control}
                    defaultValue={data.postMeta?.metaTitle || ""}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Meta título"
                        type="text"
                        size="medium"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.postMeta?.metaTitle)}
                        helperText={errors.postMeta?.metaTitle?.message}
                      />
                    )}
                  />
                  <Controller
                    name="postMeta.metaDescription"
                    control={control}
                    defaultValue={data.postMeta?.metaDescription || ""}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Meta descrição"
                        multiline
                        size="medium"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.postMeta?.metaDescription)}
                        helperText={errors.postMeta?.metaDescription?.message}
                      />
                    )}
                  />
                  <Controller
                    name="postMeta.metaIngredients"
                    control={control}
                    defaultValue={data.postMeta?.metaIngredients || ""}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Meta Ingredientes"
                        type="text"
                        multiline
                        size="medium"
                        fullWidth
                        error={Boolean(errors.postMeta?.metaIngredients)}
                        helperText={errors.postMeta?.metaIngredients?.message}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <Controller
                    name="postMeta.metaPreparationMethod"
                    control={control}
                    defaultValue={data.postMeta?.metaPreparationMethod || ""}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Meta Modo de Preparo"
                        type="text"
                        multiline
                        size="medium"
                        fullWidth
                        error={Boolean(errors.postMeta?.metaPreparationMethod)}
                        helperText={
                          errors.postMeta?.metaPreparationMethod?.message
                        }
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Stack>

                <Stack
                  component={Paper}
                  variant="outlined"
                  sx={{ padding: "5px" }}
                  spacing={2}
                >
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Typography variant="h6">Vídeo</Typography>
                    <Tooltip
                      title={
                        <>
                          <Typography>Exemplo:</Typography>
                          <em>www.youtube.com/embed/kfj23sAL_sAT</em>
                        </>
                      }
                      placement="right-start"
                    >
                      <IconButton>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Controller
                    name="postMeta.videoUrl"
                    control={control}
                    defaultValue={data.postMeta?.videoUrl || ""}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Url do vídeo"
                        type="text"
                        size="medium"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors?.postMeta?.videoUrl)}
                        helperText={errors?.postMeta?.videoUrl?.message}
                      />
                    )}
                  />
                </Stack>

                <Button
                  variant="contained"
                  onClick={handleSubmit(handleOnSubmit)}
                >
                  Salvar Alterações
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default EditPost;
