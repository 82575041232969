/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import { updateCategory } from "../../../services/categoriesService";
import { Category, EditCategory } from "../../../types/Category";
import { Image } from "../../../types/Image";
import MediaModal from "../MediaModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  height: "40%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  "@media(max-width: 425px)": {
    width: "90%",
  },
};

interface EditCategoryModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  category: Category;
  fetch: () => void;
}

const schema = yup.object().shape({
  name: yup.string().required("O campo Nome não pode ser vazio"),
  slug: yup.string().required("O campo Slug não pode ser vazio"),
  imageId: yup.number(),
});

function EditCategoryModal({
  open,
  setOpen,
  category,
  fetch,
}: EditCategoryModalProps) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<EditCategory>({
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const [mediaModalOpen, setMediaModalOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<Image | null>(null);

  React.useEffect(() => {
    if (category) {
      setSelectedImage(category.image);
      setValue("name", category.name);
      setValue("slug", category.slug);
    }
  }, [category]);

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit(data: EditCategory) {
    try {
      if (category) {
        // eslint-disable-next-line no-param-reassign
        data.imageId = selectedImage?.id ? selectedImage.id : null;

        await updateCategory(category.id, data);

        fetch();

        enqueueSnackbar("Categoria atualizada com sucesso!", {
          variant: "success",
        });

        handleClose();
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  return (
    <>
      <MediaModal
        open={mediaModalOpen}
        setOpen={setMediaModalOpen}
        setSelectedImage={setSelectedImage}
        resource="CATEGORY"
      />
      <Modal open={open} onClose={handleClose}>
        <Stack sx={style} spacing={2}>
          <Typography variant="h6">{`Editando "${
            category && category.name
          }"`}</Typography>

          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nome"
                size="small"
                error={Boolean(errors?.name)}
                helperText={errors?.name?.message}
              />
            )}
          />
          <Controller
            name="slug"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Slug"
                size="small"
                error={Boolean(errors?.slug)}
                helperText={errors?.slug?.message}
              />
            )}
          />
          {!selectedImage ? (
            <Button
              variant="contained"
              component="span"
              sx={{
                width: "100%",
                height: "100px",
                backgroundColor: "#f0f0f0",
                color: "black",
                ":hover": {
                  backgroundColor: "#D3D3D3",
                },
              }}
              onClick={() => setMediaModalOpen(true)}
            >
              Definir imagem
            </Button>
          ) : (
            <Button onClick={() => setMediaModalOpen(true)}>
              <img
                src={selectedImage.url}
                alt=""
                style={{ width: "200px", height: "100px" }}
              />
            </Button>
          )}
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            atualizar
          </Button>
        </Stack>
      </Modal>
    </>
  );
}

export default EditCategoryModal;
