import Box from "@mui/material/Box";
import { LazyLoadImage } from "react-lazy-load-image-component";

import img from "../assets/full_vovo.png";

function Dashboard() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <LazyLoadImage
        src={img}
        width={200}
        height={150}
        style={{ objectFit: "contain" }}
        effect="blur"
      />
    </Box>
  );
}

export default Dashboard;
