import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Editor as EditorType } from "tinymce";
import * as yup from "yup";

import Editor from "../../components/Editor";
import BlogCategoriesList from "../../components/Lists/BlogCategoriesList";
import EditorEmbedModal from "../../components/Modals/EditorEmbedModal";
import MediaModal from "../../components/Modals/MediaModal";
import AuthContext from "../../contexts/auth";
import { useFetchWithToken } from "../../hooks/useFetch";
import { updateBlogPost } from "../../services/blogPostsService";
import { EditBlogPost as EditBlogPostType } from "../../types/BlogPost";
import { Image } from "../../types/Image";
import clearEditorText from "../../utils/clearEditorText";
import { handleEditorImageUpload } from "../../utils/handleEditorImageUpload";

const editorInit = {
  menubar: false,
  plugins: ["lists", "link", "image", "media", "code", "wordcount"],
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
    "p { margin: 0; }" +
    "ul { margin: 0; }" +
    "ol { margin: 0; }" +
    "img { max-width: 600px; max-height: 400px }",
};

const toolbar =
  "undo redo blocks bold italic bullist numlist image link clearText embedYoutube code";

const schema = yup.object().shape({
  title: yup.string().required("O campo Título não pode ser vazio"),
  slug: yup.string().required("O campo Slug não pode ser vazio"),
  content: yup
    .string()
    .notRequired()
    .when("published", {
      is: (value: boolean) => value === true,
      then: yup
        .string()
        .required("O Conteúdo não pode ser vazio em post publicado"),
    })
    .when("schedule", {
      is: (value: object | null) => {
        if (value !== null) {
          return true;
        }
        return false;
      },
      then: yup
        .string()
        .required("O Conteúdo não pode ser vazio quando agendado"),
    }),
  description: yup
    .string()
    .notRequired()
    .max(160, "Limite de 160 caracteres ultrapassado"),
  keywords: yup.string().notRequired(),
  published: yup.boolean().default(false),
  authorId: yup.number().required(),
  categoryId: yup
    .number()
    .notRequired()
    .nullable()
    .when("published", {
      is: (value: boolean) => value === true,
      then: yup
        .number()
        .nullable()
        .required("A Categoria não pode ser vazia em post publicado"),
    })
    .when("schedule", {
      is: (value: object | null) => {
        if (value !== null) {
          return true;
        }
        return false;
      },
      then: yup
        .number()
        .nullable()
        .required("A categoria não pode ser vazio quando agendado"),
    }),
  imageId: yup
    .number()
    .notRequired()
    .nullable()
    .when("published", {
      is: (value: boolean) => value === true,
      then: yup
        .number()
        .nullable()
        .required("A Imagem não pode ser vazia em post publicado"),
    })
    .when("schedule", {
      is: (value: object | null) => {
        if (value !== null) {
          return true;
        }
        return false;
      },
      then: yup
        .number()
        .nullable()
        .required("A Imagem não pode ser vazio quando agendado"),
    }),
  schedule: yup
    .object()
    .shape({
      args: yup.object().shape({
        blogPostId: yup.number().required(),
      }),
      schedule: yup
        .string()
        .required()
        .test("is-future-date", "A data não pode ser no passado", (value) => {
          if (!value) {
            return false;
          }

          const selectedDate = new Date(value);
          const currentDate = new Date();

          return selectedDate >= currentDate;
        }),
    })
    .nullable()
    .default(null),
  blogPostMeta: yup.object().shape({
    metaTitle: yup.string().nullable().max(65, "Máximo de 65 caracteres"),
  }),
});

function EditBlogPost() {
  const { postId } = useParams();

  const { user } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { data, isLoading } = useFetchWithToken<EditBlogPostType>(
    `/blog-post/findById/${postId}`,
    user?.access_token as string
  );

  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  const [isPublished, setIsPublished] = useState<boolean>(false);

  const currentPost = useMemo<EditBlogPostType>(() => {
    if (data) {
      setSelectedCategory(data?.categoryId || null);
      setSelectedImage(data?.image || null);
      setIsPublished((prevState) => data.published ?? prevState);
    }

    return {
      title: data?.title || "",
      slug: data?.slug || "",
      content: data?.content || "",
      description: data?.description || "",
      keywords: data?.keywords || "",
      published: data?.published || false,
      authorId: user?.user.id,
      categoryId: data?.categoryId,
      imageId: data?.imageId,
      schedule: data?.schedule,
      blogPostMeta: data?.blogPostMeta || { metaTitle: "" },
    };
  }, [data, user]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<EditBlogPostType>({
    resolver: yupResolver(schema),
    defaultValues: currentPost,
  });

  useEffect(() => {
    reset(currentPost);
  }, [currentPost]);

  const [mediaModalOpen, setMediaModalOpen] = useState(false);

  useEffect(() => {
    if (selectedImage) {
      setValue("imageId", selectedImage.id);
    } else {
      setValue("imageId", null);
    }

    if (selectedCategory) {
      setValue("categoryId", selectedCategory);
    } else {
      setValue("categoryId", null);
    }
  }, [selectedImage, selectedCategory]);

  useEffect(() => {
    if (isPublished === true) {
      setValue("schedule", null);
    }
  }, [isPublished]);

  async function submit(data: EditBlogPostType) {
    try {
      await updateBlogPost(Number(postId), data);

      enqueueSnackbar("Post atualizado com sucesso!", {
        variant: "success",
      });

      navigate(-1);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  const contentEditorRef = useRef<EditorType>();

  const [editorEmbedModalOpen, setEditorEmbedModalOpen] = useState(false);

  return (
    <>
      <MediaModal
        open={mediaModalOpen}
        setOpen={setMediaModalOpen}
        setSelectedImage={setSelectedImage}
        resource="BLOG_POST"
      />
      <EditorEmbedModal
        open={editorEmbedModalOpen}
        setOpen={setEditorEmbedModalOpen}
        editorRef={contentEditorRef}
      />
      <Box width="100%">
        <form onSubmit={handleSubmit(submit)}>
          {data && !isLoading && (
            <Grid
              container
              columns={3}
              height={{ xs: "auto", md: "800px" }}
              spacing={1}
            >
              <Grid item xs={3} md={2}>
                <Paper
                  elevation={8}
                  sx={{ height: "100%", borderRadius: "10px", padding: "5px" }}
                >
                  <Typography variant="h5" sx={{ marginBottom: "2px" }}>
                    Conteúdo
                  </Typography>
                  <FormHelperText error={!!errors?.content}>
                    {errors.content?.message}
                  </FormHelperText>
                  <Controller
                    name="content"
                    control={control}
                    render={({ field }) => (
                      <Editor
                        onInit={(_, editor) => {
                          contentEditorRef.current = editor;
                        }}
                        init={{
                          ...editorInit,
                          toolbar,
                          images_upload_handler: (blobInfo) =>
                            handleEditorImageUpload(
                              String(user?.user.id),
                              blobInfo
                            ),
                          height: "750px",
                          setup(editor) {
                            editor.ui.registry.addButton("clearText", {
                              icon: "remove-formatting",
                              tooltip: "Limpar texto",
                              onAction() {
                                editor.setContent(
                                  clearEditorText(editor.getContent())
                                );
                              },
                            });
                            editor.ui.registry.addButton("embedYoutube", {
                              icon: "embed",
                              tooltip: "Anexar vídeo do Youtube",
                              onAction() {
                                setEditorEmbedModalOpen(true);
                              },
                            });
                          },
                        }}
                        initialValue={data.content?.replace(
                          /src="\/uploads/g,
                          `src="https://backend.receitadevovo.com.br/uploads`
                        )}
                        onEditorChange={(e, editor) => {
                          field.onChange(editor.getContent());
                        }}
                      />
                    )}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3} md={1} maxHeight={{ xs: "auto", md: "800px" }}>
                <Stack
                  direction="column"
                  spacing={2}
                  p="5px"
                  height="100%"
                  overflow="auto"
                >
                  <Controller
                    name="title"
                    control={control}
                    defaultValue={data.title}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type="text"
                        label="Título"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors?.title)}
                        helperText={errors?.title?.message}
                      />
                    )}
                  />
                  <Controller
                    name="slug"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type="text"
                        label="Slug"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors?.slug)}
                        helperText={errors?.slug?.message}
                      />
                    )}
                  />
                  <Controller
                    name="keywords"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type="text"
                        label="Palavras-chave (keywords)"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />

                  <Stack direction="column" justifyContent="space-between">
                    <Controller
                      name="published"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          label="Postado"
                          sx={{ alignSelf: "end" }}
                          control={
                            <Checkbox
                              checked={value}
                              onChange={(event) => {
                                onChange(event.target.checked);
                                setIsPublished(event.target.checked);
                              }}
                            />
                          }
                        />
                      )}
                    />
                    <Controller
                      name="schedule"
                      control={control}
                      defaultValue={data.schedule}
                      render={({ field: { value } }) => (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              slotProps={{
                                actionBar: {
                                  actions: ["clear", "accept"],
                                },
                              }}
                              ampm={false}
                              disabled={isPublished}
                              label="Agendar"
                              value={value ? dayjs(value?.schedule) : null}
                              onAccept={(newValue) => {
                                setValue(
                                  "schedule",
                                  newValue !== null
                                    ? {
                                        task: "POST_BLOG",
                                        args: {
                                          blogPostId: Number(postId),
                                        },
                                        schedule: dayjs(
                                          newValue
                                        ).toISOString() as string,
                                      }
                                    : null
                                );
                              }}
                            />
                          </LocalizationProvider>
                          {errors.schedule && (
                            <FormHelperText error={Boolean(errors.schedule)}>
                              {
                                (errors.schedule as { schedule: FieldError })
                                  ?.schedule?.message
                              }
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </Stack>

                  <Paper sx={{ width: "100%", padding: "5px" }}>
                    <Typography variant="h6" color="rgba(0, 0, 0, 0.6)">
                      Imagem principal
                    </Typography>
                    <FormHelperText error={!!errors?.imageId}>
                      {errors.imageId?.message}
                    </FormHelperText>
                    {!selectedImage ? (
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          height: "120px",
                          backgroundColor: "rgba(0,0,0,0.6)",
                          color: "white",
                        }}
                        onClick={() => setMediaModalOpen(true)}
                      >
                        Definir imagem
                      </Button>
                    ) : (
                      <Button fullWidth onClick={() => setMediaModalOpen(true)}>
                        <img
                          src={selectedImage.url}
                          alt={selectedImage?.alt || ""}
                          style={{ width: "200px", height: "120px" }}
                        />
                      </Button>
                    )}
                  </Paper>
                  <Stack
                    component={Paper}
                    variant="outlined"
                    sx={{ padding: "5px" }}
                    spacing={2}
                  >
                    <Typography variant="h6">SEO</Typography>
                    <Controller
                      name="blogPostMeta.metaTitle"
                      control={control}
                      defaultValue={data.blogPostMeta?.metaTitle || ""}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="Meta título"
                          type="text"
                          size="medium"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.blogPostMeta?.metaTitle)}
                          helperText={errors.blogPostMeta?.metaTitle?.message}
                        />
                      )}
                    />
                    <Controller
                      name="description"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="Meta descrição"
                          type="text"
                          multiline
                          size="medium"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.description)}
                          helperText={errors.description?.message}
                        />
                      )}
                    />
                  </Stack>
                  <BlogCategoriesList
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    error={!!errors?.categoryId}
                    errorMessage={errors?.categoryId?.message}
                  />
                  <Button type="submit" variant="contained">
                    Atualizar postagem
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </form>
      </Box>
    </>
  );
}

export default EditBlogPost;
