import { UserLogged } from "../types/Auth";

export function getLocalAccessToken() {
  const user: UserLogged = JSON.parse(localStorage.getItem("user") as string);
  return user?.access_token;
}

export function getLocalRefreshToken() {
  const user: UserLogged = JSON.parse(localStorage.getItem("user") as string);
  return user?.refresh_token.id;
}

export function updateLocalAccessToken(newToken: UserLogged) {
  localStorage.setItem("user", JSON.stringify(newToken));
}
