import { EditPopup, NewPopup } from "../types/Popup";
import instance from "./api";

export async function createPopup(popup: NewPopup) {
  const { data } = await instance.post(`/popup`, popup);

  return data;
}

export async function updatePopup(popupId: number, popup: EditPopup) {
  const { data } = await instance.patch(`/popup/${popupId}`, popup);

  return data;
}

export async function deletePopup(popupId: number) {
  const { data } = await instance.delete(`/popup/${popupId}`);

  return data;
}
