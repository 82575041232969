/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import AuthContext from "../../../contexts/auth";
import { createReply } from "../../../services/commentsService";
import { UserLogged } from "../../../types/Auth";
import { Comment, NewReply } from "../../../types/Comment";

interface ReplyCommentModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentComment: Comment | null;
  fetch: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
  "@media(max-width: 900px)": {
    width: "90%",
  },
};

const schema = yup.object().shape({
  content: yup.string().required("O campo resposta não pode ser vazio"),
});

const defaultValues: NewReply = {
  content: "",
  approved: true,
  authorId: null,
  parentId: null,
  postId: null,
};

function ReplyCommentModal({
  open,
  setOpen,
  parentComment,
  fetch,
}: ReplyCommentModalProps) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const { user } = React.useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => setOpen(false);

  async function onSubmit(data: NewReply, parentComment: Comment) {
    try {
      data.approved = true;

      const {
        user: { id: userLoggedId },
      } = user as UserLogged;

      data.authorId = userLoggedId;

      data.parentId = parentComment?.id;

      data.postId = parentComment?.postId;

      await createReply(data);

      fetch();
      reset();
      enqueueSnackbar("Resposta enviada com sucesso!", {
        variant: "success",
      });

      handleClose();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <Paper elevation={6} sx={{ width: "100%", padding: "5px" }}>
            <Typography variant="h6">Respondendo</Typography>
            <span style={{ wordWrap: "break-word", textAlign: "justify" }}>
              {parentComment?.content}
            </span>
          </Paper>
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                maxRows={15}
                label="Resposta"
                error={Boolean(errors?.content)}
                helperText={errors?.content?.message}
              />
            )}
          />

          <Button
            variant="contained"
            sx={{ alignSelf: "end" }}
            onClick={handleSubmit((data) =>
              onSubmit(data, parentComment as Comment)
            )}
          >
            Publicar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default ReplyCommentModal;
