/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useUsers } from "../../../../pages/Users";
import { updateUser } from "../../../../services/userService";
import { EditUser, User } from "../../../../types/User";
import {
  formatFromDateToIso,
  formatFromIsoToDate,
} from "../../../../utils/formatDate";

interface EditUserFormProps {
  user: User;
}

function EditUserForm({ user }: EditUserFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { fetch } = useUsers();

  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup.string().email(),
    first_name: yup.string(),
    last_name: yup.string(),
    gender: yup.string(),
    password: yup.string(),
    birthDate: yup.string(),
    roleId: yup.number(),
  });

  const { handleSubmit, control } = useForm<EditUser>({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      password: user.password,
      gender: user.gender === "f" ? "Feminino" : "Masculino",
      birthDate: formatFromIsoToDate(user.birthDate as string),
      roleId: user.roleId?.toString(),
    },
    resolver: yupResolver(schema),
  });

  async function onSubmit(data: EditUser) {
    try {
      data.birthDate = formatFromDateToIso(data.birthDate as string);

      await updateUser(user.id, data);
      fetch();
      enqueueSnackbar("Usuário atualizado com sucesso!", {
        variant: "success",
      });
      navigate("/usuarios");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  return (
    <>
      <Stack component={Paper} padding="5px" spacing={2} elevation={2}>
        <Typography variant="h5">Editar usuário</Typography>

        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <TextField {...field} size="small" label="Primeiro nome" disabled />
          )}
        />

        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <TextField {...field} size="small" label="Segundo nome" disabled />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField {...field} size="small" label="E-mail" disabled />
          )}
        />
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <TextField {...field} size="small" label="Sexo" disabled />
          )}
        />
        <Controller
          name="birthDate"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Data de nascimento"
              disabled
            />
          )}
        />
        <Controller
          name="roleId"
          control={control}
          render={({ field }) => (
            <FormControl>
              <InputLabel id="select-user-role">Cargo</InputLabel>
              <Select {...field} labelId="select-user-role" label="Cargo">
                <MenuItem value="1">Admin</MenuItem>
                <MenuItem value="2">Editor</MenuItem>
                <MenuItem value="3">Contribuidor</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Salvar alterações
        </Button>
      </Stack>
    </>
  );
}

export default EditUserForm;
