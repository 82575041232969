export function formatFromIsoToDateTime(dateToFormat: string) {
  const date = new Date(dateToFormat);
  const day = date.getDate().toString();
  const dayF = day.length === 1 ? `0${day}` : day;
  const month = (date.getMonth() + 1).toString(); // +1 pois no getMonth() Janeiro começa com zero.
  const monthF = month.length === 1 ? `0${month}` : month;
  const yearF = date.getFullYear();

  const hour: string | number =
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();

  const minutes: string | number =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  const seconds: string | number =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
  return `${dayF}/${monthF}/${yearF} às ${hour}:${minutes}:${seconds}`;
}

export function formatFromIsoToDate(dateToFormat: string) {
  const date = new Date(dateToFormat);
  const day = date.getDate().toString();
  const dayF = day.length === 1 ? `0${day}` : day;
  const month = (date.getMonth() + 1).toString(); // +1 pois no getMonth() Janeiro começa com zero.
  const monthF = month.length === 1 ? `0${month}` : month;
  const yearF = date.getFullYear();

  return `${dayF}/${monthF}/${yearF}`;
}

export function formatFromDateToIso(dateToFormat: string) {
  return new Date(dateToFormat).toISOString();
}
