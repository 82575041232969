/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldError,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Editor from "../../components/Editor";
import SubcategoriesList from "../../components/Lists/SubcategoriesList";
import MediaModal from "../../components/Modals/MediaModal";
import AuthContext from "../../contexts/auth";
import { createPost } from "../../services/postsService";
import { Image } from "../../types/Image";
import { NewPost as NewPostType } from "../../types/Post/NewPost";
import clearEditorText from "../../utils/clearEditorText";

const editorInit = {
  menubar: false,
  plugins: ["lists", "link", "media", "code", "wordcount"],
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
    "p { margin: 0; }" +
    "ul { margin: 0; }" +
    "ol { margin: 0; }",
};

const ingredientsToolbar = "undo redo bold italic bullist link clearText code";

const preparationMethodToolbar =
  "undo redo bold italic numlist link clearText code";

const additionalInformationToolbar =
  "undo redo bold italic bullist numlist link clearText code";

const introductionToolbar = "undo redo bold italic link clearText code";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("O campo Título não pode ser vazio")
    .max(65, "Máximo de 65 caracteres"),
  portions: yup
    .number()
    .typeError("Preencha as porções")
    .moreThan(0, "O valor das porções tem que ser maior que zero")
    .notRequired()
    .when("schedule", {
      is: (value: unknown) => value !== null,
      then: yup
        .number()
        .typeError("Preencha as porções")
        .moreThan(0, "O valor das porções tem que ser maior que zero")
        .required("Porções não pode ser vazio"),
    }),
  preparationTime: yup
    .number()
    .typeError("Preencha o tempo de preparo")
    .moreThan(0, "O valor do tempo de preparo tem que ser maior que zero")
    .notRequired()
    .when("schedule", {
      is: (value: unknown) => value !== null,
      then: yup
        .number()
        .typeError("Preencha o tempo de preparo")
        .moreThan(0, "O valor do tempo de preparo tem que ser maior que zero")
        .required("Tempo de preparo não pode ser vazio"),
    }),
  ingredients: yup
    .string()
    .notRequired()
    .when("schedule", {
      is: (value: unknown) => value !== null,
      then: yup
        .string()
        .transform((string) => string.replace(/\n/g, ""))
        .matches(/^(?!<ul><li>&nbsp;<\/li><\/ul>$|^$).*$/gim, {
          message: "Ingredientes não pode ser vazio",
        }),
      otherwise: yup.string().notRequired().nullable(),
    }),
  preparationMethod: yup
    .string()
    .notRequired()
    .when("schedule", {
      is: (value: unknown) => value !== null,
      then: yup
        .string()
        .transform((string) => string.replace(/\n/g, ""))
        .matches(/^(?!<ol><li>&nbsp;<\/li><\/ol>$|^$).*$/gim, {
          message: "Modo de preparo não pode ser vazio",
        }),
      otherwise: yup.string().notRequired().nullable(),
    }),
  additionalInformation: yup.string().notRequired(),
  imageId: yup.number().positive().integer().notRequired().nullable(),
  published: yup.boolean().default(false),
  authorId: yup.number().positive().integer(),
  subcategories: yup
    .array()
    .of(yup.object().shape({ id: yup.number() }))
    .test(
      "has-at-least-one-object",
      "Deve conter pelo menos um objeto",
      (value, ctx) => {
        const { schedule }: { schedule: unknown } = ctx.parent;

        if (schedule !== null) {
          if (value && value.length > 0) {
            return true;
          }

          return ctx.createError({
            path: ctx.path,
            message: "Deve conter pelo ao menos uma subcategoria",
          });
        }
        return true;
      }
    ),
  postMeta: yup.object().shape({
    metaTitle: yup.string().notRequired().max(65, "Máximo de 65 caracteres"),
    metaDescription: yup
      .string()
      .notRequired()
      .max(160, "Máximo de 160 caracteres"),
    videoUrl: yup.lazy((value: string) => {
      if (value === "") {
        return yup.string().notRequired();
      }
      return yup
        .string()
        .notRequired()
        .matches(
          /(www\.)youtube.com\/embed\/([\w\\-]+)/,
          "Formato de Url incorreto!"
        );
    }),
    metaIngredients: yup.string().nullable(),
    metaPreparationMethod: yup.string().nullable(),
    introductionTitle: yup.string().nullable(),
    introduction: yup.string().nullable(),
  }),
  schedule: yup
    .object()
    .shape({
      schedule: yup
        .string()
        .nullable()
        .test("is-future-date", "A data não pode ser no passado", (value) => {
          if (!value) {
            return true;
          }

          const selectedDate = new Date(value as string);
          const currentDate = new Date();

          return selectedDate >= currentDate;
        }),
    })
    .nullable()
    .default(null),
});

function NewPost() {
  const { user } = React.useContext(AuthContext);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<NewPostType>({
    defaultValues: { authorId: user?.user.id },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [subcategoriesChecked, setSubcategoriesChecked] = React.useState<
    number[]
  >([]);

  const [mediaModalOpen, setMediaModalOpen] = React.useState(false);

  const [selectedImage, setSelectedImage] = React.useState<Image | null>(null);

  useEffect(() => {
    setValue("imageId", selectedImage?.id || null);

    setValue(
      "subcategories",
      subcategoriesChecked.map((id) => ({ id }))
    );
  }, [selectedImage, subcategoriesChecked]);

  const onSubmit: SubmitHandler<NewPostType> = async (data: NewPostType) => {
    try {
      await createPost(data);

      enqueueSnackbar("Post adicionado com sucesso!", {
        variant: "success",
      });

      navigate(-1);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  return (
    <>
      <MediaModal
        open={mediaModalOpen}
        setOpen={setMediaModalOpen}
        setSelectedImage={setSelectedImage}
        resource="POST"
      />
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="800px"
      >
        <Grid item xs={12} sm={12} md={4} height="100%">
          <Paper elevation={8} sx={{ height: "100%", padding: "5px" }}>
            <Typography variant="h4" sx={{ height: "5%", marginBottom: "2px" }}>
              Ingredientes
            </Typography>
            <FormHelperText error={Boolean(errors?.ingredients)}>
              {errors.ingredients?.message}
            </FormHelperText>
            <Controller
              name="ingredients"
              control={control}
              render={({ field: { onChange } }) => (
                <Editor
                  init={{
                    ...editorInit,
                    toolbar: ingredientsToolbar,
                    height: "95%",
                    setup(editor) {
                      editor.ui.registry.addButton("clearText", {
                        icon: "remove-formatting",
                        tooltip: "Limpar texto",
                        onAction() {
                          editor.setContent(
                            clearEditorText(editor.getContent())
                          );
                        },
                      });
                    },
                  }}
                  initialValue="<ul><li></li></ul>"
                  onEditorChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} height="100%">
          <Paper elevation={8} sx={{ height: "100%", padding: "5px" }}>
            <Typography variant="h4" sx={{ height: "5%", marginBottom: "2px" }}>
              Modo de preparo
            </Typography>
            <FormHelperText error={Boolean(errors?.preparationMethod)}>
              {errors.preparationMethod?.message}
            </FormHelperText>
            <Controller
              name="preparationMethod"
              control={control}
              render={({ field: { onChange } }) => (
                <Editor
                  init={{
                    ...editorInit,
                    toolbar: preparationMethodToolbar,
                    height: "95%",
                    setup(editor) {
                      editor.ui.registry.addButton("clearText", {
                        icon: "remove-formatting",
                        tooltip: "Limpar texto",
                        onAction() {
                          editor.setContent(
                            clearEditorText(editor.getContent())
                          );
                        },
                      });
                    },
                  }}
                  initialValue="<ol><li></li></ol>"
                  onEditorChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} height="100%">
          <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <Stack
              component={Paper}
              elevation={8}
              padding="5px"
              spacing={2}
              height="100%"
              overflow="auto"
            >
              <Controller
                name="title"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextField
                    label="Título"
                    type="text"
                    size="medium"
                    fullWidth
                    error={Boolean(errors.title)}
                    helperText={errors.title?.message}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name="portions"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextField
                    label="Porções"
                    type="number"
                    size="medium"
                    fullWidth
                    error={Boolean(errors.portions)}
                    helperText={errors.portions?.message}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name="preparationTime"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextField
                    label="Tempo de preparo (min)"
                    type="number"
                    size="medium"
                    fullWidth
                    error={Boolean(errors.preparationTime)}
                    helperText={errors.preparationTime?.message}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name="schedule"
                control={control}
                render={() => (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                        }}
                        ampm={false}
                        label="Agendar"
                        onAccept={(newValue: dayjs.Dayjs | null) => {
                          setValue(
                            "schedule.schedule",
                            newValue?.toISOString() as string
                          );
                        }}
                      />
                    </LocalizationProvider>
                    {errors.schedule && (
                      <FormHelperText error={Boolean(errors.schedule)}>
                        {
                          (errors.schedule as { schedule: FieldError })
                            ?.schedule?.message
                        }
                      </FormHelperText>
                    )}
                  </>
                )}
              />

              <Paper variant="outlined" sx={{ width: "100%", padding: "5px" }}>
                <Stack alignItems="center" spacing={1}>
                  <Typography variant="h6" alignSelf="flex-start">
                    Imagem em destaque
                  </Typography>
                  {!selectedImage ? (
                    <Button
                      variant="contained"
                      component="span"
                      sx={{
                        width: "100%",
                        height: "100px",
                        backgroundColor: "#f0f0f0",
                        color: "black",
                        ":hover": {
                          backgroundColor: "#D3D3D3",
                        },
                      }}
                      onClick={() => setMediaModalOpen(true)}
                    >
                      Definir imagem destacada
                    </Button>
                  ) : (
                    <Button onClick={() => setMediaModalOpen(true)}>
                      <img
                        src={selectedImage.url}
                        alt=""
                        style={{ width: "200px", height: "150px" }}
                      />
                    </Button>
                  )}
                </Stack>
              </Paper>

              <Paper variant="outlined" sx={{ padding: "5px" }}>
                <SubcategoriesList
                  subcategoriesChecked={subcategoriesChecked}
                  setSubcategoriesChecked={setSubcategoriesChecked}
                  error={Boolean(errors.subcategories as unknown as FieldError)}
                  errorMessage={
                    (errors.subcategories as unknown as FieldError)?.message
                  }
                />
              </Paper>

              <Stack
                component={Paper}
                variant="outlined"
                spacing={1}
                sx={{ padding: "5px" }}
              >
                <Typography variant="h6">Introdução</Typography>
                <Controller
                  name="postMeta.introductionTitle"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Título (Opcional)"
                      fullWidth
                      onChange={onChange}
                      error={Boolean(errors?.postMeta?.introductionTitle)}
                      helperText={errors?.postMeta?.introductionTitle?.message}
                    />
                  )}
                />
                <Controller
                  name="postMeta.introduction"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Editor
                      init={{
                        ...editorInit,
                        toolbar: introductionToolbar,
                        height: "200px",
                        setup(editor) {
                          editor.ui.registry.addButton("clearText", {
                            icon: "remove-formatting",
                            tooltip: "Limpar texto",
                            onAction() {
                              editor.setContent(
                                clearEditorText(editor.getContent())
                              );
                            },
                          });
                        },
                      }}
                      onEditorChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                <FormHelperText error={Boolean(errors.postMeta?.introduction)}>
                  {errors.postMeta?.introduction?.message}
                </FormHelperText>
              </Stack>

              <Paper variant="outlined" sx={{ padding: "5px" }}>
                <Typography variant="h6">Informações adicionais</Typography>
                <Controller
                  name="additionalInformation"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Editor
                      init={{
                        ...editorInit,
                        toolbar: additionalInformationToolbar,
                        height: "200px",
                        setup(editor) {
                          editor.ui.registry.addButton("clearText", {
                            icon: "remove-formatting",
                            tooltip: "Limpar texto",
                            onAction() {
                              editor.setContent(
                                clearEditorText(editor.getContent())
                              );
                            },
                          });
                        },
                      }}
                      onEditorChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Paper>

              <Stack
                component={Paper}
                variant="outlined"
                sx={{ padding: "5px" }}
                spacing={2}
              >
                <Typography variant="h6">SEO</Typography>
                <Controller
                  name="postMeta.metaTitle"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Meta título"
                      type="text"
                      size="medium"
                      fullWidth
                      error={Boolean(errors.postMeta?.metaTitle)}
                      helperText={errors.postMeta?.metaTitle?.message}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="postMeta.metaDescription"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Meta descrição"
                      type="text"
                      multiline
                      size="medium"
                      fullWidth
                      error={Boolean(errors.postMeta?.metaDescription)}
                      helperText={errors.postMeta?.metaDescription?.message}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="postMeta.metaIngredients"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Meta Ingredientes"
                      type="text"
                      multiline
                      size="medium"
                      fullWidth
                      error={Boolean(errors.postMeta?.metaIngredients)}
                      helperText={errors.postMeta?.metaIngredients?.message}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="postMeta.metaPreparationMethod"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Meta Modo de Preparo"
                      type="text"
                      multiline
                      size="medium"
                      fullWidth
                      error={Boolean(errors.postMeta?.metaPreparationMethod)}
                      helperText={
                        errors.postMeta?.metaPreparationMethod?.message
                      }
                      onChange={onChange}
                    />
                  )}
                />
              </Stack>

              <Stack
                component={Paper}
                variant="outlined"
                sx={{ padding: "5px" }}
                spacing={2}
              >
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <Typography variant="h6">Vídeo</Typography>
                  <Tooltip
                    title={
                      <>
                        <Typography>Exemplo:</Typography>
                        <em>www.youtube.com/embed/kfj23sAL_sAT</em>
                      </>
                    }
                    placement="right-start"
                  >
                    <IconButton>
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Controller
                  name="postMeta.videoUrl"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Url do vídeo"
                      type="text"
                      size="medium"
                      fullWidth
                      error={Boolean(errors.postMeta?.videoUrl)}
                      helperText={errors.postMeta?.videoUrl?.message}
                      onChange={onChange}
                    />
                  )}
                />
              </Stack>

              <Button variant="contained" type="submit">
                Adicionar post
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default NewPost;
