import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { MutableRefObject } from "react";
import { Controller, useForm } from "react-hook-form";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Editor as EditorType } from "tinymce";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "360px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  "@media(max-width: 425px)": {
    width: "90%",
  },
};

type FormType = {
  metaTitle: string;
  videoUrl: string;
  isShort: boolean;
  alignCenter: boolean;
};

const schema = yup.object().shape({
  metaTitle: yup
    .string()
    .required("Meta título é necessário!")
    .max(65, "Máximo de 65 caracteres"),
  videoUrl: yup.lazy((value: string) => {
    if (value === "") {
      return yup.string().required("A URL é necessária!");
    }
    return yup
      .string()
      .required("A URL é necessária!")
      .matches(
        /https?:\/\/(?:www\.)?youtube.com\/embed\/([\w\\-]+)/,
        "Formato de URL incorreto!"
      );
  }),
  isShort: yup.boolean().notRequired().default(false),
  alignCenter: yup.boolean().notRequired().default(false),
});

interface EditorEmbedModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editorRef: MutableRefObject<EditorType | undefined>;
}

function EditorEmbedModal({ open, setOpen, editorRef }: EditorEmbedModalProps) {
  const handleClose = () => {
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      metaTitle: "",
      videoUrl: "",
      isShort: false,
      alignCenter: false,
    },
    resolver: yupResolver(schema),
  });

  function submit(data: FormType) {
    const iframeHtml = data.isShort
      ? `<div><iframe id="iframe-yt-short-player" style="${
          data.alignCenter ? "display: block; margin: 0 auto; border: 0;" : ""
        }" title="${data.metaTitle}" src="${
          data.videoUrl
        }" width="225" height="400" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>`
      : `<div><iframe id="iframe-yt-player" style="${
          data.alignCenter ? "display: block; margin: 0 auto; border: 0;" : ""
        }" title="${data.metaTitle}" src="${
          data.videoUrl
        }" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe></div>`;
    editorRef.current?.insertContent(iframeHtml);
    reset();
    handleClose();
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(submit)}>
        <Stack sx={style} spacing={2}>
          <Controller
            name="metaTitle"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Meta título"
                type="text"
                size="small"
                fullWidth
                value={value}
                onChange={onChange}
                error={Boolean(errors.metaTitle)}
                helperText={errors.metaTitle?.message}
              />
            )}
          />
          <Stack direction="row">
            <Controller
              name="videoUrl"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  label="URL do vídeo"
                  type="text"
                  size="small"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.videoUrl)}
                  helperText={errors.videoUrl?.message}
                />
              )}
            />
            <Tooltip
              title={
                <>
                  <Typography>Exemplo:</Typography>
                  <em>https://www.youtube.com/embed/id_do_video</em>
                </>
              }
              placement="right-start"
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack direction="row" justifyContent="end" flexWrap="wrap">
            <Controller
              name="alignCenter"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Alinhar ao centro"
                  sx={{ alignSelf: "end" }}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(event) => {
                        onChange(event.target.checked);
                      }}
                    />
                  }
                />
              )}
            />
            <Controller
              name="isShort"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Short"
                  sx={{ alignSelf: "end" }}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(event) => {
                        onChange(event.target.checked);
                      }}
                    />
                  }
                />
              )}
            />
          </Stack>
          <Button type="submit" variant="contained">
            Anexar
          </Button>
        </Stack>
      </form>
    </Modal>
  );
}

export default EditorEmbedModal;
