/* eslint-disable react/jsx-no-constructed-context-values */

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

import AuthService from "../services/authService";
import { UserLogged } from "../types/Auth";

interface AuthContextData {
  signed: boolean;
  user: UserLogged | null;
  signIn: ({ email, password }: SignInProps) => Promise<void>;
  signOut: () => void;
}
interface Props {
  children: JSX.Element;
}

interface SignInProps {
  email: string;
  password: string;
}

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: Props) {
  const [user, setUser] = React.useState<UserLogged | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") as string);

    if (user) {
      setUser(user);
    }

    setTimeout(() => setLoading(false), 500);
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  async function signIn({ email, password }: SignInProps) {
    const user = await AuthService.signIn({
      email,
      password,
    });

    setUser(user);
  }

  function signOut() {
    AuthService.signOut();

    setUser(null);
  }

  return (
    <AuthContext.Provider value={{ signed: !!user, user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
