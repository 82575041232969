/* eslint-disable no-nested-ternary */
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Link, TableSortLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { orderBy } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useLocation } from "react-router-dom";

import {
  deleteComment,
  deleteManyComments,
  updateComment,
} from "../../../services/commentsService";
import { Comment } from "../../../types/Comment";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";
import AlertDialog from "../../AlertDialog";
import NewCommentModal from "../../Modals/NewCommentModal";
import ReplyCommentModal from "../../Modals/ReplyCommentModal";

interface RowProps {
  comment: Comment;
  fetch: () => void;
  setOpenReplyCommentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setParentComment: React.Dispatch<React.SetStateAction<Comment | null>>;
  setTypeOfDeletion: React.Dispatch<
    React.SetStateAction<"singleDeletion" | "manyDeletions">
  >;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setTitleDialog: React.Dispatch<React.SetStateAction<string>>;
  setContentDialog: React.Dispatch<React.SetStateAction<string>>;
  setCommentIdToDelete: React.Dispatch<React.SetStateAction<number | null>>;
  selectedComments: number[];
  setSelectedComments: React.Dispatch<React.SetStateAction<number[]>>;
}

function Row({
  comment,
  fetch,
  setOpenReplyCommentModal,
  setParentComment,
  setTypeOfDeletion,
  setOpenDialog,
  setTitleDialog,
  setContentDialog,
  setCommentIdToDelete,
  selectedComments,
  setSelectedComments,
}: RowProps) {
  const route = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const handleClickDelete = (commentId: number) => {
    setTypeOfDeletion("singleDeletion");
    setTitleDialog("Excluir comentário");
    setContentDialog(
      "Tem certeza que deseja excluir o comentário? A ação não pode ser desfeita."
    );
    setOpenDialog(true);
    setCommentIdToDelete(commentId);
  };

  const handleToggleComments = (userId: number) => {
    const currentIndex = selectedComments.indexOf(userId);
    const newChecked = [...selectedComments];

    if (currentIndex === -1) {
      newChecked.push(userId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedComments(newChecked);
  };

  const handleApproved = async (commentId: number) => {
    try {
      await updateComment(commentId, {
        approved: true,
        parentId: comment.parentId,
      });
      fetch();
      enqueueSnackbar("Aprovado com sucesso", { variant: "success" });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const handleReject = async (commentId: number) => {
    try {
      await updateComment(commentId, { approved: false });
      fetch();
      enqueueSnackbar("Movido para os pendentes com sucesso", {
        variant: "success",
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedComments.indexOf(comment.id) !== -1}
          onChange={() => handleToggleComments(comment.id)}
        />
      </TableCell>
      <TableCell>
        {comment.author?.full_name
          ? `${comment.author?.full_name}`
          : comment.author?.first_name
          ? `${comment.author?.first_name} ${comment.author?.last_name}`
          : ""}
      </TableCell>
      <TableCell>{comment.content}</TableCell>
      <TableCell>
        <Stack>
          {comment.post?.title}
          <Link href={`posts/${comment.post?.id}`} target="_blank">
            Ver
          </Link>
        </Stack>
      </TableCell>
      <TableCell>
        {comment.parentId ? `${comment.parent?.author?.full_name}` : ""}
      </TableCell>
      <TableCell>
        {formatFromIsoToDateTime(comment.createdAt as string)}
      </TableCell>
      <TableCell>
        {route.pathname.includes("pendings") ? (
          <Tooltip title="aprovar">
            <IconButton
              aria-label="aprovar"
              onClick={() => handleApproved(comment.id)}
            >
              <ThumbUpIcon color="success" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="rejeitar">
            <IconButton
              aria-label="rejeitar"
              onClick={() => handleReject(comment.id)}
            >
              <ThumbDownIcon color="error" />
            </IconButton>
          </Tooltip>
        )}

        {!route.pathname.includes("pendings") && !comment.parentId && (
          <Tooltip title="responder">
            <IconButton
              aria-label="responder"
              onClick={() => {
                setOpenReplyCommentModal((state) => !state);
                setParentComment(comment);
              }}
            >
              <ReplyIcon color="info" />
            </IconButton>
          </Tooltip>
        )}

        {route.pathname.includes("pendings") && (
          <Tooltip title="deletar">
            <IconButton
              aria-label="delete"
              onClick={() => handleClickDelete(comment.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}

interface CommentsTableProps {
  comments: Array<Comment>;
  fetch: () => void;
}

type filterBy = "autor" | "postagem";

function CommentsTable({ comments, fetch }: CommentsTableProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [filter, setFilter] = React.useState("");
  const [filterBy, setFilterBy] = React.useState<filterBy>("autor");

  const [sortedData, setSortedData] = React.useState<Comment[]>([]);
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sortBy, setSortBy] = React.useState("createdAt");

  const [selectedComments, setSelectedComments] = React.useState<number[]>([]);
  const [selectionOption, setSelectionOption] = React.useState("");
  const [checkerSelectAll, setCheckerSelectAll] = React.useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [titleDialog, setTitleDialog] = React.useState("");
  const [contentDialog, setContentDialog] = React.useState("");
  const [typeOfDeletion, setTypeOfDeletion] = React.useState<
    "singleDeletion" | "manyDeletions"
  >("singleDeletion");

  const [openReplyCommentModal, setOpenReplyCommentModal] =
    React.useState(false);
  const [parentComment, setParentComment] = React.useState<Comment | null>(
    null
  );

  const [openNewCommentModal, setOpenNewCommentModal] = React.useState(false);

  const [commentIdToDelete, setCommentIdToDelete] = React.useState<
    number | null
  >(null);

  const filtering = (comment: Comment, filterBy: filterBy) => {
    switch (filterBy) {
      case "postagem":
        return comment.post?.title.toLowerCase().includes(filter);
      default:
        return `${comment.author?.first_name} ${comment.author?.last_name}`
          .toLowerCase()
          .includes(filter);
    }
  };

  React.useEffect(() => {
    if (comments) {
      let sorted = orderBy(comments, [sortBy], [sortOrder]);
      sorted = sorted.filter((comment) => filtering(comment, filterBy));
      setSortedData(sorted);
    }
  }, [comments, sortOrder, sortBy, filter]);

  const handleSort = (newSortBy: string) => {
    if (newSortBy !== sortBy) {
      setSortBy(newSortBy);
    } else {
      setSortOrder((state) => (state === "desc" ? "asc" : "desc"));
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleDisagree() {
    setOpenDialog(false);
  }

  const handleAgree = async (
    typeOfDeletion: "singleDeletion" | "manyDeletions",
    commentId: number
  ) => {
    try {
      switch (typeOfDeletion) {
        case "singleDeletion":
          await deleteComment(commentId);
          fetch();
          enqueueSnackbar("Deletado com sucesso", { variant: "success" });
          setOpenDialog(false);
          break;
        case "manyDeletions":
          await deleteManyComments(selectedComments);
          fetch();
          enqueueSnackbar("Comentários deletados com sucesso", {
            variant: "success",
          });
          setSelectedComments([]);
          setSelectionOption("");
          setTypeOfDeletion("singleDeletion");
          setCheckerSelectAll(false);
          setOpenDialog(false);
          break;
        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const handleClickApplyButton = async () => {
    try {
      switch (selectionOption) {
        case "delete":
          setTypeOfDeletion("manyDeletions");
          setTitleDialog("Excluir vários comentários");
          setContentDialog(
            "Tem certeza que deseja excluir os comentários? A ação não pode ser desfeita."
          );
          setOpenDialog(true);
          break;
        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { message } = err.response.data;
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <AlertDialog
        title={titleDialog}
        content={contentDialog}
        buttonAgree="Sim"
        buttonDisagree="Não"
        handleDisagree={() => handleDisagree()}
        handleAgree={() =>
          handleAgree(typeOfDeletion, commentIdToDelete as number)
        }
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <ReplyCommentModal
        open={openReplyCommentModal}
        setOpen={setOpenReplyCommentModal}
        parentComment={parentComment}
        fetch={fetch}
      />
      <NewCommentModal
        open={openNewCommentModal}
        setOpen={setOpenNewCommentModal}
        fetch={fetch}
      />
      <Stack
        width="100%"
        direction={matches ? "column" : "row"}
        justifyContent="space-between"
        component={Paper}
        elevation={8}
        padding="10px"
      >
        <Stack direction="row" spacing={2} maxWidth="350px">
          <FormControl size="small" sx={{ width: "250px" }}>
            <InputLabel id="select-option">Ação em massa</InputLabel>
            <Select
              label="Ação em massa"
              disabled={!(selectedComments.length > 1)}
              value={selectionOption}
              onChange={(e) => setSelectionOption(e.target.value)}
            >
              <MenuItem value="">Nenhuma</MenuItem>
              <MenuItem value="delete">Excluir</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            disabled={!(selectedComments.length > 1)}
            onClick={() => handleClickApplyButton()}
          >
            Aplicar
          </Button>
        </Stack>

        <Stack direction="row" spacing={2} maxWidth="400px">
          <TextField
            type="text"
            placeholder={`buscar pelo ${filterBy}...`}
            size="small"
            sx={{
              alignSelf: "flex-end",
              maxWidth: "250px",
              [theme.breakpoints.down("sm")]: {
                alignSelf: "start",
                marginTop: "10px",
              },
            }}
            onChange={(e) => setFilter(e.target.value.toLowerCase())}
          />

          <FormControl size="small" sx={{ alignSelf: "flex-end" }}>
            <Select
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value as filterBy)}
            >
              <MenuItem value="autor">autor</MenuItem>
              <MenuItem value="postagem">postagem</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 320 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  disabled={sortedData.length === 0}
                  checked={checkerSelectAll}
                  onChange={(e) => {
                    setCheckerSelectAll((state) => !state);
                    if (e.target.checked) {
                      setSelectedComments(
                        sortedData.slice(0, rowsPerPage).map((el) => el.id)
                      );
                    } else {
                      setSelectedComments([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "author.first_name"}
                  direction={sortOrder === "desc" ? sortOrder : "asc"}
                  onClick={() => handleSort("author.first_name")}
                >
                  Autor
                </TableSortLabel>
              </TableCell>
              <TableCell>Conteúdo</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "post.title"}
                  direction={sortOrder === "desc" ? sortOrder : "asc"}
                  onClick={() => handleSort("post.title")}
                >
                  Enviado em
                </TableSortLabel>
              </TableCell>
              <TableCell>Respondendo</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "createdAt"}
                  direction={sortOrder === "desc" ? sortOrder : "asc"}
                  onClick={() => handleSort("createdAt")}
                >
                  Data de envio
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setOpenNewCommentModal(true)}
                >
                  novo
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length > 0 &&
              sortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((comment) => (
                  <Row
                    key={comment.id}
                    comment={comment}
                    fetch={fetch}
                    setOpenReplyCommentModal={setOpenReplyCommentModal}
                    setParentComment={setParentComment}
                    setTypeOfDeletion={setTypeOfDeletion}
                    setOpenDialog={setOpenDialog}
                    setTitleDialog={setTitleDialog}
                    setContentDialog={setContentDialog}
                    setCommentIdToDelete={setCommentIdToDelete}
                    selectedComments={selectedComments}
                    setSelectedComments={setSelectedComments}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
}

export default CommentsTable;
