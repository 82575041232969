/* eslint-disable @typescript-eslint/no-explicit-any */
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import AuthContext from "../../../../contexts/auth";
import { Resource, sendImage } from "../../../../services/galleryService";
import { UserLogged } from "../../../../types/Auth";
import FilesDropzone from "../../../Inputs/FilesDropzone";

interface UploadImageProps {
  setTabValue: Dispatch<SetStateAction<number>>;
  resource: Resource;
}

function UploadImage({ setTabValue, resource }: UploadImageProps) {
  const [files, setFiles] = useState<File[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(AuthContext);

  const {
    user: { id: userLoggedId },
  } = user as UserLogged;

  useEffect(() => {
    async function exec() {
      try {
        if (files.length > 0) {
          const file = files[0];

          await sendImage(file, userLoggedId.toString(), resource);

          setTabValue(1);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const message = err?.response?.data.message;
          enqueueSnackbar(message, { variant: "error" });
        }
      }
    }

    exec();
  }, [files]);

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      height="100%"
      spacing={2}
    >
      <FilesDropzone setFiles={setFiles} />
    </Stack>
  );
}

export default UploadImage;
