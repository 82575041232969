/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { useFetch } from "../../../hooks/useFetch";
import { createSubcategory } from "../../../services/subcategoriesService";
import { Category } from "../../../types/Category";
import { Image } from "../../../types/Image";
import { NewSubcategory } from "../../../types/Subcategory";
import MediaModal from "../MediaModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
  "@media(max-width: 600px)": {
    width: "90%",
  },
};

const schema = yup.object().shape({
  name: yup.string().required("Nome não pode ser vazio!"),
  imageId: yup.number().required("Imagem não pode ser vazia!"),
  categoryId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required("Categoria não pode ser vazia!"),
});

interface NewSubcategoryModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => void;
}

function NewSubcategoryModal({
  open,
  setOpen,
  fetch,
}: NewSubcategoryModalProps) {
  const { data: categories } = useFetch<Category[]>("/category");

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm<NewSubcategory>({ resolver: yupResolver(schema) });

  const { enqueueSnackbar } = useSnackbar();

  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  useEffect(() => {
    if (selectedImage) {
      setValue("imageId", selectedImage.id);
    }
  }, [selectedImage]);

  async function submit(data: NewSubcategory) {
    try {
      await createSubcategory(data);

      enqueueSnackbar("Subcategoria adicionada com sucesso!", {
        variant: "success",
      });

      fetch();
      reset();
      setOpen(false);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  return (
    <>
      <MediaModal
        open={mediaModalOpen}
        setOpen={setMediaModalOpen}
        setSelectedImage={setSelectedImage}
        resource="SUBCATEGORY"
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <form onSubmit={handleSubmit(submit)}>
            <Stack spacing={2}>
              <Typography variant="h6">Nova subcategoria</Typography>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextField
                    type="text"
                    label="Nome da subcategoria"
                    onChange={onChange}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                  />
                )}
              />
              <FormControl>
                <InputLabel>Categoria</InputLabel>
                <Controller
                  name="categoryId"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select label="Categoria" onChange={onChange} value={value}>
                      {categories &&
                        categories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                <FormHelperText
                  error={Boolean(errors?.categoryId)}
                  sx={{ textAlign: "center" }}
                >
                  {errors?.categoryId?.message}
                </FormHelperText>
              </FormControl>
              <Stack>
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "#f0f0f0",
                    color: "black",
                    ":hover": {
                      backgroundColor: "#D3D3D3",
                    },
                  }}
                  onClick={() => setMediaModalOpen(true)}
                >
                  {!selectedImage ? (
                    <Typography align="center" fontWeight={500}>
                      Definir imagem (subcategoria)
                    </Typography>
                  ) : (
                    <img
                      src={selectedImage.url}
                      alt={selectedImage.alt}
                      style={{ width: "200px", height: "100%" }}
                    />
                  )}
                </Button>
                <FormHelperText
                  error={Boolean(errors?.imageId)}
                  sx={{ textAlign: "center" }}
                >
                  {errors?.imageId?.message}
                </FormHelperText>
              </Stack>
              <Button variant="contained" type="submit">
                Criar subcategoria
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default NewSubcategoryModal;
