import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Dispatch } from "react";

import theme from "../../../styles/theme";
import { Video } from "../../../types/Video";
import EditVideoForm from "../../Forms/Videos/EditVideoForm";

interface EditVideoModalProps {
  data: Video;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  fetch: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
};

function EditVideoModal({ data, open, setOpen, fetch }: EditVideoModalProps) {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <EditVideoForm
          data={data}
          fetch={fetch}
          onFinished={() => setOpen(false)}
        />
      </Box>
    </Modal>
  );
}

export default EditVideoModal;
