import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { useFetch } from "../../hooks/useFetch";
import { Comment } from "../../types/Comment";

type ContextType = { comments: Comment[]; fetch: () => void };

function Comments() {
  const { data, isLoading, mutate } = useFetch<Comment[]>("/comment");

  return (
    <>
      {!isLoading && (
        <Outlet context={{ comments: data, fetch: () => mutate() }} />
      )}
      <Backdrop
        sx={{ color: "red", backgroundColor: "transparent" }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Comments;

export function useComments() {
  return useOutletContext<ContextType>();
}
