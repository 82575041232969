/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, FormControlLabel, FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import * as yup from "yup";

import { updatePopup } from "../../../services/popupService";
import theme from "../../../styles/theme";
import { Image } from "../../../types/Image";
import { EditPopup, Popup, PopupPages } from "../../../types/Popup";
import MediaModal from "../MediaModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
};

interface EditPopupModalProps {
  fetch: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  popup: Popup;
  selectedPages: PopupPages[];
}

const schema = yup.object().shape({
  href: yup.string().required("O campo Link não pode ser vazio!"),
  rel: yup.string().optional(),
  target: yup.string(),
  visible: yup.boolean(),
  pages: yup
    .array()
    .of(yup.string())
    .when("visible", {
      is: true,
      then: yup
        .array()
        .min(1, "Páginas não podem ser vazias quando popup está visível!"),
    }),
  imageId: yup
    .number()
    .nullable()
    .when("visible", {
      is: true,
      then: yup
        .number()
        .nullable()
        .required("A imagem não pode ser vazia quando popup está visível!"),
    }),
});

function EditPopupModal({
  fetch,
  open,
  setOpen,
  popup,
  selectedPages,
}: EditPopupModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<EditPopup>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: EditPopup) => {
    try {
      await updatePopup(popup.id, data);
      fetch();
      enqueueSnackbar("Popup atualizado com sucesso!", {
        variant: "success",
      });
      handleClose();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const [openMediaModal, setOpenMediaModal] = React.useState(false);

  const [image, setImage] = React.useState<Image | null>(popup.image);

  React.useEffect(() => {
    setValue("imageId", image?.id || null);
  }, [image]);

  return (
    <>
      {openMediaModal && (
        <MediaModal
          open={openMediaModal}
          setOpen={setOpenMediaModal}
          setSelectedImage={setImage}
          resource="POPUP"
        />
      )}
      <Modal open={open} onClose={handleClose}>
        <Stack sx={style} spacing={2}>
          <Controller
            name="href"
            control={control}
            defaultValue={popup.href}
            render={({ field }) => (
              <TextField
                {...field}
                label="Link"
                size="small"
                error={Boolean(errors?.href)}
                helperText={errors?.href?.message}
              />
            )}
          />
          <FormControl>
            <InputLabel id="select-link-target">Abrir</InputLabel>
            <Controller
              name="target"
              control={control}
              defaultValue={popup.target}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Abrir"
                  labelId="select-link-target"
                  size="small"
                >
                  <MenuItem value="_self">Mesma página</MenuItem>
                  <MenuItem value="_blank">Nova página</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <Controller
            name="rel"
            control={control}
            defaultValue={popup.rel || ""}
            render={({ field }) => (
              <TextField {...field} label="rel" size="small" />
            )}
          />
          <Controller
            name="pages"
            control={control}
            defaultValue={popup.pages}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                options={["Home", "Recipe", "Blog", "BlogPost"] as PopupPages[]}
                getOptionDisabled={(option) =>
                  selectedPages.includes(option as PopupPages)
                }
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={value}
                onChange={(_, value) => {
                  onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Páginas"
                    size="small"
                    error={Boolean(errors?.pages)}
                    helperText={
                      (errors?.pages as unknown as FieldError)?.message
                    }
                  />
                )}
              />
            )}
          />
          <FormControlLabel
            label="Visível"
            labelPlacement="start"
            control={
              <Controller
                name="visible"
                control={control}
                defaultValue={popup.visible}
                render={({ field }) => (
                  <Switch {...field} defaultChecked={popup.visible} />
                )}
              />
            }
          />
          <Stack>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "120px",
                backgroundColor: "#f0f0f0",
                color: "black",
                ":hover": {
                  backgroundColor: "#D3D3D3",
                },
              }}
              onClick={() => setOpenMediaModal(true)}
            >
              {image ? (
                <img
                  src={image.url}
                  alt={image.alt || ""}
                  style={{ width: "180px", height: "100%" }}
                />
              ) : (
                <span>Definir imagem</span>
              )}
            </Button>

            <FormHelperText
              error={Boolean(errors?.imageId)}
              sx={{ textAlign: "center" }}
            >
              {errors?.imageId?.message}
            </FormHelperText>
          </Stack>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            atualizar
          </Button>
        </Stack>
      </Modal>
    </>
  );
}

export default EditPopupModal;
