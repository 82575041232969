import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

import AuthContext from "../../../contexts/auth";
import { useFetchWithToken } from "../../../hooks/useFetch";
import { deleteVideo } from "../../../services/videoService";
import { Video } from "../../../types/Video";
import { formatFromIsoToDateTime } from "../../../utils/formatDate";
import AlertDialog from "../../AlertDialog";
import EditVideoModal from "../../Modals/EditVideoModal";
import NewVideoModal from "../../Modals/NewVideoModal";

interface RowProps {
  video: Video;
  handleEdit: () => void;
  handleDelete: () => void;
}

function Row({ video, handleEdit, handleDelete }: RowProps) {
  return (
    <TableRow>
      <TableCell>{video.title}</TableCell>
      <TableCell>{video.url}</TableCell>
      <TableCell>
        {video.thumbnailUrl ? <CheckIcon /> : <CloseIcon />}
      </TableCell>
      <TableCell>{video.author}</TableCell>
      <TableCell>{video.source}</TableCell>
      <TableCell>
        {formatFromIsoToDateTime(video.createdAt as string)}
      </TableCell>
      <TableCell>
        {formatFromIsoToDateTime(video.updatedAt as string)}
      </TableCell>
      <TableCell align="right">
        <IconButton title="editar vídeo" onClick={() => handleEdit()}>
          <EditIcon />
        </IconButton>
        <IconButton title="deletar vídeo" onClick={() => handleDelete()}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function VideosTable() {
  const { enqueueSnackbar } = useSnackbar();

  const [newVideoModalOpen, setNewVideoModalOpen] = useState(false);

  const [editVideoModalOpen, setEditVideoModalOpen] = useState(false);
  const [editVideo, setEditVideo] = useState<Video | null>(null);

  const PER_PAGE = 10;

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page")
    ? Number(searchParams.get("page")) - 1
    : 0;

  const { user } = useContext(AuthContext);

  const { data, mutate } = useFetchWithToken<{
    count: number;
    videos: Video[];
  }>(
    `/video/cms?take=${PER_PAGE}&skip=${PER_PAGE * page}`,
    user?.access_token as string
  );

  function filter(params: Record<string, string | string[]>) {
    const currentFilters = Object.fromEntries(searchParams);

    const resFilters = { ...currentFilters, ...params };

    if (currentFilters.published !== resFilters.published) {
      Object.defineProperty(resFilters, "page", { value: 1 });
    }

    setSearchParams(resFilters);
  }

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    filter({ page: (newPage + 1).toString() });
  };

  function handleEdit(video: Video) {
    setEditVideo(video);
    setEditVideoModalOpen(true);
  }

  const [dialogOpen, setDialogOpen] = useState(false);

  const [idVideoToDelete, setIdVideoToDelete] = useState<number | null>(null);

  async function handleDelete(videoId: number) {
    setDialogOpen(true);
    setIdVideoToDelete(videoId);
  }

  function handleDisagree() {
    setDialogOpen(false);
  }

  async function handleAgree(videoId: number | null) {
    try {
      if (videoId !== null) {
        await deleteVideo(videoId);

        mutate();

        enqueueSnackbar("Vídeo deletado com sucesso!", { variant: "success" });

        setDialogOpen(false);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const message = err?.response?.data.message;
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  }

  return (
    <>
      <AlertDialog
        title="Excluir vídeo"
        content="Tem certeza que deseja excluir o vídeo? A ação não pode ser desfeita."
        buttonAgree="Sim"
        buttonDisagree="Não"
        handleDisagree={() => handleDisagree()}
        handleAgree={() => handleAgree(idVideoToDelete)}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
      <NewVideoModal
        open={newVideoModalOpen}
        setOpen={setNewVideoModalOpen}
        fetch={mutate}
      />
      {editVideo && (
        <EditVideoModal
          data={editVideo}
          open={editVideoModalOpen}
          setOpen={setEditVideoModalOpen}
          fetch={mutate}
        />
      )}
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320 }}>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell>Url</TableCell>
                <TableCell>Thumbnail</TableCell>
                <TableCell>Autor</TableCell>
                <TableCell>Fonte</TableCell>
                <TableCell>Criado em</TableCell>
                <TableCell>Atualizado em</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    title="adicionar vídeo"
                    onClick={() => setNewVideoModalOpen(true)}
                  >
                    Novo
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.videos.map((video) => (
                  <Row
                    key={video.id}
                    video={video}
                    handleEdit={() => handleEdit(video)}
                    handleDelete={() => handleDelete(video.id)}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {data && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={data.count}
            rowsPerPage={PER_PAGE}
            page={page}
            onPageChange={(event, page) => handleChangePage(event, page)}
          />
        )}
      </Stack>
    </>
  );
}

export default VideosTable;
