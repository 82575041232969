/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { Badge } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import { NewComment as NewCommentType } from "../../../../types/Comment";
import AttachPostsModal from "../../../Modals/AttachPostsModal";
import AttachUserModal from "../../../Modals/AttachUserModal";

interface NewCommentFormProps {
  onSubmit: SubmitHandler<NewCommentType>;
}

function NewCommentForm({ onSubmit }: NewCommentFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    content: yup.string().required("Comentário vazio!"),
    postId: yup.number().required("Necessário associação a uma postagem"),
    authorId: yup.number().required("Necessário associação a um usuário"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<NewCommentType>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const [attachPostsModalOpen, setAttachPostsModalOpen] = React.useState(false);
  const [selectedPosts, setSelectedPosts] = React.useState<number[]>([]);

  const handleAttach = () => setAttachPostsModalOpen(false);

  const [attachUserModalOpen, setAttachUserModalOpen] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState<number | null>(
    null
  );

  React.useEffect(() => {
    if (selectedPosts.length > 0) {
      setValue("postId", selectedPosts[0]);
    }
  }, [selectedPosts]);

  React.useEffect(() => {
    if (selectedUserId) {
      setValue("authorId", selectedUserId);
    }
  }, [selectedUserId]);

  React.useEffect(() => {
    if (errors.postId) {
      enqueueSnackbar(errors.postId.message, { variant: "error" });
    }

    if (errors.authorId) {
      enqueueSnackbar(errors.authorId.message, { variant: "error" });
    }
  }, [errors]);

  return (
    <>
      {attachPostsModalOpen && (
        <AttachPostsModal
          open={attachPostsModalOpen}
          setOpen={setAttachPostsModalOpen}
          selectedPosts={selectedPosts}
          setSelectedPosts={setSelectedPosts}
          handleAttach={handleAttach}
          singleSelection
        />
      )}
      {attachUserModalOpen && (
        <AttachUserModal
          open={attachUserModalOpen}
          setOpen={setAttachUserModalOpen}
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Badge
            badgeContent={selectedUserId ? 1 : 0}
            showZero
            color="secondary"
          >
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setAttachUserModalOpen(true)}
            >
              Anexar usuário
            </Button>
          </Badge>
          <Badge badgeContent={selectedPosts.length} showZero color="secondary">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setAttachPostsModalOpen(true)}
            >
              Anexar postagem
            </Button>
          </Badge>
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                multiline
                maxRows={15}
                label="Novo comentário"
                error={Boolean(errors?.content)}
                helperText={errors?.content?.message}
              />
            )}
          />
          <Button type="submit" variant="contained">
            Adicionar comentário
          </Button>
        </Stack>
      </form>
    </>
  );
}

export default NewCommentForm;
