import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import React from "react";

import theme from "../../../styles/theme";
import { PopupPages } from "../../../types/Popup";
import NewPopupForm from "../../Forms/Popup/NewPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
};

interface NewPopupModalProps {
  fetch: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPages: PopupPages[];
}

function NewPopupModal({
  open,
  setOpen,
  fetch,
  selectedPages,
}: NewPopupModalProps) {
  const handleClose = () => {
    setOpen(false);
  };

  const onFinished = () => {
    fetch();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack sx={style} spacing={2}>
        <NewPopupForm onFinished={onFinished} selectedPages={selectedPages} />
      </Stack>
    </Modal>
  );
}

export default NewPopupModal;
