import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import ControlPanel from "../ControlPanel";

function Layout() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflowX: "hidden",
      }}
    >
      <ControlPanel>
        <Outlet />
      </ControlPanel>
    </Box>
  );
}

export default Layout;
