/* eslint-disable react/require-default-props */
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { useFetch } from "../../../hooks/useFetch";
import { BlogCategory } from "../../../types/BlogCategory";

function renderRow({ index, style, data }: ListChildComponentProps) {
  const { categories, selectedCategory, setSelectedCategory } = data;
  const item = categories[index];

  function handleToggle() {
    if (selectedCategory === item.id) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(item.id);
    }
  }

  return (
    <ListItem
      key={item.id}
      style={style}
      secondaryAction={
        <Checkbox
          edge="end"
          onChange={() => handleToggle()}
          checked={selectedCategory === item.id}
        />
      }
      disablePadding
    >
      <ListItemButton onClick={() => handleToggle()}>
        <ListItemText primary={`${item.name}`} sx={{ pl: 4 }} />
      </ListItemButton>
    </ListItem>
  );
}

interface BlogCategoriesListProps {
  selectedCategory: number | null;
  setSelectedCategory: React.Dispatch<React.SetStateAction<number | null>>;
  error?: boolean;
  errorMessage?: string;
}

function BlogCategoriesList({
  selectedCategory,
  setSelectedCategory,
  error,
  errorMessage,
}: BlogCategoriesListProps) {
  const { data: categories, isLoading } =
    useFetch<BlogCategory[]>("/blog-category");

  return (
    <Stack width="100%" height="200px" direction="column" component={Paper}>
      <Typography variant="h6" ml={1} color="rgba(0,0,0,0.6)">
        Categorias
      </Typography>
      <FormHelperText sx={{ marginLeft: "10px" }} error={error}>
        {errorMessage}
      </FormHelperText>
      {categories && !isLoading && (
        <Box width="100%" height="200px">
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemSize={56}
                itemCount={categories.length}
                itemData={{
                  categories,
                  selectedCategory,
                  setSelectedCategory,
                }}
                overscanCount={5}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      )}
    </Stack>
  );
}

export default BlogCategoriesList;
